import { DOCUMENTS_API_URL } from "appConstants";
import { DocumentsClient, Document, ApiException, DocumentAttributes } from "documents-client";
import { base64ToBlob, StoreAction } from "orpyx-web-common";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { LIST_DOCUMENTS_SUCCESSFUL, LIST_DOCUMENTS_FAILED, 
    DocumentListActions, UPLOAD_DOCUMENT_SUCCESSFUL, UPLOAD_DOCUMENT_FAILED,
    UPLOAD_DOCUMENTS_SUCCESSFUL, UPLOAD_DOCUMENTS_FAILED, 
    DOWNLOAD_DOCUMENT_SUCCESSFUL, DOWNLOAD_DOCUMENT_FAILED, 
    GET_DOCUMENT_STATUS_SUCCESSFUL, GET_DOCUMENT_STATUS_FAILED, UPDATE_DOCUMENT_ATTRIBUTES_SUCCESSFUL, UPDATE_DOCUMENT_ATTRIBUTES_FAILED, UPDATE_DOCUMENT_ATTRIBUTES
} from "./document-list.actions";

function* onListDocuments(action: StoreAction<DocumentListActions>) {
    try {
        const associatedId = action.payload!.id;
        const category = action.payload!.category;
        const api = new DocumentsClient(DOCUMENTS_API_URL);

        const documents: Array<Document> = yield call(
            [api, api.listDocuments],
            category,
            associatedId
        );

        yield put(LIST_DOCUMENTS_SUCCESSFUL(documents));
    } catch (e) {
        yield put(LIST_DOCUMENTS_FAILED(e));
    }
}


function* onUploadDocument(action: StoreAction<DocumentListActions>) {
    try {
        const associatedId = action.payload!.id;
        const category = action.payload!.category;
        const api = new DocumentsClient(DOCUMENTS_API_URL);

        const document: Document = yield call(
            [api, api.uploadDocument],
            category,
            associatedId,
            action.payload!.description,
            action.payload!.filename,
            action.payload!.file
        );

        yield put(UPLOAD_DOCUMENT_SUCCESSFUL(document));
    } catch (e) {
        yield put(UPLOAD_DOCUMENT_FAILED(e));
    }
}

function* onUploadDocuments(action: StoreAction<DocumentListActions>) {
    try {
        const associatedId = action.payload!.id;
        const category = action.payload!.category;
        const api = new DocumentsClient(DOCUMENTS_API_URL);
        const desc = action.payload!.description;

        const documents: Array<Document> = yield call(
            [api, api.uploadDocumentFiles],
            category,
            associatedId,
            action.payload!.files,
            action.payload!.filenames
        );

        yield put(UPLOAD_DOCUMENTS_SUCCESSFUL(documents));
        for(let iDocIterator = 0; iDocIterator < documents.length; iDocIterator++) {
            if(documents[iDocIterator].status !== 'Failed' 
                && documents[iDocIterator].status !== 'Completed' 
                && desc && desc.length !== 0)
                yield put(UPDATE_DOCUMENT_ATTRIBUTES(documents[iDocIterator].documentId, { fileName: documents[iDocIterator].fileName, description: action.payload!.description }));
        }
    } catch (e) {
        yield put(UPLOAD_DOCUMENTS_FAILED(e));
    }
}

function* onDownloadDocuments(action: StoreAction<DocumentListActions>) {
    try {
        const documentId = action.payload!.id;
        const documentName = action.payload!.name;
        const api = new DocumentsClient(DOCUMENTS_API_URL);

        const fileContent: string = yield call(
            [api, api.downloadDocument],
            documentId
        );

        yield put(DOWNLOAD_DOCUMENT_SUCCESSFUL(documentName, base64ToBlob(fileContent)));
    } catch (e) {
        yield put(DOWNLOAD_DOCUMENT_FAILED(e));
    }
}

function* onDocumentStatusCheck(action: StoreAction<DocumentListActions>) {
    try {
        const documentId = action.payload;
        const api = new DocumentsClient(DOCUMENTS_API_URL);

        const status: string = yield call(
            [api, api.getStatus],
            documentId
        );

        yield put(GET_DOCUMENT_STATUS_SUCCESSFUL(documentId, status));
    } catch (e) {
        yield put(GET_DOCUMENT_STATUS_FAILED(e));
    }
}

function* onUpdateDocumentAttributes(action: StoreAction<DocumentListActions>) {
    try {
        const documentId = action.payload!.id;
        const api = new DocumentsClient(DOCUMENTS_API_URL);

        yield call(
            [api, api.updateDocument],
            documentId,
            action.payload!.request
        );

        yield put(UPDATE_DOCUMENT_ATTRIBUTES_SUCCESSFUL(documentId, action.payload!.request.filename, action.payload!.request.description));
    } catch (e) {
        yield put(UPDATE_DOCUMENT_ATTRIBUTES_FAILED(e));
    }
}

export default function* DocumentListSagas() {
    yield all([
        yield takeEvery(DocumentListActions.LIST_DOCUMENTS, onListDocuments),
        yield takeEvery(DocumentListActions.UPLOAD_DOCUMENT, onUploadDocument),
        yield takeEvery(DocumentListActions.UPLOAD_DOCUMENTS, onUploadDocuments),
        yield takeEvery(DocumentListActions.DOWNLOAD_DOCUMENT, onDownloadDocuments),
        yield takeEvery(DocumentListActions.GET_DOCUMENT_STATUS, onDocumentStatusCheck),
        yield takeEvery(DocumentListActions.UPDATE_DOCUMENT_ATTRIBUTES, onUpdateDocumentAttributes)
    ]);
}