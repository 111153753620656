import {useDoctorsDashboardPageStyles} from "../doctors-dashboard-page/doctors-dashboard-page.style";
import {Box, Paper} from "@material-ui/core";
import {Link} from "react-router-dom";
import * as React from "react";
import {memo} from "react";
import {DoctorsDashboardUrlBlockProps} from "./doctors-dashboard-url-block.interface";

export const DoctorsDashboardUrlBlock = memo((props: DoctorsDashboardUrlBlockProps) => {
    const classes = useDoctorsDashboardPageStyles();

    return (<Box width="33%" mr={2}>
        <Link to={props.url.url} className={classes.link}>
            <Paper className={classes.paper}>
                {props.url.title}
            </Paper>
        </Link>
    </Box>);
});