import * as React from "react";
import {memo} from "react";
import {connect} from "react-redux";

import {PatientInformationActivityLogMeta, PatientInformationActivityLogOwnProps,} from "./patient-information-activity-log.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {PatientActivityLogSelectors} from "../../../patient-activity-log/store/patient-activity-log.selectors";
import {OperationProgress, OperationSkeleton} from "orpyx-web-common";
import {PatientActivityLog} from "../../../patient-activity-log/components/patient-activity-log/patient-activity-log";
import {Grid} from "@material-ui/core";
import {usePatientInformationPageStyles} from "../patient-information-page/patient-information-page.style";
import {PatientInformationTitle} from "../../../patient-information-title/patient-information-title";

const PatientInformationActivityLogTemplate = (props: PatientInformationActivityLogMeta) => {
    const classes = usePatientInformationPageStyles();

    return (<Grid item xs={12}>
        <PatientInformationTitle text="Activity log"/>
        <div className={classes.content} id="patient-information-activity-log-table">
            <OperationProgress initialized={props.initialized} error={props.error}/>
            {!props.initialized && <OperationSkeleton height={40} rows={8}/>}
            {props.initialized && <PatientActivityLog/>}
        </div>
    </Grid>);
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientInformationActivityLogOwnProps => {
    return {
        initialized: PatientActivityLogSelectors.initialized(state),
        loading: PatientActivityLogSelectors.loading(state),
        error: PatientActivityLogSelectors.error(state),
    };
};

export const PatientInformationActivityLog = memo(connect(mapStateToProps)(PatientInformationActivityLogTemplate));

