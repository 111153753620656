import {createSelector} from "reselect";
import {getPatientsState} from "../../../store/patients.selectors";

export class PatientSubmitCareNoteSelectors {
    static readonly state = createSelector(getPatientsState, state => state.submitCareNote);

    static readonly inProcess = createSelector(PatientSubmitCareNoteSelectors.state, state => state.inProcess);
    static readonly error = createSelector(PatientSubmitCareNoteSelectors.state, state => state.error);

    static readonly formKey = createSelector(PatientSubmitCareNoteSelectors.state, state => state.formKey);
    static readonly trackedTime = createSelector(PatientSubmitCareNoteSelectors.state, state => state.trackedTime);
}