import { OperationError, OrganizationOutputDtoPagingResult } from "doctors-dashboard";
import {StoreAction} from "orpyx-web-common";

export enum DoctorsDashboardOrganizationsActions {
    FETCH_ORGANIZATIONS = 'DOCTORS_DASHBOARD_ORGANIZATIONS: FETCH_ORGANIZATIONS',
    FETCH_ORGANIZATIONS_SUCCESSFULLY = 'DOCTORS_DASHBOARD_ORGANIZATIONS: FETCH_ORGANIZATIONS_SUCCESSFULLY',
    FETCH_ORGANIZATIONS_FAILED = 'DOCTORS_DASHBOARD_ORGANIZATIONS: FETCH_ORGANIZATIONS_FAILEDC'
}

export const DOCTORS_DASHBOARD_FETCH_ORGANIZATIONS = (): StoreAction<DoctorsDashboardOrganizationsActions> => ({
    type: DoctorsDashboardOrganizationsActions.FETCH_ORGANIZATIONS
});

export const DOCTORS_DASHBOARD_FETCH_ORGANIZATIONS_SUCCESSFULLY = (organizations: OrganizationOutputDtoPagingResult): StoreAction<DoctorsDashboardOrganizationsActions> => ({
    type: DoctorsDashboardOrganizationsActions.FETCH_ORGANIZATIONS_SUCCESSFULLY, payload: organizations
})

export const DOCTORS_DASHBOARD_FETCH_ORGANIZATIONS_FAILED = (error: OperationError): StoreAction<DoctorsDashboardOrganizationsActions> => ({
    type: DoctorsDashboardOrganizationsActions.FETCH_ORGANIZATIONS_FAILED, payload: error
})