import {createSelector} from "reselect";
import {getPatientsState} from "../../../store/patients.selectors";

export class PatientInformationActionRequiredAlertsSelectors {
    static readonly state = createSelector(getPatientsState, state => state.information.actionRequiredAlerts);

    static readonly loading = createSelector(PatientInformationActionRequiredAlertsSelectors.state, state => state.loading);
    static readonly error = createSelector(PatientInformationActionRequiredAlertsSelectors.state, state => state.error);

    static readonly selected = createSelector(PatientInformationActionRequiredAlertsSelectors.state, state => state.selected);
    static readonly alerts = createSelector(PatientInformationActionRequiredAlertsSelectors.state, state => state.alerts);

    static readonly hasAlerts = createSelector(PatientInformationActionRequiredAlertsSelectors.alerts, alerts => alerts && alerts.length);
}