import * as React from "react";
import {memo} from "react";
import {RadioButton, isNullOrUndefined} from "orpyx-web-common";
import {PatientTemperatureComparisonRadioButtonProps} from "./PatientTemperatureComparisonRadioButton.interface";
import { Tooltip } from "@material-ui/core";

export const PatientTemperatureComparisonRadioButton = memo((props: PatientTemperatureComparisonRadioButtonProps) => {
    const ComparisonButton = () => 
        <>
            <RadioButton
                value={props.device.value}
                label={props.device.name}
                name={props.device.name}
                onChecked={props.onChecked}
                disabled={props.disabled}
            />
        </>;

    if (isNullOrUndefined(props.disabled) || props.disabled === false || isNullOrUndefined(props.disabledText)) {
        return <ComparisonButton/>;
    } else {
        return (
            <Tooltip title={props.disabledText!}>
                <span>
                    <ComparisonButton/>
                </span>
            </Tooltip>);
    }
});
