import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

export const useHomePageNavbarStyle = makeStyles((theme: Theme) => createStyles({

    appBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 40px',
    },

    logoClass: {
        display: 'flex',
        padding: '15px 0 14px 0'
    },
}));
