import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientSubmitCareNoteActions} from "./patient-submit-care-note.actions";

export interface PatientSubmitCareNoteState {
    inProcess: boolean;
    error: OperationErrorType;
    formKey: string;
    trackedTime: number;
}

const initialState: PatientSubmitCareNoteState = {
    inProcess: false,
    error: undefined,
    formKey: Math.random().toString(36),
    trackedTime: 0,
};

const PatientSubmitCareNoteReducers = (state = initialState, action: StoreAction<PatientSubmitCareNoteActions>): PatientSubmitCareNoteState => {
    return produce(state, (draft: PatientSubmitCareNoteState) => {
        switch (action.type) {
            case PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE:
                draft.inProcess = true;
                draft.error = undefined;
                break;

                case PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE_INCREASE_TIME:
                draft.trackedTime++;
                break;

            case PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE_RESET:
                draft.trackedTime = 0;
                draft.inProcess = false;
                draft.error = undefined;
                break;

            case PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE_SUCCESSFULLY:
                draft.trackedTime = 0;
                draft.error = undefined;
                draft.inProcess = false;
                draft.formKey = Math.random().toString(36);
                break;

            case PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE_FAILED:
                draft.error = action.payload;
                draft.inProcess = false;
                break;
        }
    });
};

export default PatientSubmitCareNoteReducers;
