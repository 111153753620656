import React, {memo} from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {createStyles, Theme, withStyles} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import {Teal} from "orpyx-web-common";
import {PatientActivityLogTableHeaderProps} from "./patient-activity-log-table-header.interface";

const GreenTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: Teal,
            color: '#FFF',
            fontSize: 14,
            verticalAlign: 'baseline',
            padding: '15px 10px',
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);

export const PatientActivityLogTableHeader = memo((props: PatientActivityLogTableHeaderProps) => {
    return (<TableHead>
        <TableRow>
            <GreenTableCell>Action Required Flags</GreenTableCell>
            <GreenTableCell>Date</GreenTableCell>
            <GreenTableCell>Notes</GreenTableCell>
            <GreenTableCell className={props.classes.actionIconCell}/>
            <GreenTableCell className={props.classes.actionLabelCell}>Action</GreenTableCell>
            <GreenTableCell>Name of Monitoring Personnel</GreenTableCell>
            <GreenTableCell>Monitored Time</GreenTableCell>
            <GreenTableCell/>
        </TableRow>
    </TableHead>)
});