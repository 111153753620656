import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {PatientSubmitCareNoteEvents, PatientSubmitCareNoteMeta, PatientSubmitCareNoteOwnProps, PatientSubmitCareNoteProps} from "./patient-submit-care-note.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {OperationProgress, OperationSkeleton} from "orpyx-web-common";
import {PatientSubmitCareNoteForm} from "../patient-submit-care-note-form/patient-submit-care-note-form";
import {CarePractitionerActionSelectors} from "../../../../../care-practitioner-action/store/care-practitioner-action.selectors";
import {PatientSubmitCareNoteSelectors} from "../../store/patient-submit-care-note.selectors";
import {PATIENT_SUBMIT_CARE_NOTE} from "../../store/patient-submit-care-note.actions";
import {PatientInformationActionRequiredAlertsSelectors} from "../../../patient-action-required-alerts/store/patient-information-action-required-alerts.selectors";
import {onPatientSubmitCareNote} from "./patient-submit-care-note.hooks";
import {PatientTemperatureSelectors} from "../../../patient-temperature/store/patient-temperature.selectors";
import {usePatientInformationPageStyles} from "../../../patient-information-page/components/patient-information-page/patient-information-page.style";
import { DOCTORS_DASHBOARD_RESET_LAST_ACTIVE_TIME } from "../../../../../auth/store/doctors-dashboard-auth-actions";

const PatientSubmitCareNoteTemplate = (props: PatientSubmitCareNoteMeta) => {
    const classes = usePatientInformationPageStyles();

    return (<div>
        <h2 className={classes.title}>
            Comments / Notes
        </h2>

        <OperationProgress initialized={props.actions.initialized} error={props.actions.error}/>
        {!props.actions.initialized && !props.actions.error && <>
            <OperationSkeleton height={150} rows={1}/>
            <OperationSkeleton height={40} rows={4}/>
        </>}

        {props.actions.initialized && <PatientSubmitCareNoteForm
            key={props.submitCareNote.formKey}
            actions={props.actions.list}
            error={props.submitCareNote.error}
            inProcess={props.submitCareNote.inProcess}
            trackedTime={props.trackedTime}
            onSubmit={onPatientSubmitCareNote(props)}
            onActiveInput={() => props.resetLastActiveTime()}
        />}
    </div>);
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientSubmitCareNoteOwnProps => {
    return {
        temperature: {
            options: PatientTemperatureSelectors.options(state)
        },
        trackedTime: PatientSubmitCareNoteSelectors.trackedTime(state)!,
        selectedActionRequiredAlerts: PatientInformationActionRequiredAlertsSelectors.selected(state),
        actions: {
            initialized: CarePractitionerActionSelectors.initialized(state),
            error: CarePractitionerActionSelectors.error(state),
            list: CarePractitionerActionSelectors.actions(state),
        },
        submitCareNote: {
            inProcess: PatientSubmitCareNoteSelectors.inProcess(state),
            error: PatientSubmitCareNoteSelectors.error(state),
            formKey: PatientSubmitCareNoteSelectors.formKey(state),
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientSubmitCareNoteProps): PatientSubmitCareNoteEvents => {
    return bindActionCreators({
        submitCareNoteForm: PATIENT_SUBMIT_CARE_NOTE,
        resetLastActiveTime: DOCTORS_DASHBOARD_RESET_LAST_ACTIVE_TIME
    }, dispatch);
};

export const PatientSubmitCareNote = memo(connect(mapStateToProps, mapDispatchToProps)(PatientSubmitCareNoteTemplate));
