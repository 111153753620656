import {createSelector} from "reselect";
import {getPatientsState} from "../../../store/patients.selectors";

export class PatientActivityLogSelectors {
    static readonly state = createSelector(getPatientsState, state => state.information.careNotes);

    static readonly initialized = createSelector(PatientActivityLogSelectors.state, state => state.initialized);
    static readonly loading = createSelector(PatientActivityLogSelectors.state, state => state.loading);
    static readonly error = createSelector(PatientActivityLogSelectors.state, state => state.error);

    static readonly inProcess = createSelector(PatientActivityLogSelectors.state, state => state.careNotes.inProcess);

    static readonly careNotes = createSelector(PatientActivityLogSelectors.state, state => state.careNotes.list);
    static readonly lastRowMarker = createSelector(PatientActivityLogSelectors.state, state => state.careNotes.lastRowMarker);
    static readonly canGetMoreCareNotes = createSelector(PatientActivityLogSelectors.state, state => state.careNotes.canGetMore);
    static readonly takeCount = createSelector(PatientActivityLogSelectors.state, state => state.careNotes.takeCount);

    static readonly monitoringCycle = createSelector(PatientActivityLogSelectors.state, state => state.monitoringCycle);
}