import * as React from 'react';
import organizationSymbol from 'images/house-chimney-medical-solid.svg';
import { PatientListOrganizationSelectorProps } from './patient-list-organization-selector.interface';
import { usePatientListOrganizationSelectorStyle } from './patient-list-organization-selector.style';
import {Tooltip} from "@material-ui/core";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const truncateOrganizationName = (name: string | null | undefined, characterLimit: number = 25) => {
    if (name!.length <= characterLimit) return name;
    return `${name?.substring(0, characterLimit).trim()}...`
}

// The select component uses hidden props to track values, so we need to take `props`
// in here and spread it down to the `MenuItem`
const MenuItemWithTooltip = React.forwardRef((props: any, ref: any) => 
<Tooltip innerRef={ref} key={props.key} title={props.name}>
    <MenuItem {...props}>
        {truncateOrganizationName(props.name)}  
    </MenuItem>
</Tooltip>
);

export const OrganizationSelector = (props: PatientListOrganizationSelectorProps) => {
    const onOrganizationSelected = (event: any) => {
        const id = event.target.value;
        const organization = props.organizations.find(x => x.id === id);
        props.onOrganizationSelected(organization!);
    }

    const classes = usePatientListOrganizationSelectorStyle();

    const { currentOrganization, defaultOrganization, renderOrganizationSelect } = props;
    const organization = typeof currentOrganization !== 'undefined' 
        ? currentOrganization 
        : defaultOrganization;
    const hasCurrentOrganization = typeof organization !== 'undefined';
    const organizationName = organization?.name!;

    if (hasCurrentOrganization) {
        return (
            <span className={classes.right} id="patient-list-organization-selector-span">
                <img src={organizationSymbol} id="patient-list-organization-selector-image" className={classes.icon} />
                { renderOrganizationSelect ?
                    <Select 
                        disableUnderline={true}
                        value={organization?.id}
                        id="patient-list-organization-selector"
                        onChange={onOrganizationSelected}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: "500px"
                                }
                            },
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    >
                    {props.organizations.map((org) => (
                      <MenuItemWithTooltip value={org.id} key={org.id} name={org.name}>
                        {org.name}
                      </MenuItemWithTooltip>
                    ))}
                  </Select>
                    :
                    organizationName
                }
            </span>
        );
    }

    return (null);
};
