import {createListSearchOrderItem, ListSearchOrderByItem, orderByAlphabetPipe} from "orpyx-web-common";
import {GetPatientsListPropertyOrderBy} from "doctors-dashboard/http-clients/index";

export const PatientsListOrderPipe = (order: GetPatientsListPropertyOrderBy, orderByAsc?: boolean): string => {
    switch (order) {
        case GetPatientsListPropertyOrderBy.FirstName:
            return orderByAlphabetPipe('First name', orderByAsc);
        case GetPatientsListPropertyOrderBy.LastName:
            return orderByAlphabetPipe('Last name', orderByAsc);
        case GetPatientsListPropertyOrderBy.ClientPatientIdentifier:
            return 'Patient ID';
        case GetPatientsListPropertyOrderBy.DateOfPatientAuthorization:
            return 'Patient Initialization Date';
    }
    return `Unknown: ${order}`;
};

export const PatientsListOrderPipeItems: ListSearchOrderByItem[] = [
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.FirstName, PatientsListOrderPipe, true),
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.FirstName, PatientsListOrderPipe, false),

    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.LastName, PatientsListOrderPipe, true),
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.LastName, PatientsListOrderPipe, false),

    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.ClientPatientIdentifier, PatientsListOrderPipe, true),
    createListSearchOrderItem<GetPatientsListPropertyOrderBy>(GetPatientsListPropertyOrderBy.DateOfPatientAuthorization, PatientsListOrderPipe, true),
];

