import {PatientInformationActionRequiredAlertProps} from "./patient-information-action-required-alert.interface";
import * as React from "react";
import {memo} from "react";
import {azureBlobHost} from "../../../../../../../appConstants";
import {DatePipe} from "orpyx-web-common";
import {Checkbox, Tooltip} from "@material-ui/core";
import {usePatientInformationActionRequiredAlertStyle} from "./patient-information-action-required-alert.style";
import {ActionRequiredAlertTypeEnum} from "doctors-dashboard";

export const PatientInformationActionRequiredAlert = memo((props: PatientInformationActionRequiredAlertProps) => {
    const classes = usePatientInformationActionRequiredAlertStyle();

    const canDisplayCheckBox = props.alert.type !== ActionRequiredAlertTypeEnum.DataReviewWith7Days;
    const isSelected = !!props.selected[props.alert.id!];

    return (<div className={classes.root}>
        <Tooltip title={`${DatePipe(props.alert.creationDate)}: ${props.alert.alertMessage}`}>
            <img
                className={classes.icon}
                src={`${azureBlobHost}/${props.alert.imageUrl}`}
                alt={props.alert.alertMessage!}
            />
        </Tooltip>

        <span className={classes.message}>
            {props.alert.alertMessage}
        </span>

        {canDisplayCheckBox && <span className={classes.checkbox}>
            <Checkbox
                color="primary"
                checked={isSelected}
                onChange={() => props.onSelected(props.alert.id!)}
            />
        </span>}
    </div>);
});