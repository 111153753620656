import {createSelector} from "reselect";
import {getDoctorDashboardState} from "../../store/doctor-dashboard.selectors";

export class DoctorsDashboardAuthSelectors {
    static readonly state = createSelector(getDoctorDashboardState, state => state.auth);

    static readonly inProcess = createSelector(DoctorsDashboardAuthSelectors.state, state => state.inProcess);
    static readonly isLoggedIn = createSelector(DoctorsDashboardAuthSelectors.state, state => state.isLoggedIn);

    static readonly profile = createSelector(DoctorsDashboardAuthSelectors.state, state => state.profile);

    static readonly azureActor = createSelector(DoctorsDashboardAuthSelectors.state, state => state.azureActor);
    static readonly accessToken = createSelector(DoctorsDashboardAuthSelectors.azureActor, actor => actor ? actor.accessToken : null);

    static readonly lastActive = createSelector(DoctorsDashboardAuthSelectors.state, state => state?.lastActive);

    static readonly actorType = createSelector(DoctorsDashboardAuthSelectors.azureActor, state => state?.type);

    static readonly defaultOrganization = createSelector(DoctorsDashboardAuthSelectors.state, state => state?.profile?.defaultOrganization);
    static readonly hasCustomerCarePermissions = createSelector(DoctorsDashboardAuthSelectors.state, state => state?.profile?.hasCustomerCarePermissions);

    static readonly logoutRequiresConfirmation = createSelector(DoctorsDashboardAuthSelectors.state, state => state.logoutRequiresConfirmation);
}
