import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientStepCountActions} from "./patient-step-count.actions";
import { IPatientStepCountsSummary } from "doctors-dashboard";

export interface PatientStepCountState {
    loading: boolean,
    initialized: boolean,
    error: OperationErrorType;

    summary: IPatientStepCountsSummary[];
}

const initialState: PatientStepCountState = {
    loading: false,
    initialized: false,
    error: undefined,

    summary: [],
}

const PatientStepCountReducers = (state = initialState, action: StoreAction<PatientStepCountActions>): PatientStepCountState => {
    return produce(state, (draft: PatientStepCountState) => {
        switch (action.type) {
            case PatientStepCountActions.RESET_PATIENT_STEP_COUNT_SUMMARY:
                draft.loading = false;
                draft.initialized = false;
                draft.error = undefined;
                draft.summary = [];
                break;

            case PatientStepCountActions.GET_PATIENT_STEP_COUNT_SUMMARY:
                draft.loading = true;
                draft.initialized = false;
                draft.error = undefined;
                draft.summary = [];
                break;

            case PatientStepCountActions.GET_PATIENT_STEP_COUNT_SUMMARY_SUCCESSFULLY:
                draft.summary = action.payload;
                draft.error = undefined;
                draft.initialized = true;
                draft.loading = false;
                break;

            case PatientStepCountActions.GET_PATIENT_STEP_COUNT_SUMMARY_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                draft.summary = [];
                break;
        }
    });
};

export default PatientStepCountReducers;