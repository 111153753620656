import * as React from "react";
import {memo} from "react";

import {useHomePageNavbarStyle} from "../../../../app/home-page/components/home-page-navbar/home-page-navbar/home-page-navbar.style";
import {NavLink} from "react-router-dom";
import logo from "../../../../../images/Orpyx-SI-Logo.svg";
import {DoctorsDashboardNavbar} from "./doctors-dashboard-navbar";

export const DoctorsPagesNavBar = memo(() => {
    const classes = useHomePageNavbarStyle();

    return (
        <header className={classes.appBar}>
            <div id="doctors-pages-nav-bar">
                <NavLink
                    exact={true}
                    to="/patients/Active"
                    activeClassName="active"
                    className={`Title-header ${classes.logoClass}`}>
                    <img src={logo} alt="Home" height="50px"/>
                </NavLink>
            </div>
            <div>
                <DoctorsDashboardNavbar usePrimaryLinkColor={true}/>
            </div>
        </header>
    );
});
