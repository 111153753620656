import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

export const PatientActivityLogTableStyle = createStyles({
    table: {
        minWidth: 650,
    },
    loadMoreNotesButton: {
      display: "block",
      margin: '25px auto 10px',
        '& span': {
            color: 'white'
        },
    },
    actionLabelCell: {
        paddingLeft: 0,
        whiteSpace: 'nowrap',
        minWidth: "200px"
    },
    actionIconCell: {
        padding: 0,
        width: '10px',
        '& img': {
            width: '20px',
            height: '20px',
            margin: '0 10px 0 0',
            verticalAlign: 'sub',
        }
    },
    noteText: {
        wordBreak: "break-all",
        minWidth: "300px"
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
});

export const usePatientActivityLogTableStyle = makeStyles((theme: Theme) => PatientActivityLogTableStyle);