import { Grid } from "@material-ui/core";
import moment from "moment";
import { DateFormat } from "orpyx-web-common";
import * as React from "react";
import { memo } from "react";
import { usePatientInformationPageStyles } from "../patient-information-page/patient-information-page.style";
import { pages } from "./patient-information-sub-header-pages";
import { PatientInformationSubHeaderProps } from "./patient-information-sub-header.interface";

export const PatientInformationSubHeader = memo((props: PatientInformationSubHeaderProps) => {
    const classes = usePatientInformationPageStyles();

    const getHrefStyles = (page: string) => {
        let result = `${classes.href}`;
        if (page === props.currentPage) result = `${result} ${classes.bold}`;
        return result;
    }
    
    return(
        <>
            { !props.loading && <Grid item xs={12}>
                {/* TODO: Revisit adding underline to this whole section */}
                {props.hasCustomerCarePermissions && <>
                    <a 
                        className={`${getHrefStyles(pages.patientChart)} ${classes.borderRight}`}
                        href={`/patients/details/${props.patient?.patientId!}`}
                    >
                        Patient Chart
                    </a>
                    <a 
                        className={`${getHrefStyles(pages.careNotes)}`}
                        href={`/patients/internalnotes/${props.patient?.patientId!}`}
                    >
                        Orpyx Care Notes
                    </a>
                </>}
                <div className={classes.dateLine} id="patient-information-sub-header-today-date">
                    <div>Today's Date:</div><div className={classes.dateItem}>{moment().format(DateFormat)}</div>
                </div>
                <br/>
                {props.patient && props.patient.dataCurrentAsOf &&
                    <div className={classes.dateLine} id="patient-information-sub-header-data-current-as-of">
                        <div>Data Current as of:</div><div className={classes.dateItem}>{moment(props.patient.dataCurrentAsOf).format(DateFormat)}</div>
                    </div>}
            </Grid> }
        </>
    );
});