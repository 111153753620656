import {AzureB2CCredentials} from "orpyx-web-common";

export interface DoctorsDashboardSignInProviders {
    readonly signIn: AzureB2CCredentials,
}

export const signInProviders: DoctorsDashboardSignInProviders = {
    signIn: new AzureB2CCredentials
    (
        process.env.REACT_APP_B2C_CLIENT_ID!,
        process.env.REACT_APP_B2C_TENANT_NAME!,
        process.env.REACT_APP_B2C_SIGN_IN_POLICY!,
        process.env.REACT_APP_B2C_SCOPE_NAME!,
    )
};