import {PropertyNamePipe} from "orpyx-web-common";
import {Field} from "formik";
import {Select} from "formik-material-ui";
import MenuItem from "@material-ui/core/MenuItem";
import * as React from "react";
import {memo} from "react";
import {CarePractitionerActionSelectorProps} from "./care-practitioner-action-selector.interface";
import {useCarePractitionerActionSelectorStyle} from "./care-practitioner-action-selector.style";
import {ImageUrlPipe} from "../../../../../../shared/pipes/image-url-pipe";

export const CarePractitionerActionSelector = memo((props: CarePractitionerActionSelectorProps) => {
    const classes = useCarePractitionerActionSelectorStyle();

    const inputProps = {
        id: PropertyNamePipe(props.values, 'actionId'),
        name: PropertyNamePipe(props.values, 'actionId')
    };

    return (
        <Field
            component={Select}
            className={props.classes.input}
            name={PropertyNamePipe(props.values, 'actionId')}
            value={props.selectedAction}
            inputProps={inputProps}
        >
            {props.actions.map(action => (<MenuItem key={action.id} value={action.id}>
                <img
                    alt={action?.name!}
                    src={ ImageUrlPipe(action?.url)}
                    className={classes.icon}
                />

                {action.name}
            </MenuItem>))}
        </Field>
    );
});