import { Document, ApiException, DocumentAttributes } from "documents-client";
import { StoreAction} from "orpyx-web-common";

export enum DocumentListActions {
    LIST_DOCUMENTS = 'LIST_DOCUMENTS: LIST_DOCUMENTS',
    LIST_DOCUMENTS_SUCCESSFUL = 'LIST_DOCUMENTS: LIST_DOCUMENTS_SUCCESSFUL',
    LIST_DOCUMENTS_FAILED = 'LIST_DOCUMENTS: LIST_DOCUMENTS_FAILED',
    ADD_NEW_DOCUMENTS = 'ADD_NEW_DOCUMENTS: ADD_NEW_DOCUMENTS',
    RESET_DOCUMENTS = 'RESET_DOCUMENTS: RESET_DOCUMENTS',
    UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT: UPLOAD_DOCUMENT',
    UPLOAD_DOCUMENT_SUCCESSFUL = 'UPLOAD_DOCUMENT: UPLOAD_DOCUMENT_SUCCESSFUL',
    UPLOAD_DOCUMENT_FAILED = 'UPLOAD_DOCUMENT: UPLOAD_DOCUMENT_FAILED',
    CANCEL_DOCUMENT_UPLOAD = 'CANCEL_DOCUMENT_UPLOAD: CANCEL_DOCUMENT_UPLOAD',
    PREPARE_FOR_UPLOAD = 'PREPARE_FOR_UPLOAD: PREPARE_FOR_UPLOAD',
    UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS: UPLOAD_DOCUMENTS',
    UPLOAD_DOCUMENTS_SUCCESSFUL = 'UPLOAD_DOCUMENTS: UPLOAD_DOCUMENTS_SUCCESSFUL',
    UPLOAD_DOCUMENTS_FAILED = 'UPLOAD_DOCUMENTS: UPLOAD_DOCUMENTS_FAILED',
    DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT: DOWNLOAD_DOCUMENT',
    DOWNLOAD_DOCUMENT_SUCCESSFUL = 'DOWNLOAD_DOCUMENT: DOWNLOAD_DOCUMENT_SUCCESSFUL',
    DOWNLOAD_DOCUMENT_FAILED = 'DOWNLOAD_DOCUMENT: DOWNLOAD_DOCUMENT_FAILED',
    CLOSE_DOCUMENT_VIEWER = 'CLOSE_DOCUMENT_VIEWER: CLOSE_DOCUMENT_VIEWER',
    GET_DOCUMENT_STATUS = 'GET_DOCUMENT_STATUS: GET_DOCUMENT_STATUS',
    GET_DOCUMENT_STATUS_SUCCESSFUL = 'GET_DOCUMENT_STATUS: GET_DOCUMENT_STATUS_SUCCESSFUL',
    GET_DOCUMENT_STATUS_FAILED = 'GET_DOCUMENT_STATUS: GET_DOCUMENT_STATUS_FAILED',
    UPDATE_DOCUMENT_ATTRIBUTES = 'UPDATE_DOCUMENT_ATTRIBUTES: UPDATE_DOCUMENT_ATTRIBUTES',
    UPDATE_DOCUMENT_ATTRIBUTES_SUCCESSFUL = 'UPDATE_DOCUMENT_ATTRIBUTES: UPDATE_DOCUMENT_ATTRIBUTES_SUCCESSFUL',
    UPDATE_DOCUMENT_ATTRIBUTES_FAILED = 'UPDATE_DOCUMENT_ATTRIBUTES: UPDATE_DOCUMENT_ATTRIBUTES_FAILED'
}

export const LIST_DOCUMENTS = (category: string, id: number): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.LIST_DOCUMENTS, payload: { category, id }
});

export const RESET_DOCUMENTS = (): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.RESET_DOCUMENTS
});

export const LIST_DOCUMENTS_SUCCESSFUL = (documents: Array<Document>): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.LIST_DOCUMENTS_SUCCESSFUL, payload: documents
});

export const LIST_DOCUMENTS_FAILED = (ex: ApiException): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.LIST_DOCUMENTS_SUCCESSFUL, payload: ApiException
});

export const ADD_NEW_DOCUMENTS = (category: string, id: number): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.ADD_NEW_DOCUMENTS, payload: { category, id }
});

export const UPLOAD_DOCUMENT = (category: string, id: number, description: string | null, filename: string, file: Blob): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.UPLOAD_DOCUMENT, payload: { category, id, description, filename, file }
});

export const UPLOAD_DOCUMENT_SUCCESSFUL = (document: Document): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.UPLOAD_DOCUMENT_SUCCESSFUL, payload: document
});

export const UPLOAD_DOCUMENT_FAILED = (ex: ApiException): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.UPLOAD_DOCUMENT_FAILED, payload: ApiException
});

export const CANCEL_DOCUMENT_UPLOAD = () : StoreAction<DocumentListActions> => ({
    type: DocumentListActions.CANCEL_DOCUMENT_UPLOAD
});

export const PREPARE_FOR_UPLOAD = (files: Array<File>, description: string): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.PREPARE_FOR_UPLOAD, payload: { files, description }
});

export const UPLOAD_DOCUMENTS = (category: string, id: number, files: Array<Blob>, filenames: Array<string>, description: string): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.UPLOAD_DOCUMENTS, payload: { category, id, files, filenames, description }
});

export const UPLOAD_DOCUMENTS_SUCCESSFUL = (documents: Array<Document>): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.UPLOAD_DOCUMENTS_SUCCESSFUL, payload: documents
});

export const UPLOAD_DOCUMENTS_FAILED = (ex: ApiException): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.UPLOAD_DOCUMENTS_FAILED, payload: ApiException
});

export const DOWNLOAD_DOCUMENT = (id: string, name: string): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.DOWNLOAD_DOCUMENT, payload: { id, name }
});

export const DOWNLOAD_DOCUMENT_SUCCESSFUL = (documentName: string, fileContent: Blob): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.DOWNLOAD_DOCUMENT_SUCCESSFUL, payload: { documentName , fileContent }
});

export const DOWNLOAD_DOCUMENT_FAILED = (ex: ApiException): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.DOWNLOAD_DOCUMENT_FAILED, payload: ApiException
});

export const CLOSE_DOCUMENT_VIEWER = (): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.CLOSE_DOCUMENT_VIEWER
});

export const GET_DOCUMENT_STATUS = (id: string): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.GET_DOCUMENT_STATUS, payload: id
});

export const GET_DOCUMENT_STATUS_SUCCESSFUL = (id: string, status: string): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.GET_DOCUMENT_STATUS_SUCCESSFUL, payload: { id, status}
});

export const GET_DOCUMENT_STATUS_FAILED = (ex: ApiException): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.GET_DOCUMENT_STATUS_FAILED, payload: ApiException
});

export const UPDATE_DOCUMENT_ATTRIBUTES = (id: string, request: DocumentAttributes): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.UPDATE_DOCUMENT_ATTRIBUTES, payload: { id, request }
});

export const UPDATE_DOCUMENT_ATTRIBUTES_SUCCESSFUL = (id: string, filename: string, description: string): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.UPDATE_DOCUMENT_ATTRIBUTES_SUCCESSFUL, payload: { id, filename, description }
});

export const UPDATE_DOCUMENT_ATTRIBUTES_FAILED = (ex: ApiException): StoreAction<DocumentListActions> => ({
    type: DocumentListActions.UPDATE_DOCUMENT_ATTRIBUTES_FAILED, payload: ApiException
});