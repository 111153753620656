import {PatientInformationTitle} from "../../../patient-information-title/patient-information-title";
import {Grid} from "@material-ui/core";
import {PatientSafePressureChart} from "../../../patient-statistic/components/patient-safe-pressure-chart/patient-safe-pressure-chart";
import {PatientMetricsAlertRegion} from "../../../patient-statistic/components/patient-metrics-alert-region/patient-metrics-alert-region";
import {PatientMetricsAlertsChart} from "../../../patient-statistic/components/patient-metrics-alerts-chart/patient-metrics-alerts-chart";
import * as React from "react";
import {memo} from "react";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {PatientStatisticSelectors} from "../../../patient-statistic/store/patient-statistic.selectors";
import {connect} from "react-redux";
import {DateRangePipe, getFirstMonthDay, getLastMonthDay, OperationProgress, OperationSkeleton} from "orpyx-web-common";
import {PatientInformationPressureMeta, PatientInformationPressureOwnProps} from "./patient-information-pressure.interface";
import {usePatientInformationPageStyles} from "../patient-information-page/patient-information-page.style";
import {PatientInformationPageSelectors} from "../../store/patient-information-page.selectors";

const PatientInformationPressureContent = memo((props: PatientInformationPressureMeta) => {
    const classes = usePatientInformationPageStyles();

    const {lastMonth, thisMonth} = props.statistic;

    return (<>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <div className={classes.content} id="patient-information-pressure-last-month">
                    <div className={classes.centered}>
                        Last Month <br/>
                        {DateRangePipe({
                            begin: getFirstMonthDay(lastMonth?.month),
                            end: getLastMonthDay(lastMonth?.month),
                        })}
                    </div>
                    <PatientSafePressureChart summary={lastMonth}/>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.content} id="patient-information-pressure-this-month">
                    <div className={classes.centered}>
                        This Month <br/>
                        {DateRangePipe({
                            begin: getFirstMonthDay(thisMonth?.month),
                            end: getLastMonthDay(thisMonth?.month),
                        })}
                    </div>
                    <PatientSafePressureChart summary={thisMonth}/>
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <div className={classes.content}>
                    <PatientMetricsAlertRegion summary={thisMonth}/>
                </div>
            </Grid>

            <Grid item xs={8}>
                <div className={classes.content}>
                    <div className={classes.centered}>
                        This Month <br/>
                        {DateRangePipe(props.monitoringCycle)}
                    </div>
                    <PatientMetricsAlertsChart/>
                </div>
            </Grid>
        </Grid>
    </>);
});

const PatientInformationPressureLoading = memo((props: PatientInformationPressureMeta) => {
    const classes = usePatientInformationPageStyles();

    return (<>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <div className={classes.content}>
                    <OperationSkeleton height={40} rows={8}/>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.content}>
                    <OperationSkeleton height={40} rows={8}/>
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <div className={classes.content}>
                    <OperationSkeleton height={40} rows={8}/>
                </div>
            </Grid>

            <Grid item xs={8}>
                <div className={classes.content}>
                    <OperationProgress error={props.error} inProcess={props.loading}/>
                    {!props.initialized && <OperationSkeleton height={40} rows={8}/>}
                </div>
            </Grid>
        </Grid>
    </>)
});

const PatientInformationPressureTemplate = (props: PatientInformationPressureMeta) => {
    return (
        <>
            <PatientInformationTitle text="Pressure"/>
            {!props.initialized && <PatientInformationPressureLoading {...props}/>}
            {props.initialized && <PatientInformationPressureContent {...props}/>}
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientInformationPressureOwnProps => {
    return {
        initialized: PatientStatisticSelectors.initialized(state),
        loading: PatientStatisticSelectors.loading(state),
        error: PatientStatisticSelectors.error(state),
        monitoringCycle: PatientInformationPageSelectors.monitoringCycle(state),
        statistic: {
            lastMonth: PatientStatisticSelectors.lastMonth(state),
            thisMonth: PatientStatisticSelectors.thisMonth(state),
        },
    };
};

export const PatientInformationPressure = memo(connect(mapStateToProps)(PatientInformationPressureTemplate));
