import { OperationResultCode } from "doctors-dashboard";
import { ScratchPadNoteMeta } from "./scratch-pad-notes.interface";

export const onPatientScratchPadNotePageLoaded = (props: ScratchPadNoteMeta) => () => {
    const rawPatientId = props.match.params.patientId;
    if (props.match.isExact && rawPatientId) {
        const patientId = parseInt(rawPatientId);
        if (isNaN(patientId)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid patient ID: ${rawPatientId}`
            })
        } else {
            props.loadPatientInformation(patientId);
            props.loadScratchPadNote(patientId);
        }
    } else {
        props.showErrorMessage({
            code: OperationResultCode.InvalidFilter,
            errorMessage: `Invalid route, patient ID: ${rawPatientId}`
        });
    }
}