import {PatientFilterType} from "doctors-dashboard/http-clients/index";

export const PatientFilterTypeDisplayPipe = (type: PatientFilterType): string => {
    switch (type) {
        case PatientFilterType.Active:
            return 'Active patients';
        case PatientFilterType.NewPatients:
            return 'Pending Orders';
        case PatientFilterType.InactivePatients:
            return 'Inactive patients';
        case PatientFilterType.GlobalSearch:
            return 'Global Search';
    }

    return `Unknown: ${type}`;
}

export const PatientFilterTypeUrlPipe = (type: PatientFilterType): string => {
    switch (type) {
        case PatientFilterType.Active:
            return 'Active';
        case PatientFilterType.NewPatients:
            return 'New';
        case PatientFilterType.InactivePatients:
            return 'Inactive';
        case PatientFilterType.GlobalSearch:
            return 'Global';
    }

    return `Unknown_${type}`;
}