import {createSelector} from "reselect";
import {getPatientsState} from "../../store/patients.selectors";
import {getPagesCount} from "orpyx-web-common";

export class PatientsListSelectors {
    static readonly state = createSelector(getPatientsState, state => state.list);

    static readonly loading = createSelector(PatientsListSelectors.state, state => state.loading);
    static readonly error = createSelector(PatientsListSelectors.state, state => state.error);

    static readonly total = createSelector(PatientsListSelectors.state, state => state.total);
    static readonly pages = createSelector(PatientsListSelectors.state, state => getPagesCount(state.total, state.search.count));

    static readonly patients = createSelector(PatientsListSelectors.state, state => state.patients);
    static readonly search = createSelector(PatientsListSelectors.state, state => state.search);

}