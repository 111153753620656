import {all, put, takeEvery} from "redux-saga/effects";
import {UserAccountStatus, DashboardUserType} from "doctors-dashboard/http-clients/index";
import {StoreAction} from "orpyx-web-common";
import {RemotePatientMonitoringSigninActions} from "./remote-patient-monitoring-signin.actions";
import {
    DOCTORS_DASHBOARD_LOGIN_COMPLETE,
    DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE_SUCCESSFULLY,
    DOCTORS_DASHBOARD_LOGIN_SUCCESSFULLY
} from "../../../doctor-dashboard/auth/store/doctors-dashboard-auth-actions";


export const addDays = (date: Date, days: number): Date => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function* onSetRemoteUserToken(action: StoreAction<RemotePatientMonitoringSigninActions, string>) {
    const jwtToken: string = action.payload!;

    yield put(DOCTORS_DASHBOARD_LOGIN_SUCCESSFULLY({
        type: DashboardUserType.RemotePatientMonitoringUser,
        accessToken: {
            token: jwtToken,
            expiredAt: addDays(new Date(), 1)
        },
        accountIdentifier: 'RPM',
        name: 'RPM'
    }));

    yield put(DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE_SUCCESSFULLY({
        dashboardUserId: 10,
        firstName: 'RPM',
        lastName: 'RPM',
        title: 'RPM',
        email: 'RPM@RPM',
        status: UserAccountStatus.Activated,
    }));

    yield put(DOCTORS_DASHBOARD_LOGIN_COMPLETE());
}

export default function* RemotePatientMonitoringSignInSagas() {
    yield all([
        yield takeEvery(RemotePatientMonitoringSigninActions.SET_USER_TOKEN, onSetRemoteUserToken),
    ]);
}
