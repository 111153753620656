import {createSelector} from "reselect";
import {getPatientsState} from "../../../store/patients.selectors";

export class PatientStepCountSelectors {
    static readonly state = createSelector(getPatientsState, state => state.information.stepCounts);

    static readonly loading = createSelector(PatientStepCountSelectors.state, state => state.loading);
    static readonly initialized = createSelector(PatientStepCountSelectors.state, state => state.initialized);
    static readonly error = createSelector(PatientStepCountSelectors.state, state => state.error);

    static readonly summary = createSelector(PatientStepCountSelectors.state, state => state.summary);

    //  We expect there will always be two months
    static readonly months = createSelector(PatientStepCountSelectors.summary, summary => summary ?? []);
    static readonly lastMonth = createSelector(PatientStepCountSelectors.months, months => months.length === 2 ? months[0] : undefined);
    static readonly thisMonth = createSelector(PatientStepCountSelectors.months, months => months.length === 2 ? months[1] : undefined);
}