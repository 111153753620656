import {TableCell} from "@material-ui/core";
import EventNoteIcon from "@material-ui/icons/EventNote";
import {AdvancedIconButton} from "orpyx-web-common";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import TableRow from "@material-ui/core/TableRow";
import React, {memo} from "react";
import {PatientMonthlyReportsTableItemProps} from "./patient-monthly-reports-table-item.interface";

export const PatientMonthlyReportsTableItem = memo((props: PatientMonthlyReportsTableItemProps) => {
    return (<TableRow>
        <TableCell>
            <EventNoteIcon className={props.classes.icon}/>
            {props.report.name}
        </TableCell>
        <TableCell>
            <AdvancedIconButton
                disabled={props.disabled}
                tooltip={`Download report at ${props.report.name}`}
                icon={GetAppOutlinedIcon}
                onClick={() => props.onClickedDownloadMonthlyReport(props.report)}
            />
        </TableCell>
    </TableRow>);
});
