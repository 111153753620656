import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

export const usePatientMetricsAlertRegionStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        position: "relative",
        width: '200px',
        margin: 'auto',
        paddingTop: '25px',
        paddingBottom: '15px',
    },
    stats: {
        position: "absolute",
        color: '#FFF',
        fontSize: '12px',
    },
    lOuterToes: {
        left: "10px",
        top: "60px",
    },
    lInnerToes: {
        left: "40px",
        top: "60px",
    },
    lOuterMetas: {
        left: "12px",
        top: "100px",
    },
    lInnerMetas: {
        left: "40px",
        top: "100px",
    },
    lMid: {
        top: '155px',
        left: '30px',
    },
    lHeel: {
        top: '190px',
        left: '33px',
    },
    rOuterToes: {
        left: "170px",
        top: "60px",
    },
    rInnerToes: {
        left: "138px",
        top: "60px",
    },
    rOuterMetas: {
        left: "172px",
        top: "100px",
    },
    rInnerMetas: {
        left: "142px",
        top: "100px",
    },
    rMid: {
        left: "154px",
        top: "155px"
    },
    rHeel: {
        left: "149px",
        top: "190px",
    },
    title: {
        textAlign: 'center',
    },
    right: {
        textAlign: 'right',
    },
}));
