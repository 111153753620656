import { GetScratchPadNoteResponse } from "doctors-dashboard";
import produce from "immer";
import { OperationErrorType, StoreAction } from "orpyx-web-common";
import { ScratchPadNoteActions } from "./scratch-pad-notes.actions";

export interface PatientScratchPadNoteState {
    loading: boolean;
    error: OperationErrorType;

    scratchPadNote: GetScratchPadNoteResponse | undefined;

    setUnloadListener: boolean;
}

const initialState: PatientScratchPadNoteState = {
    loading: false,
    error: undefined,

    scratchPadNote: undefined,
    setUnloadListener: false,
};

const ScratchPadNoteReducers = (state = initialState, action: StoreAction<ScratchPadNoteActions>): PatientScratchPadNoteState => {
    return produce(state, (draft: PatientScratchPadNoteState) => {
        switch(action.type) {
            case ScratchPadNoteActions.GET_PATIENT_SCRATCH_PAD_NOTE:
                draft.loading = true;
                draft.error = undefined;
                draft.scratchPadNote = undefined;
                break;
            case ScratchPadNoteActions.GET_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY:
                draft.loading = false;
                draft.error = undefined;
                draft.scratchPadNote = action.payload;
                break;
            case ScratchPadNoteActions.GET_PATIENT_SCRATCH_PAD_NOTE_FAILED:
                draft.loading = false;
                draft.error = action.payload;
                break;
            case ScratchPadNoteActions.UPDATE_PATIENT_SCRATCH_PAD_NOTE:
                draft.loading = true;
                draft.error = undefined;
                break;
            case ScratchPadNoteActions.UPDATE_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY:
                draft.loading = false;
                draft.error = undefined;
                draft.scratchPadNote = action.payload;
                break;
            case ScratchPadNoteActions.UPDATE_PATIENT_SCRATCH_PAD_NOTE_FAILED:
                draft.loading = false;
                draft.error = action.payload;
                break;
            case ScratchPadNoteActions.SET_PATIENT_SCRATCH_PAD_UNLOAD_LISTENER:
                draft.setUnloadListener = action.payload;
                break;
        }
    });
};

export default ScratchPadNoteReducers;