import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const useDoctorsDashboardNavbarStyle = makeStyles((theme: Theme) => createStyles({
        root: {
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },

        login: {
            color: 'black',
            borderColor: 'black',
        },
        link: {
            color: 'white',
            textDecoration: 'unset',
            '&.active': {
                fontWeight: '600',
            },
        },
        primaryColor: {
            color: '#00b6b1',
            '&:visited': {
                color: '#00b6b1',
            },
        }
    }),
);
