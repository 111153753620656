import * as React from "react";
import {memo} from "react";
import {connect} from "react-redux";

import {PatientInformationAdherenceMeta, PatientInformationAdherenceOwnProps} from "./patient-information-adherence.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {PatientStatisticSelectors} from "../../../patient-statistic/store/patient-statistic.selectors";
import {Grid, Typography} from "@material-ui/core";
import {PatientAdherenceDailyUse} from "../../../patient-statistic/components/patient-adherence-daily-use/patient-adherence-daily-use";
import {PatientAdherenceChart} from "../../../patient-statistic/components/patient-adherence-chart/patient-adherence-chart";
import {DateRangePipe, getFirstMonthDay, getLastMonthDay, OperationProgress, OperationSkeleton} from "orpyx-web-common";
import {usePatientInformationPageStyles} from "../patient-information-page/patient-information-page.style";
import {PatientInformationTitle} from "../../../patient-information-title/patient-information-title";
import {PatientInformationPageSelectors} from "../../store/patient-information-page.selectors";

const PatientInformationAdherenceContent = memo((props: PatientInformationAdherenceMeta) => {
    const classes = usePatientInformationPageStyles();
    const {lastMonth, thisMonth} = props.statistic;

    return (<Grid container spacing={3} id="patient-information-adherence-grid">
        <Grid item xs={6}>
            <div className={classes.content} id="patient-information-adherence-last-month">
                <div className={classes.centered}>
                    Last Month<br/>
                    {DateRangePipe({
                        begin: getFirstMonthDay(lastMonth?.month),
                        end: getLastMonthDay(lastMonth?.month),
                    })}
                </div>

                <PatientAdherenceDailyUse statistic={lastMonth}/>
                <div className={classes.centered}>
                    <Typography variant="caption">
                        Average hours the device is in use each day.
                    </Typography>
                </div>
            </div>
        </Grid>

        <Grid item xs={6}>
            <div className={classes.content} id="patient-information-adherence-this-month">
                <div className={classes.centered}>
                    This Month<br/>
                    {DateRangePipe({
                        begin: getFirstMonthDay(thisMonth?.month),
                        end: getLastMonthDay(thisMonth?.month),
                    })}
                </div>

                <PatientAdherenceDailyUse statistic={thisMonth}/>
                <div className={classes.centered}>
                    <Typography variant="caption">
                        Average hours the device is in use each day.
                    </Typography>
                </div>

            </div>
        </Grid>

        <Grid item xs={12}>
            <div className={classes.content} id="patient-information-adherence-this-month-daily-usage">
                <div className={classes.centered}>
                    This Month<br/>
                    {DateRangePipe(props.monitoringCycle)}
                </div>
                <PatientAdherenceChart/>
            </div>
        </Grid>
    </Grid>);
});

const PatientInformationAdherenceLoading = memo((props: PatientInformationAdherenceMeta) => {
    const classes = usePatientInformationPageStyles();

    return (<Grid container spacing={3}>
        <Grid item xs={6}>
            <div className={classes.content}>
                <OperationSkeleton height={40} rows={8}/>
            </div>
        </Grid>

        <Grid item xs={6}>
            <div className={classes.content}>
                <OperationSkeleton height={40} rows={8}/>
            </div>
        </Grid>

        <Grid item xs={12}>
            <div className={classes.content}>
                <OperationProgress error={props.error} inProcess={props.loading}/>
                {!props.initialized && <OperationSkeleton height={40} rows={8}/>}
            </div>
        </Grid>
    </Grid>)
});

const PatientInformationAdherenceTemplate = (props: PatientInformationAdherenceMeta) => {
    return (
        <>
            <PatientInformationTitle text="Adherence"/>
            {!props.initialized && <PatientInformationAdherenceLoading {...props}/>}
            {props.initialized && <PatientInformationAdherenceContent {...props}/>}
        </>
    );
};


const mapStateToProps = (state: IOrpyxApplicationStore): PatientInformationAdherenceOwnProps => {
    return {
        initialized: PatientStatisticSelectors.initialized(state),
        loading: PatientStatisticSelectors.loading(state),
        error: PatientStatisticSelectors.error(state),
        monitoringCycle: PatientInformationPageSelectors.monitoringCycle(state),
        statistic: {
            thisMonth: PatientStatisticSelectors.thisMonth(state),
            lastMonth: PatientStatisticSelectors.lastMonth(state),
        },
    };
};

export const PatientInformationAdherence = memo(connect(mapStateToProps)(PatientInformationAdherenceTemplate));