export const getExtension = (filename: string) : string => {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2)
}
  
export const getFilenameWithoutExtension = (filename: string) : string => {
    return filename.slice(0, filename.lastIndexOf('.'))
}

export const nameLengthValidator = (file: File) => {
    if (file && file.name && file.name.length > 200) {
      return {
        code: "name-too-large",
        message: `Name is larger than ${200} characters`
      };
    }
  
    return null
}