import {createStyles, Theme} from "@material-ui/core";
import background from "../../../../../images/loginPage/HomeBackground.jpg";
import {makeStyles} from "@material-ui/core/styles";

export const useHomePageStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '95vh',
        textAlign: "center",
        verticalAlign: "middle",
    },
    logo: {
        width: '50%',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: 'auto',
        position: 'absolute',
    },
    homePage: {
        "& a": {
            color: 'white'
        }
    },
}));

export default useHomePageStyle;
