import {createSelector} from "reselect";
import {getPatientsState} from "../../../store/patients.selectors";

export class PatientStatisticSelectors {
    static readonly state = createSelector(getPatientsState, state => state.information.statistic);

    static readonly loading = createSelector(PatientStatisticSelectors.state, state => state.loading);
    static readonly initialized = createSelector(PatientStatisticSelectors.state, state => state.initialized);
    static readonly error = createSelector(PatientStatisticSelectors.state, state => state.error);

    static readonly summary = createSelector(PatientStatisticSelectors.state, state => state.summary);

    //  We expect there will always be two months
    static readonly months = createSelector(PatientStatisticSelectors.summary, summary => summary?.months ?? []);
    static readonly lastMonth = createSelector(PatientStatisticSelectors.months, months => months.length === 2 ? months[0] : undefined);
    static readonly thisMonth = createSelector(PatientStatisticSelectors.months, months => months.length === 2 ? months[1] : undefined);
}