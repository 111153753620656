import * as React from 'react';
import {memo} from 'react';
import {connect} from 'react-redux';
import {IOrpyxApplicationStore} from 'modules/store/application.reducers';
import {ChartComponent, DateTime, Inject, LineSeries, SeriesCollectionDirective, SeriesDirective, RangeAreaSeries, Tooltip,} from '@syncfusion/ej2-react-charts';
import {usePatientTemperatureChartStyles} from "./patient-temperature-chart.styles";
import {
    PatientTemperatureChartEvents,
    PatientTemperatureChartMeta,
    PatientTemperatureChartOwnProps,
    PatientTemperatureChartProps,
    PatientTemperatureChartValues
} from "./patient-temperature-chart.interface";
import {bindActionCreators, Dispatch} from "redux";
import {PatientTemperatureSelectors} from "../../store/patient-temperature.selectors";
import {isNullOrUndefined, OperationProgress, OperationSkeleton, PropertyNamePipe} from "orpyx-web-common";
import {TemperatureComparisonPipe} from "../../pipes/temperature-comparison-pipe";
import {
    PatientTemperatureAreaAnimation,
    PatientTemperatureAreaData,
    PatientTemperatureAreaMarkers,
    PatientTemperatureChartAxisX,
    PatientTemperatureChartAxisY, PatientTemperatureChartRender,
    PatientTemperatureChartTooltip
} from "./patient-temperature-chart.axis";
import {PatientTemperatureSensors} from "../../data/enums/patient-temperature-sensors";
import {PatientInformationPageSelectors} from "../../../patient-information-page/store/patient-information-page.selectors";

const generateChartValues = (props: PatientTemperatureChartMeta): PatientTemperatureChartValues[] => {
    if (isNullOrUndefined(props.temperature.model?.dailySummary)) {
        return [];
    }
    return props.temperature.model!.dailySummary!.map(p => ({
        temperature: TemperatureComparisonPipe(props.temperature.options, p),
        summaryDate: p.dateTimeToDay!.local(true),
        activeSensorStyle: (PatientTemperatureSensors.find(q => q.value === props.temperature.options.sensor))?.color ?? 'black',
    })).filter(x => !isNullOrUndefined(x.temperature));
};

const PatientTemperatureChartTemplate = (props: PatientTemperatureChartMeta) => {
    const classes = usePatientTemperatureChartStyles();
    const chartData = generateChartValues(props);
    const selectedChartColor = chartData[0]?.activeSensorStyle ?? 'black';
    
    return (
        <>
            <div className={classes.dates}>
                Temperature Difference<br/>
            </div>

            <OperationProgress inProcess={props.loading} error={props.error}/>
            {props.loading && <OperationSkeleton rows={10} height={40}/>}

            <ChartComponent
                height="350px"
                className={classes.chart}
                palettes={[selectedChartColor]}
                primaryXAxis={PatientTemperatureChartAxisX(props.temperature.monitoringCycle.begin!, props.temperature.monitoringCycle.end!)}
                primaryYAxis={PatientTemperatureChartAxisY}
                tooltip={PatientTemperatureChartTooltip}
                tooltipRender={PatientTemperatureChartRender}
            >
                <Inject services={[LineSeries, RangeAreaSeries, DateTime, Tooltip]}/>
                <SeriesCollectionDirective>
                    <SeriesDirective
                        dataSource={PatientTemperatureAreaData(props.temperature.monitoringCycle.begin!, props.temperature.monitoringCycle.end!)}
                        xName='x'
                        width={2}
                        fill={'#D3D3D3'}
                        opacity={0.5}
                        low={'low'}
                        high={'high'}
                        type='RangeArea'
                    >
                    </SeriesDirective>
                    <SeriesDirective
                        dataSource={chartData}
                        xName={PropertyNamePipe(chartData[0], 'summaryDate')}
                        yName={PropertyNamePipe(chartData[0], 'temperature')}
                        width={1}
                        type='Line'
                        marker={PatientTemperatureAreaMarkers(selectedChartColor)}
                        animation={PatientTemperatureAreaAnimation}
                    >
                    </SeriesDirective>

                </SeriesCollectionDirective>
            </ChartComponent>
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientTemperatureChartOwnProps => {
    return {
        loading: PatientTemperatureSelectors.loading(state),
        error: PatientTemperatureSelectors.error(state),

        temperature: {
            options: PatientTemperatureSelectors.options(state),
            model: PatientTemperatureSelectors.summary(state)!,

            monitoringCycle: PatientInformationPageSelectors.monitoringCycle(state)!,
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientTemperatureChartProps): PatientTemperatureChartEvents => {
    return bindActionCreators({}, dispatch);
};

export const PatientTemperatureChart = memo(connect(mapStateToProps, mapDispatchToProps)(PatientTemperatureChartTemplate));

