import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const usePatientTemperatureComparisonStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        padding: '0 50px',
    },
    title: {
        fontSize: '14px',
        color: '#000',
        letterSpacing: '-0.15px',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));
