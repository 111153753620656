import {PatientsListPageMeta} from "./patients-list-page.interface";
import {PatientsSearchQuery, toQueryString} from "../../models/patients-search-query";
import {PatientFilterType} from "doctors-dashboard/http-clients/index";
import {ChangedSearchOption, ChangedSearchOptions, ControlValidator, FormControlValidators, PagingQuery} from "orpyx-web-common";

const PatientsListTermValidators = new ControlValidator([
    FormControlValidators.minLength(2),
    FormControlValidators.maxLength(32),
]);

export const onPatientsListPageLoaded = (props: PatientsListPageMeta) => () => {
    const params = new URLSearchParams(props.location.search);
    const query = PatientsSearchQuery.parse(
        params.has('term') ? params.get('term')! : undefined,
        params.has(PatientsSearchQuery.PARAM_FILTER_BY) ? params.get(PatientsSearchQuery.PARAM_FILTER_BY)! : undefined,

        props.patientType,

        params.has(PatientsSearchQuery.PARAM_ORDER_BY) ? params.get(PatientsSearchQuery.PARAM_ORDER_BY)! : undefined,
        params.has(PatientsSearchQuery.PARAM_ORDER_BY_ASC) ? params.get(PatientsSearchQuery.PARAM_ORDER_BY_ASC)! : undefined,

        params.has(PatientsSearchQuery.PARAM_OFFSET) ? params.get(PatientsSearchQuery.PARAM_OFFSET)! : undefined,
        params.has(PatientsSearchQuery.PARAM_COUNT) ? params.get(PatientsSearchQuery.PARAM_COUNT)! : undefined,
    );

    props.refreshPatientsList(query);
};


export const onPatientsListTermValidation = (term: string | undefined) => {
    return PatientsListTermValidators.validate(term);
};

const generatePatientsSearchQuery = (props: PatientsListPageMeta, options: ChangedSearchOptions): PatientsSearchQuery => {
    if (options.changed === ChangedSearchOption.Term) {
       return  {
            ...props.search,
            term: options.term,
            offset: PagingQuery.DEFAULT_PAGE
        };
    } else if (options.changed === ChangedSearchOption.OrderBy) {
        return  {
            ...props.search,
            term: options.term,
            orderBy: options.orderBy!.value,
            orderByAsc: options.orderBy!.orderByAsc ?? PatientsSearchQuery.DEFAULT_ORDER_BY_METHOD
        };
    } else if (options.changed === ChangedSearchOption.FilterBy) {
        return  {
            ...props.search,
            term: options.term,
            filterBy: options.filterBy!.key,
            offset: PagingQuery.DEFAULT_PAGE
        };
    }
    throw new Error(`Invalid ChangedSearchOption ${options.changed}`)
};

export const onPatientsListSearchOptionsChanged = (props: PatientsListPageMeta) => (options: ChangedSearchOptions): void => {
    const searchQuery = generatePatientsSearchQuery(props, options);
    onSearchQueryChanged(props, searchQuery);
};

export const onPatientsListPatientTypeChanged = (props: PatientsListPageMeta) => (patientFilterType: PatientFilterType) => {
    const searchQuery: PatientsSearchQuery = {...props.search, patientsType: patientFilterType, offset: PagingQuery.DEFAULT_PAGE};
    onSearchQueryChanged(props, searchQuery);
};

export const onPatientsListPageChanged = (props: PatientsListPageMeta) => (page: number) => {
    const searchQuery: PatientsSearchQuery = {...props.search, offset: page};
    onSearchQueryChanged(props, searchQuery);
};

export const onPatientsListCountChanged = (props: PatientsListPageMeta) => (count: number) => {
    const searchQuery: PatientsSearchQuery = {...props.search, count: count, offset: PagingQuery.DEFAULT_PAGE};
    onSearchQueryChanged(props, searchQuery);
};

export const onSearchQueryChanged = (props: PatientsListPageMeta, searchQuery: PatientsSearchQuery) => {
    const urlQuery: string = toQueryString(searchQuery);
    props.history!.push(urlQuery);
};