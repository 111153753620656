import {AnimationModel, AxisModel, ITooltipRenderEventArgs, MarkerSettingsModel} from "@syncfusion/ej2-react-charts";
import moment from "moment";
import {FixedNumericPipe} from "orpyx-web-common";

const font = {
    fontFamily: 'Poppins'
};

export const patientAdherenceChartAnimation: AnimationModel = {
    enable: false
};

export const patientAdherenceChartMarkers: MarkerSettingsModel = {
    visible: true
};

export const patientAdherenceChartAxisX = (startDate: moment.Moment, endDate: moment.Moment): AxisModel => ({
    valueType: 'DateTime',
    minimum: startDate,
    maximum: endDate,
    labelFormat: 'MMM dd',
    edgeLabelPlacement: "Shift",
    titleStyle: font,
    labelStyle: font,
});

export const patientAdherenceChartAxisY = (maximumActiveTimeValue: number): AxisModel => ({
    minimum: 0,
    maximum: (maximumActiveTimeValue > 12) ? 24 : 12,
    interval: 2,
    title: 'Hours',
    titleStyle: font,
    labelStyle: font,
});

export const patientAdherenceChartLegendSettings = {
    toggleVisibility: false,
    textStyle: font
}

export const patientAdherenceChartTooltip = {
    enable: true,
    header: '${point.x}',
    textStyle: font,
};

export const patientAdherenceChartTooltipRender = (args: ITooltipRenderEventArgs | undefined) => {
    if (args) {
        const value = FixedNumericPipe(args.point.y as number, 1);
        args.text = `${value} Hours`;
    }
}