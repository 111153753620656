import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export const useDoctorsDashboardNavbarProfileStyleStyle = makeStyles((theme: Theme) => createStyles({
    icon: {
        width: '24px',
        height: '24px',
        verticalAlign: "middle",
    },
    profile: {
        marginLeft: '5px',
    },
}));
