import {PatientMonthlyReportsTableProps} from "./patient-monthly-reports-table.interface";
import Table from "@material-ui/core/Table";
import TableContainer from '@material-ui/core/TableContainer'
import React from "react";
import TableBody from "@material-ui/core/TableBody";
import {PatientMonthlyReportsTableItem} from "../patient-monthly-reports-table-item/patient-monthly-reports-table-item";

export const PatientMonthlyReportsTable = (props: PatientMonthlyReportsTableProps) => {
    return <TableContainer className={props.classes.tableContainer} id="patient-monthly-reports-table">
        <Table>
            <TableBody>
                {props.reports.map(report => <PatientMonthlyReportsTableItem
                    key={report.name!}
                    report={report}
                    disabled={!!props.inProcess[report.name!]}
                    classes={props.classes}
                    onClickedDownloadMonthlyReport={props.onClickedDownloadMonthlyReport}
                />)}

            </TableBody>
        </Table>
    </TableContainer>
};