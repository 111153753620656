import {DateRange, getFirstMonthDay, getLastMonthDay, OperationErrorType, StoreAction} from "orpyx-web-common";
import {IListCareNotesResultEntry} from "doctors-dashboard/http-clients/index";
import produce from "immer";
import {PatientActivityLogActions} from "./patient-activity-log.actions";
import {PatientActivityLogDownloadInProcess} from "../types/patient-activity-log-download-in-process";
import moment from "moment";

const DEFAULT_TAKE_CARE_NOTES_COUNT = 10;

// Obsolete, use PatientInformationPageState
const getCurrentMonitoringCycle = (): DateRange => {
    const now = moment().local();
    const startOfMonth = getFirstMonthDay(now)!;
    const endOfMonth = getLastMonthDay(startOfMonth)!;
    return {
        begin: startOfMonth,
        end: endOfMonth
    };
}

const getMonitoringCycleByFilter = (filterDate: Date): DateRange => {
    const date = moment(filterDate)?.local(true)!;
    return {
        begin: getFirstMonthDay(date)!,
        end: getLastMonthDay(date)!
    }
}

export interface PatientActivityLogState {
    loading: boolean;
    initialized: boolean;
    error: OperationErrorType;
    //  TODO: Use monitoringCycle from PatientInformationPageState
    monitoringCycle: DateRange,

    careNotes: {
        inProcess: PatientActivityLogDownloadInProcess;
        list: IListCareNotesResultEntry[];
        lastRowMarker: string | undefined;
        canGetMore: boolean;
        takeCount: number;
    }
}

const initialState: PatientActivityLogState = {
    loading: false,
    initialized: false,
    error: undefined,
    monitoringCycle: getCurrentMonitoringCycle(),
    careNotes: {
        inProcess: {},
        list: [],
        lastRowMarker: undefined,
        canGetMore: true,
        takeCount: DEFAULT_TAKE_CARE_NOTES_COUNT,
    }
};

const PatientActivityLogReducers = (state = initialState, action: StoreAction<PatientActivityLogActions>): PatientActivityLogState => {
    return produce(state, (draft: PatientActivityLogState) => {
        switch (action.type) {

            case PatientActivityLogActions.DOWNLOAD_PATIENT_CARE_NOTE_REPORT: {
                const careNote = action.payload as IListCareNotesResultEntry;
                const rowMarker: string = careNote.rowMarker!;
                draft.careNotes.inProcess[rowMarker] = true;
                break;
            }

            case PatientActivityLogActions.DOWNLOAD_PATIENT_CARE_NOTE_REPORT_SUCCESSFULLY:
            case PatientActivityLogActions.DOWNLOAD_PATIENT_CARE_NOTE_REPORT_FAILED: {
                const rowMarker: string = action.payload;
                delete draft.careNotes.inProcess[rowMarker];
                break;
            }

            case PatientActivityLogActions.GET_PATIENT_CARE_NOTES:
                const appendCareNotes: boolean = action.payload;
                draft.loading = true;
                if (!appendCareNotes) {
                    draft.loading = true;
                    draft.initialized = false;
                    draft.error = undefined;
                    draft.careNotes = {
                        inProcess: {},
                        canGetMore: false,
                        list: [],
                        takeCount: DEFAULT_TAKE_CARE_NOTES_COUNT,
                        lastRowMarker: undefined
                    };
                }
                break;

            case PatientActivityLogActions.APPEND_PATIENT_CARE_NOTE: {
                const newCareNotes = [action.payload as IListCareNotesResultEntry];

                var endDate = moment().local();
                if (draft.monitoringCycle.end != null) {
                    endDate = draft.monitoringCycle.end;
                }

                // OSID-396: Do not append note when month selector is on a previous month
                const careNotesForMonth = newCareNotes.filter(careNote =>careNote.noteDate!.local() <= endDate);
                
                //  First append new care note, then append exist care notes
                draft.careNotes.list = careNotesForMonth.concat(draft.careNotes.list)
                break;
            }

            case PatientActivityLogActions.GET_PATIENT_CARE_NOTES_SUCCESSFULLY: {
                draft.error = undefined;
                draft.initialized = true;
                draft.loading = false;

                const newCareNotes = action.payload as IListCareNotesResultEntry[];
                const lastRow = newCareNotes[newCareNotes.length - 1];

                draft.careNotes = {
                    list: draft.careNotes.list.concat(newCareNotes),
                    canGetMore: newCareNotes.length === draft.careNotes.takeCount,
                    lastRowMarker: lastRow?.rowMarker as string | undefined,
                    takeCount: draft.careNotes.takeCount,
                    inProcess: draft.careNotes.inProcess,
                };

                break;
            }

            case PatientActivityLogActions.GET_PATIENT_CARE_NOTES_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;

            case PatientActivityLogActions.RESET_PATIENT_CARE_NOTES:
                draft.error = undefined;
                draft.loading = false;
                draft.initialized = false;
                draft.monitoringCycle = getCurrentMonitoringCycle();
                draft.careNotes.list = [];
                break;

            case PatientActivityLogActions.GET_PATIENT_CARE_NOTES_DATA_CHANGE:
                const filterDate = action.payload!.filterDate;
                draft.error = undefined;
                draft.initialized = true;
                draft.loading = true;
                draft.careNotes.list = [];
                draft.monitoringCycle = getMonitoringCycleByFilter(filterDate);
        }
    });
};

export default PatientActivityLogReducers;
