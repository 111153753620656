import moment from "moment";
import {AxisModel, MarkerSettingsModel, AnimationModel, ITooltipRenderEventArgs} from "@syncfusion/ej2-react-charts";
import {FixedNumericPipe, isNullOrUndefined} from "orpyx-web-common";
import {PatientStepCountChartValues} from "./patient-step-count-chart.interface";

const font = {
    fontFamily: 'Poppins'
};

export const PatientStepCountAChartLegendSettings = {
    toggleVisibility: false,
    textStyle: font
};

export const PatientStepCountAreaAnimation: AnimationModel = {
    enable: false
};

export const PatientStepCountAreaMarkers: MarkerSettingsModel = {
    visible: true
};

export const PatientStepCountChartAxisX = (startDate: moment.Moment, endDate: moment.Moment): AxisModel => ({
    valueType: 'DateTime',
    minimum: startDate,
    maximum: endDate,
    labelFormat: 'MMM dd',
    edgeLabelPlacement: 'Shift',
    titleStyle: font,
    labelStyle: font,
});

interface IStepCountsPerInterval {
    steps: number;
    interval: number;
}

const StepCountsPerInterval: IStepCountsPerInterval[] = [
    {steps: 100000, interval: 20000},
    {steps: 50000, interval: 10000},
    {steps: 10000, interval: 5000},
    {steps: 5000, interval: 1000},
    {steps: 2000, interval: 500},
    {steps: 1000, interval: 300},
];

const getStepCountsInterval = (maxStepsCount: number) => {
    for (const stepsInInterval of StepCountsPerInterval) {
        if (maxStepsCount >= stepsInInterval.steps)
            return stepsInInterval.interval;
    }

    return 100;
}
const getMaxStepsPerDate = (stepValues: number[]): number => {
    if(isNullOrUndefined(stepValues) || stepValues.length === 0){
        return 0;
    }
    return Math.max(...stepValues);
}

export const PatientStepCountChartAxisY = (steps: PatientStepCountChartValues[]): AxisModel => {
    const stepValues = steps.map(step => Math.ceil(step.steps!));
    const maxStepsPerDate = getMaxStepsPerDate(stepValues);
    const stepsInterval = getStepCountsInterval(maxStepsPerDate);

    return {
        title: 'Steps',
        titleStyle: font,
        labelStyle: font,
        minimum: 0,
        maximum: maxStepsPerDate * 1.2,
        interval: stepsInterval,
    };
}

export const PatientStepCountChartTooltip = {
    enable: true,
    header: '${point.x}',
    textStyle: font,
};

export const PatientStepCountChartRender = (args: ITooltipRenderEventArgs | undefined) => {
    if (args) {
        const value = FixedNumericPipe(args.point.y as number, 0);
        args.text = `${value} steps`;
    }
}