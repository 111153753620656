import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import {createStyles, Typography} from '@material-ui/core';
import {withStyles, WithStyles} from '@material-ui/styles';
import {Teal} from "orpyx-web-common";

const styles = () => createStyles({
  root: {
    fontSize: 'smaller',
    position: 'fixed',
    left: '0',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
    color: Teal,
    zIndex: 1
  },
  activeLinkStyle: {
    color:  '#2D363B',
  },
  indented: {
    marginLeft: '10px',
  }
});

const footer = (props: WithStyles<typeof styles>) => (
      <div className={props.classes.root}>
        <Grid container>
          <Grid item xs={9}>
            <Typography id='index-copyright' className={props.classes.indented}>&copy; 2019 Orpyx&reg; Medical Technologies Inc.</Typography>
          </Grid>
        </Grid>
      </div>
    );

    export default withStyles(styles)(footer);
