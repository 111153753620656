import {PatientsListTableHeaderProps} from "./patients-list-table-header.interface";
import {TableCell, TableRow} from "@material-ui/core";
import * as React from "react";
import {memo} from "react";
import TableHead from "@material-ui/core/TableHead";

export const PatientsListTableHeader = memo((props: PatientsListTableHeaderProps) => {
    const classes = props.classes;

    return <TableHead>
        <TableRow>
            {props.canDisplayActionRequiredAlerts && <TableCell className={classes.tableHeadCell}>
                Action Required Flags
            </TableCell>}
            <TableCell className={classes.tableHeadCell}>Name</TableCell>
            <TableCell className={classes.tableHeadCell}>Patient ID</TableCell>

            {props.canDisplayDateOfPatientAuthorization && <TableCell className={classes.tableHeadCell}>Patient Initialization Date</TableCell>}
            {props.canDisplayDaysSinceLastReview && <TableCell className={classes.tableHeadCell}>Days Since Last Review</TableCell>}
            {props.canDisplayAllowAccessEndDate && <TableCell className={classes.tableHeadCell}>End Date of Patient Authorization</TableCell>}

            {props.canDisplayInsoleSerialNumbers && <>
                <TableCell className={classes.tableHeadCell}>Left insole S/N</TableCell>
                <TableCell className={classes.tableHeadCell}>Right insole S/N</TableCell>
            </>}

        </TableRow>
    </TableHead>;
});