import {Container, Grid} from "@material-ui/core";
import * as React from "react";
import {memo} from "react";
import {useCenteredMessageStyles} from "./centered-message.style";
import {CenteredMessageProps} from "./centered-message.interface";
import {Alert, AlertTitle} from "@material-ui/lab";

export const CenteredMessage = memo((props: CenteredMessageProps) => {
    const classes = useCenteredMessageStyles();
    return (<Container>
        <Grid container className={classes.root} spacing={4}>
            <Grid item xs={3}/>
            <Grid item xs={6}>
                {props.severity ? <Alert severity={props.severity!}>
                    <AlertTitle>
                        {props.title}
                    </AlertTitle>
                    {props.children}
                </Alert> : <div className={classes.content}>
                    {props.title && <div className={classes.title}>
                        {props.title}
                    </div>}

                    {props.children}
                </div>}
            </Grid>
        </Grid>
    </Container>);
});