import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {RemotePatientMonitoringSigninActions} from "./remote-patient-monitoring-signin.actions";

export interface RemotePatientMonitoringSignInState {
    error: OperationErrorType;
}

const initialState: RemotePatientMonitoringSignInState = {
    error: undefined,
};

export const RemotePatientMonitoringSignInReducers = (state = initialState, action: StoreAction<RemotePatientMonitoringSigninActions>): RemotePatientMonitoringSignInState => {
    return produce(state, (draft: RemotePatientMonitoringSignInState) => {
        switch (action.type) {
            case RemotePatientMonitoringSigninActions.SET_USER_TOKEN_FAILED:
                draft.error = action.payload;
                break;
        }
    });
};