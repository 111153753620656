import * as React from 'react';
import {Route, Switch} from 'react-router';
import NoMatch from 'pages/NoMatch';
import Footer from 'components/Footer';
import {HomePage} from "../modules/app/home-page/components/home-page/home-page";
import {buildPatientsListPage} from "../modules/doctor-dashboard/patients/patients-list/components/patients-list-page/patients-list-page";
import {PatientFilterType, DashboardUserType} from "doctors-dashboard/http-clients/index";
import {PatientFilterTypeUrlPipe} from "../modules/doctor-dashboard/pipes/patient-filter-type-pipe";
import {PatientInformationPage} from "../modules/doctor-dashboard/patients/patient-information/patient-information-page/components/patient-information-page/patient-information-page";
import {DoctorsDashboardPage} from "../modules/doctor-dashboard/components/doctors-dashboard-page/doctors-dashboard-page";
import {DoctorsPagesNavBar} from "../modules/doctor-dashboard/components/doctors-dashboard-navbar/doctors-dashboard-navbar/doctors-pages-nav-bar";
import {DoctorDashboardPageRoute} from "../modules/doctor-dashboard/components/doctor-dashboard-page-route/doctor-dashboard-page-route";
import {RemotePatientMonitoringSignInPage} from "../modules/app/remote-patient-monitoring-signin/components/remote-patient-monitoring-signin-page/remote-patient-monitoring-signin-page";
import { RedirectUriRoute } from '../modules/doctor-dashboard/auth/redirect-uri-route';
import { ScratchPadNotes } from 'modules/doctor-dashboard/patients/patient-information/patient-information-page/components/patient-information-page/scratch-pad-notes/scratch-pad-notes';

const routes = (
    <div>
        <Route path="/patients" component={DoctorsPagesNavBar}/>
        <Switch>
            <Route exact={true} path="/" component={HomePage}/>

            <DoctorDashboardPageRoute
                exact={true}
                path="/patients"
                component={DoctorsDashboardPage}
                allowedFor={[DashboardUserType.DashboardUser]}
            />

            <DoctorDashboardPageRoute
                path={`/patients/${PatientFilterTypeUrlPipe(PatientFilterType.Active)}`}
                component={buildPatientsListPage(PatientFilterType.Active)}
                allowedFor={[DashboardUserType.DashboardUser]}
            />

            <DoctorDashboardPageRoute
                path={`/patients/${PatientFilterTypeUrlPipe(PatientFilterType.InactivePatients)}`}
                component={buildPatientsListPage(PatientFilterType.InactivePatients)}
                allowedFor={[DashboardUserType.DashboardUser]}
            />

            <DoctorDashboardPageRoute
                path={`/patients/${PatientFilterTypeUrlPipe(PatientFilterType.NewPatients)}`}
                component={buildPatientsListPage(PatientFilterType.NewPatients)}
                allowedFor={[DashboardUserType.DashboardUser]}
            />

            <DoctorDashboardPageRoute
                path={`/patients/${PatientFilterTypeUrlPipe(PatientFilterType.GlobalSearch)}`}
                component={buildPatientsListPage(PatientFilterType.GlobalSearch)}
                allowedFor={[DashboardUserType.DashboardUser]}
                requiresCustomerCarePermissions={true}
            />

            <DoctorDashboardPageRoute
                path={`/patients/details/:patientId`}
                component={PatientInformationPage}
                allowedFor={[DashboardUserType.DashboardUser, DashboardUserType.RemotePatientMonitoringUser]}
            />

            <DoctorDashboardPageRoute
                path={`/patients/internalnotes/:patientId`}
                component={ScratchPadNotes}
                allowedFor={[DashboardUserType.DashboardUser]}
                requiresCustomerCarePermissions={true}
            />

            <Route exact={true} path="/dashboard/rpm/:patientId" component={RemotePatientMonitoringSignInPage}/>

            <Route exact={true} path="/redirecturi" component={RedirectUriRoute}/>

            <Route component={NoMatch}/>
        </Switch>
        <Footer/>
    </div>
);
export default routes;

