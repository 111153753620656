import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useDocumentUploadStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: 200,
      }
    },
    margin: {
      margin: theme.spacing(1)
    },
    clickable: {
      cursor: "pointer",
      outline: "none"
    },
    baseStyle: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      // main styles,
      "&:focus": {
        borderColor: '#2196f3'
      },
      "&:accept": {
        borderColor: '#00e676'
      },
      "&:reject": {
        borderColor: '#ff1744'
      }
    }
  })
);