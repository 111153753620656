import {FormControlValidators} from "orpyx-web-common";
import {PatientSubmitCareNoteFormProps, PatientSubmitCareNoteFormValues} from "./patient-submit-care-note-form.interface";
import {FormControlValidators as TimeSpanFormControlValidators} from "../../../../../../shared/pipes/to-minutes-and-seconds";

const PatientSubmitCareNoteForValidators = FormControlValidators.createValidators<PatientSubmitCareNoteFormValues>({
    comment: [
        FormControlValidators.maxLength(30000)
    ],
    timeSpent: [
        TimeSpanFormControlValidators.timespan,
        TimeSpanFormControlValidators.timespanRange(0, 60 * 60 * 6),
    ],
    actionId: [
        FormControlValidators.required,
        FormControlValidators.numeric,
        FormControlValidators.minValue(1),
    ],
    overrideTime: [],
}, (form, result) => {
    if (form.overrideTime) {
        const validationResult = FormControlValidators.required(form.timeSpent);

        if (validationResult) {
            result.timeSpent = validationResult;
        }
    }
});


export const PatientSubmitCareNoteForValidator = (values: PatientSubmitCareNoteFormValues, props: PatientSubmitCareNoteFormProps) => {
    const errors = PatientSubmitCareNoteForValidators.validate(values);
    return errors;
};
