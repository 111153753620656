import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {DoctorsDashboardActionRequiredAlertsHttpClient, IGetPatientInformationResponse, IListActionRequiredAlertsResult,} from "doctors-dashboard/http-clients/index";
import {ORPYXSI_API_URL} from "../../../../../../appConstants";
import {
    GET_PATIENT_ACTION_REQUIRED_ALERTS_FAILED,
    GET_PATIENT_ACTION_REQUIRED_ALERTS_SUCCESSFULLY,
    PatientInformationActionRequiredAlertsActions
} from "./patient-information-action-required-alerts.actions";
import {PatientSubmitCareNoteActions} from "../../patient-submit-care-note/store/patient-submit-care-note.actions";
import {PatientInformationPageSelectors} from "../../patient-information-page/store/patient-information-page.selectors";

const API_VERSION = '1';

function* onFetchPatientActionRequiredAlerts() {
    try {
        const patient: IGetPatientInformationResponse = yield select(PatientInformationPageSelectors.patient);
        const api = new DoctorsDashboardActionRequiredAlertsHttpClient(ORPYXSI_API_URL);

        const data: IListActionRequiredAlertsResult = yield call(
            [api, api.getActionRequiredAlerts],
            patient.patientId!,
            API_VERSION
        );

        yield put(GET_PATIENT_ACTION_REQUIRED_ALERTS_SUCCESSFULLY(data.entries!));
    } catch (e) {
        yield put(GET_PATIENT_ACTION_REQUIRED_ALERTS_FAILED(e));
    }
}

export default function* PatientInformationActionRequiredAlertsSagas() {
    yield all([
        yield takeEvery(PatientInformationActionRequiredAlertsActions.GET_PATIENT_ACTION_REQUIRED_ALERTS, onFetchPatientActionRequiredAlerts),
        yield takeEvery(PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE_SUCCESSFULLY, onFetchPatientActionRequiredAlerts),
    ]);
}
