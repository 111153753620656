import {createSelector} from "reselect";
import {getPatientsState} from "../../../store/patients.selectors";

export class PatientInformationPageSelectors {
    static readonly state = createSelector(getPatientsState, state => state.information.information);

    static readonly loading = createSelector(PatientInformationPageSelectors.state, state => state.loading);
    static readonly error = createSelector(PatientInformationPageSelectors.state, state => state.error);

    static readonly patient = createSelector(PatientInformationPageSelectors.state, state => state.patient!);

    static readonly automationTrackingTimeStartDate = createSelector(PatientInformationPageSelectors.state, state => state.automationTrackingTimeStartDate);

    static readonly monitoringCycle = createSelector(PatientInformationPageSelectors.state, state => state.monitoringCycle);
}