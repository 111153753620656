import { ORPYXSI_API_URL } from "appConstants";
import { DoctorsDashboardPatientScratchPadHttpClient, GetScratchPadNoteResponse, UpsertScratchPadNoteModel, UpsertScratchPadNoteResponse } from "doctors-dashboard";
import { ENQUEUE_SNACKBAR, StoreAction } from "orpyx-web-common";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { GET_PATIENT_SCRATCH_PAD_NOTE_FAILED, GET_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY, ScratchPadNoteActions, UPDATE_PATIENT_SCRATCH_PAD_NOTE_FAILED, UPDATE_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY } from "./scratch-pad-notes.actions";

const API_VERSION = '1';

function* onScratchPadNoteFetch(action: StoreAction<ScratchPadNoteActions>) {
    try {
        const patientId = action.payload!;
        const api = new DoctorsDashboardPatientScratchPadHttpClient(ORPYXSI_API_URL);

        const data: GetScratchPadNoteResponse = yield call(
            [api, api.getPatientScratchPadNote],
            patientId,
            API_VERSION
        );

        yield put(GET_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY(data));
    } catch (e) {
        yield put(GET_PATIENT_SCRATCH_PAD_NOTE_FAILED(e));
    }
}

function* onScratchPadNoteUpdate(action: StoreAction<ScratchPadNoteActions>){
    try {
        const { patient, note, version } = action.payload!;
        const { patientId, firstName, lastName } = patient;

        const api = new DoctorsDashboardPatientScratchPadHttpClient(ORPYXSI_API_URL);

        const model = new UpsertScratchPadNoteModel();
        model.patientId = patientId;
        model.note = note;
        model.version = version;

        const data: UpsertScratchPadNoteResponse = yield call(
            [api, api.upsertPatientScratchPadNote],
            API_VERSION,
            patientId,
            model
        );

        yield put(UPDATE_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY(data));
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully updated note for ${firstName} ${lastName}`,
            options: {variant: "success"}
        }));
    } catch (e) {
        yield put(UPDATE_PATIENT_SCRATCH_PAD_NOTE_FAILED(e));
    }
}

export default function* ScratchPadNoteSagas() {
    yield all([
        yield takeEvery(ScratchPadNoteActions.GET_PATIENT_SCRATCH_PAD_NOTE, onScratchPadNoteFetch),
        yield takeEvery(ScratchPadNoteActions.UPDATE_PATIENT_SCRATCH_PAD_NOTE, onScratchPadNoteUpdate)
    ]);
}