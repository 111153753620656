import { GetScratchPadNoteResponse, IGetPatientInformationResponse, UpsertScratchPadNoteResponse } from "doctors-dashboard";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum ScratchPadNoteActions {
    GET_PATIENT_SCRATCH_PAD_NOTE = 'GET_PATIENT_SCRATCH_PAD_NOTE: GET_PATIENT_SCRATCH_PAD_NOTE',
    GET_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY = 'GET_PATIENT_SCRATCH_PAD_NOTE: GET_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY',
    GET_PATIENT_SCRATCH_PAD_NOTE_FAILED = 'GET_PATIENT_SCRATCH_PAD_NOTE: GET_PATIENT_SCRATCH_PAD_NOTE_FAILED',
    UPDATE_PATIENT_SCRATCH_PAD_NOTE = 'UPDATE_PATIENT_SCRATCH_PAD_NOTE: UPDATE_PATIENT_SCRATCH_PAD_NOTE',
    UPDATE_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY = 'UPDATE_PATIENT_SCRATCH_PAD_NOTE: UPDATE_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY',
    UPDATE_PATIENT_SCRATCH_PAD_NOTE_FAILED = 'UPDATE_PATIENT_SCRATCH_PAD_NOTE: UPDATE_PATIENT_SCRATCH_PAD_NOTE_FAILED',
    SET_PATIENT_SCRATCH_PAD_UNLOAD_LISTENER = 'SET_PATIENT_SCRATCH_PAD_UNLOAD_LISTENER: SET_PATIENT_SCRATCH_PAD_UNLOAD_LISTENER'
}

export const GET_PATIENT_SCRATCH_PAD_NOTE = (patientId: number): StoreAction<ScratchPadNoteActions> => ({
    type: ScratchPadNoteActions.GET_PATIENT_SCRATCH_PAD_NOTE, payload: patientId
});

export const GET_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY = (careNote: GetScratchPadNoteResponse): StoreAction<ScratchPadNoteActions> => ({
    type: ScratchPadNoteActions.GET_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY, payload: careNote
});

export const GET_PATIENT_SCRATCH_PAD_NOTE_FAILED = (error: OperationErrorType): StoreAction<ScratchPadNoteActions> => ({
    type: ScratchPadNoteActions.GET_PATIENT_SCRATCH_PAD_NOTE_FAILED, payload: error
});

export const UPDATE_PATIENT_SCRATCH_PAD_NOTE = (patient: IGetPatientInformationResponse, note: string, version: string): StoreAction<ScratchPadNoteActions> => ({
    type: ScratchPadNoteActions.UPDATE_PATIENT_SCRATCH_PAD_NOTE, payload: { patient, note, version }
});

export const UPDATE_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY = (careNote: UpsertScratchPadNoteResponse): StoreAction<ScratchPadNoteActions> => ({
    type: ScratchPadNoteActions.UPDATE_PATIENT_SCRATCH_PAD_NOTE_SUCCESSFULLY, payload: careNote
});

export const UPDATE_PATIENT_SCRATCH_PAD_NOTE_FAILED = (error: OperationErrorType): StoreAction<ScratchPadNoteActions> => ({
    type: ScratchPadNoteActions.UPDATE_PATIENT_SCRATCH_PAD_NOTE_FAILED, payload: error
});

export const SET_PATIENT_SCRATCH_PAD_UNLOAD_LISTENER = (value: boolean): StoreAction<ScratchPadNoteActions> => ({
    type: ScratchPadNoteActions.SET_PATIENT_SCRATCH_PAD_UNLOAD_LISTENER, payload: value
});
