import * as React from "react";
import {memo, useEffect} from "react";
import {Box, TextField as MuiTextField} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import {Field, withFormik} from "formik";
import {CheckboxWithLabel, TextField} from "formik-material-ui";
import {
    isNullOrEmpty,
    ModalWindowSubmitButton,
    OperationProgress,
    PropertyNamePipe,
    useModalWindowStyle
} from "orpyx-web-common";
import {PatientsCreateFormMeta, PatientSubmitCareNoteFormProps, PatientSubmitCareNoteFormValues} from "./patient-submit-care-note-form.interface";
import {PatientSubmitCareNoteForValidator} from "./patient-submit-care-note-form.validators";
import {onSubmitPatientSubmitCareNoteForm} from "./patient-submit-care-note-form.hooks";
import {CarePractitionerActionSelector} from "../care-practitioner-action-selector/care-practitioner-action-selector";
import InputAdornment from "@material-ui/core/InputAdornment";
import {TimeSpan} from "../../../../../../shared/utility/timeSpan";

const additionalMinutesInputAdornments = {
    endAdornment: (<InputAdornment position="start">override time</InputAdornment>)
};

const elapsedMinutesInputAdornments = {
    endAdornment: (<InputAdornment position="start">elapsed time</InputAdornment>)
};

const labelOverrideTime = {
    label: 'Override time',
};

const PatientSubmitCareNoteFormTemplate = (props: PatientsCreateFormMeta) => {
    const classes = useModalWindowStyle();

    useEffect(() => {
        props.setSubmitting(props.inProcess)
    }, [props.inProcess]);

    return (
        <form onSubmit={props.handleSubmit} onKeyUp={props.onActiveInput}>
            <Box mb={3} pr={3}>
                <FormLabel>
                    Comments /Assessment
                </FormLabel>
                <Field
                    component={TextField}
                    className={classes.input}
                    placeholder={'Enter comments / notes'}
                    name={PropertyNamePipe(props.values, 'comment')}
                    multiline={true}
                    rows={10}
                    type="text"
                />
            </Box>

            <Box mb={3} pr={3}>
                <FormLabel required={true}>
                    Action
                </FormLabel>

                <CarePractitionerActionSelector
                    classes={classes}
                    actions={props.actions}
                    selectedAction={props.values.actionId}
                    values={props.values}
                />
            </Box>

            <Box mb={3} pr={3}>
                <FormLabel required={true}>
                    Time Spent
                </FormLabel>
                {props.values.overrideTime ? <Field
                    component={TextField}
                    className={classes.input}
                    placeholder={'Override time'}
                    name={PropertyNamePipe(props.values, 'timeSpent')}
                    InputProps={isNullOrEmpty(props.values.timeSpent) ? null : additionalMinutesInputAdornments}
                    type="text"
                /> : <MuiTextField
                    className={classes.input}
                    value={TimeSpan.toHoursAndMinutesAndSeconds(props.trackedTime)}
                    InputProps={elapsedMinutesInputAdornments}
                    disabled={true}
                    type="text"
                />}
            </Box>

            <Box mb={3} pr={3}>
                <Field
                    component={CheckboxWithLabel}
                    name={PropertyNamePipe(props.values, 'overrideTime')}
                    Label={labelOverrideTime}
                    type='checkbox'
                />
            </Box>

            <OperationProgress error={props.error} inProcess={props.isSubmitting}/>

            <ModalWindowSubmitButton
                disabled={props.isSubmitting}
                content={'Submit'}
            />

        </form>
    );
};

export const PatientSubmitCareNoteForm = memo(withFormik<PatientSubmitCareNoteFormProps, PatientSubmitCareNoteFormValues>({
    validate: PatientSubmitCareNoteForValidator,
    handleSubmit: onSubmitPatientSubmitCareNoteForm,
    mapPropsToValues: (props: PatientSubmitCareNoteFormProps): PatientSubmitCareNoteFormValues => ({
        comment: '',
        actionId: '',
        timeSpent: '',
        overrideTime: false,
    }),
})(PatientSubmitCareNoteFormTemplate));
