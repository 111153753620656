import {PatientFilterTypeDisplayPipe, PatientFilterTypeUrlPipe} from "../pipes/patient-filter-type-pipe";
import {PatientFilterType} from "doctors-dashboard/http-clients/index";
import {ActionUrl} from "orpyx-web-common";

const generatePatientFilterUrl = (type: PatientFilterType): ActionUrl => {
    return {
        url: `/patients/${PatientFilterTypeUrlPipe(type)}`,
        title: PatientFilterTypeDisplayPipe(type)
    };
}

export const doctorsDashboardUrls: ActionUrl[] = [
    generatePatientFilterUrl(PatientFilterType.Active),
    generatePatientFilterUrl(PatientFilterType.InactivePatients),
    generatePatientFilterUrl(PatientFilterType.NewPatients),
    generatePatientFilterUrl(PatientFilterType.GlobalSearch),
];
