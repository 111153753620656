import {all, call, put, takeEvery} from 'redux-saga/effects';
import {ORPYXSI_API_URL} from "../../../../appConstants";
import {CarePractitionerActionActions, FETCH_CARE_PRACTITIONER_ACTIONS_FAILED, FETCH_CARE_PRACTITIONER_ACTIONS_SUCCESSFULLY} from "./care-practitioner-action.actions";
import {DoctorsDashboardActionRequiredAlertsHttpClient, ICarePractitionerActionDto} from "doctors-dashboard/http-clients/index";

const API_VERSION = '1';

function* onGetCarePractitionerActions() {
    try {
        const api = new DoctorsDashboardActionRequiredAlertsHttpClient(ORPYXSI_API_URL);
        const data: ICarePractitionerActionDto[] = yield call(
            [api, api.getCarePractitionerActions],
            API_VERSION
        );

        yield put(FETCH_CARE_PRACTITIONER_ACTIONS_SUCCESSFULLY(data));

    } catch (e) {
        yield put(FETCH_CARE_PRACTITIONER_ACTIONS_FAILED(e));
    }
}

export default function* CarePractitionerActionSagas() {
    yield all([
        takeEvery(CarePractitionerActionActions.FETCH_CARE_PRACTITIONER_ACTIONS, onGetCarePractitionerActions)
    ]);
}
