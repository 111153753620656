import produce from "immer";
import {ICarePractitionerActionDto} from "doctors-dashboard/http-clients/index";
import {CarePractitionerActionActions} from "./care-practitioner-action.actions";
import {OperationErrorType, StoreAction} from "orpyx-web-common";

export interface CarePractitionerActionState {
    initialized: boolean;
    actions: ICarePractitionerActionDto[];
    error: OperationErrorType;
}

const initialState: CarePractitionerActionState = {
    initialized: false,
    actions: [],
    error: undefined,
};

const CarePractitionerActionReducers = (state = initialState, action: StoreAction<CarePractitionerActionActions>): CarePractitionerActionState => {
    return produce(state, (draft: CarePractitionerActionState) => {
        switch (action.type) {
            case CarePractitionerActionActions.FETCH_CARE_PRACTITIONER_ACTIONS:
                draft.initialized = false;
                draft.error = undefined;
                draft.actions = [];
                break;

            case CarePractitionerActionActions.FETCH_CARE_PRACTITIONER_ACTIONS_SUCCESSFULLY:
                draft.initialized = true;
                draft.actions = action.payload;
                draft.error = undefined;
                break;

            case CarePractitionerActionActions.FETCH_CARE_PRACTITIONER_ACTIONS_FAILED:
                draft.initialized = false;
                draft.error = action.payload;
                draft.actions = [];
                break;
        }
    });
};

export default CarePractitionerActionReducers;
