import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {DoctorsDashboardPatientTemperatureStatsHttpClient, IGetPatientInformationResponse, IPatientTemperatureSummaryModel,} from "doctors-dashboard/http-clients/index";
import {ORPYXSI_API_URL} from "../../../../../../appConstants";
import {PatientInformationPageSelectors} from "../../patient-information-page/store/patient-information-page.selectors";
import {GET_PATIENT_TEMPERATURE_SUMMARY_FAILED, GET_PATIENT_TEMPERATURE_SUMMARY_SUCCESSFULLY, PatientTemperatureActions} from "./patient-temperature.actions";

const API_VERSION = '1';

function* onFetchPatientTemperatureSummary() {
    try {
        const patient: IGetPatientInformationResponse = yield select(PatientInformationPageSelectors.patient)!;
        const monitoringCycle = yield select(PatientInformationPageSelectors.monitoringCycle)!;

        const api = new DoctorsDashboardPatientTemperatureStatsHttpClient(ORPYXSI_API_URL);

        const data: IPatientTemperatureSummaryModel = yield call(
            [api, api.getPatientTemperatureStatistics],
            patient.patientId!,
            //  TODO: Fix types
            monitoringCycle.begin! as any,
            API_VERSION,
        );

        yield put(GET_PATIENT_TEMPERATURE_SUMMARY_SUCCESSFULLY(data));
    } catch (e) {
        yield put(GET_PATIENT_TEMPERATURE_SUMMARY_FAILED(e));
    }
}

export default function* PatientTemperatureSagas() {
    yield all([
        yield takeEvery(PatientTemperatureActions.GET_PATIENT_TEMPERATURE_SUMMARY, onFetchPatientTemperatureSummary),
    ]);
}
