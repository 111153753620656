import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";


import {
    PatientInformationMonthlyReportsEvents,
    PatientInformationMonthlyReportsMeta,
    PatientInformationMonthlyReportsOwnProps,
    PatientInformationMonthlyReportsProps
} from "./patient-information-monthly-reports.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {PatientMonthlyReportsSelectors} from "../../../patient-monthly-reports/store/patient-monthly-reports.selectors";
import {usePatientInformationPageStyles} from "../patient-information-page/patient-information-page.style";
import {Grid} from "@material-ui/core";
import {PatientInformationTitle} from "../../../patient-information-title/patient-information-title";
import {isNullOrEmpty, OperationProgress, OperationSkeleton} from "orpyx-web-common";
import {PatientMonthlyReports} from "../../../patient-monthly-reports/components/patient-monthly-reports/patient-monthly-reports";

const PatientInformationMonthlyReportsTemplate = (props: PatientInformationMonthlyReportsMeta) => {
    const classes = usePatientInformationPageStyles();

    return (<Grid item xs={12}>
        <PatientInformationTitle text="Monthly reports"/>
        <div className={classes.content}>
            <OperationProgress initialized={props.initialized} error={props.error}/>
            {!props.initialized && <OperationSkeleton height={40} rows={8}/>}
            {props.initialized && <PatientMonthlyReports/>}
            {isNullOrEmpty(props.monthlyReports) && <span>No monthly reports</span>}
            </div>
    </Grid>);

};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientInformationMonthlyReportsOwnProps => {
    return {
        monthlyReports: PatientMonthlyReportsSelectors.monthlyReports(state),

        initialized: PatientMonthlyReportsSelectors.initialized(state),
        loading: PatientMonthlyReportsSelectors.loading(state),
        error: PatientMonthlyReportsSelectors.error(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientInformationMonthlyReportsProps): PatientInformationMonthlyReportsEvents => {
    return bindActionCreators({}, dispatch);
};

export const PatientInformationMonthlyReports = memo(connect(mapStateToProps, mapDispatchToProps)(PatientInformationMonthlyReportsTemplate));
