import {DateRange, OperationErrorType, StoreAction} from "orpyx-web-common";
import {IListCareNotesResultEntry} from "doctors-dashboard/http-clients/index";

export enum PatientActivityLogActions {
    RESET_PATIENT_CARE_NOTES = 'GET_PATIENT_CARE_NOTES: RESET_PATIENT_CARE_NOTES',

    APPEND_PATIENT_CARE_NOTE = 'GET_PATIENT_CARE_NOTES: APPEND_PATIENT_CARE_NOTE',

    GET_PATIENT_CARE_NOTES = 'GET_PATIENT_CARE_NOTES: GET_PATIENT_CARE_NOTES',
    GET_PATIENT_CARE_NOTES_SUCCESSFULLY = 'GET_PATIENT_CARE_NOTES: GET_PATIENT_CARE_NOTES_SUCCESSFULLY',
    GET_PATIENT_CARE_NOTES_FAILED = 'GET_PATIENT_CARE_NOTES: GET_PATIENT_CARE_NOTES_FAILED',
    GET_PATIENT_CARE_NOTES_DATA_CHANGE = 'GET_PATIENT_CARE_NOTES: GET_PATIENT_CARE_NOTES_DATA_CHANGE',

    DOWNLOAD_PATIENT_CARE_NOTE_REPORT = 'GET_PATIENT_CARE_NOTES: DOWNLOAD_PATIENT_CARE_NOTE_REPORT',
    DOWNLOAD_PATIENT_CARE_NOTE_REPORT_SUCCESSFULLY = 'GET_PATIENT_CARE_NOTES: DOWNLOAD_PATIENT_CARE_NOTE_REPORT_SUCCESSFULLY',
    DOWNLOAD_PATIENT_CARE_NOTE_REPORT_FAILED = 'GET_PATIENT_CARE_NOTES: DOWNLOAD_PATIENT_CARE_NOTE_REPORT_FAILED',
}

export const APPEND_PATIENT_CARE_NOTE = (careNote: IListCareNotesResultEntry): StoreAction<PatientActivityLogActions> => ({
    type: PatientActivityLogActions.APPEND_PATIENT_CARE_NOTE, payload: careNote
});

export const RESET_PATIENT_CARE_NOTES = (): StoreAction<PatientActivityLogActions> => ({
    type: PatientActivityLogActions.RESET_PATIENT_CARE_NOTES
});

export const DOWNLOAD_PATIENT_CARE_NOTE_REPORT = (careNote: IListCareNotesResultEntry): StoreAction<PatientActivityLogActions> => ({
    type: PatientActivityLogActions.DOWNLOAD_PATIENT_CARE_NOTE_REPORT, payload: careNote
});

export const DOWNLOAD_PATIENT_CARE_NOTE_REPORT_SUCCESSFULLY = (rowMarker: string): StoreAction<PatientActivityLogActions> => ({
    type: PatientActivityLogActions.DOWNLOAD_PATIENT_CARE_NOTE_REPORT_SUCCESSFULLY, payload: rowMarker
});

export const DOWNLOAD_PATIENT_CARE_NOTE_REPORT_FAILED = (rowMarker: string, error: OperationErrorType): StoreAction<PatientActivityLogActions> => ({
    type: PatientActivityLogActions.DOWNLOAD_PATIENT_CARE_NOTE_REPORT_FAILED, payload: {rowMarker, error}
});

export const GET_PATIENT_CARE_NOTES = (appendCareNotes: boolean) => (): StoreAction<PatientActivityLogActions> => ({
    type: PatientActivityLogActions.GET_PATIENT_CARE_NOTES, payload: appendCareNotes
});

export const GET_PATIENT_CARE_NOTES_SUCCESSFULLY = (careNotes: IListCareNotesResultEntry[]): StoreAction<PatientActivityLogActions> => ({
    type: PatientActivityLogActions.GET_PATIENT_CARE_NOTES_SUCCESSFULLY, payload: careNotes
});

export const GET_PATIENT_CARE_NOTES_FAILED = (error: OperationErrorType): StoreAction<PatientActivityLogActions> => ({
    type: PatientActivityLogActions.GET_PATIENT_CARE_NOTES_FAILED, payload: error
});

export const GET_PATIENT_CARE_NOTES_DATA_CHANGE = (dateRange: DateRange) => (): StoreAction<PatientActivityLogActions> => ({
    type: PatientActivityLogActions.GET_PATIENT_CARE_NOTES_DATA_CHANGE, payload: dateRange
});
