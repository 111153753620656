import * as React from "react";
import {memo} from "react";
import {connect} from "react-redux";
import {PatientAdherenceChartMeta, PatientAdherenceChartOwnProps, PatientAdherenceChartValues} from "./patient-adherence-chart.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {ChartComponent, LineSeries, DateTime, Inject, Legend, SeriesCollectionDirective, SeriesDirective, Tooltip} from "@syncfusion/ej2-react-charts";
import {DateTimeConstants, getFirstMonthDay, getLastMonthDay, isNullOrUndefined, Teal} from "orpyx-web-common";
import {PatientStatisticSelectors} from "../../store/patient-statistic.selectors";
import {
    patientAdherenceChartAnimation,
    patientAdherenceChartAxisX,
    patientAdherenceChartAxisY,
    patientAdherenceChartLegendSettings, patientAdherenceChartMarkers,
    patientAdherenceChartTooltip, patientAdherenceChartTooltipRender
} from "./patient-adherence-chart.axis";
import {usePatientAdherenceChartStyles} from "./patient-adherence-chart.styles";

const generateChartValues = (props: PatientAdherenceChartMeta): PatientAdherenceChartValues[] => {
    if (isNullOrUndefined(props.statistic?.dailySummary)) {
        return [];
    }

    return props.statistic!.dailySummary!.map(q => ({
        summaryDate: q.dateTimeToDay!,
        activeTime: q.summary!.overall!.activeDurationSecs! / DateTimeConstants.SECONDS_IN_HOUR,
    }));
};

const getMaximumActiveTimeValue = (props: PatientAdherenceChartValues[]): number => {
    const activeTimeArray = props.map(v => v.activeTime );
    return Math.max(...activeTimeArray);
};

const PatientAdherenceChartTemplate = (props: PatientAdherenceChartMeta) => {
    const classes = usePatientAdherenceChartStyles();
    const chartData = generateChartValues(props);
    const maximumActiveTimeValue = getMaximumActiveTimeValue(chartData);

    const startDate = getFirstMonthDay(props.statistic?.month)!;
    const endDate = getLastMonthDay(props.statistic?.month)!;

    return (<ChartComponent
        className={classes.chart}
        primaryXAxis={patientAdherenceChartAxisX(startDate, endDate)}
        primaryYAxis={patientAdherenceChartAxisY(maximumActiveTimeValue)}
        tooltip={patientAdherenceChartTooltip}
        tooltipRender={patientAdherenceChartTooltipRender}
        legendSettings={patientAdherenceChartLegendSettings}
    >
        <Inject services={[LineSeries, Legend, DateTime, Tooltip]}/>
        <SeriesCollectionDirective>
            <SeriesDirective
                dataSource={chartData}
                xName='summaryDate'
                yName='activeTime'
                name={"Daily Usage"}
                type='Line'
                fill={Teal}
                animation={patientAdherenceChartAnimation}
                marker={patientAdherenceChartMarkers}
            >
            </SeriesDirective>
        </SeriesCollectionDirective>
    </ChartComponent>);
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientAdherenceChartOwnProps => {
    return {
        statistic: PatientStatisticSelectors.thisMonth(state),
    };
};

export const PatientAdherenceChart = memo(connect(mapStateToProps)(PatientAdherenceChartTemplate));

