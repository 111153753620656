import * as React from "react";
import {PatientStepCountChartProps, PatientStepCountChartValues} from "./patient-step-count-chart.interface";
import {getFirstMonthDay, getLastMonthDay, isNullOrUndefined, PropertyNamePipe} from "orpyx-web-common";
import {ChartComponent, DateTime, Inject, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective, Tooltip} from "@syncfusion/ej2-react-charts";
import {IPatientStepCountsSummary} from "doctors-dashboard";
import {
    PatientStepCountAChartLegendSettings,
    PatientStepCountAreaAnimation, PatientStepCountAreaMarkers,
    PatientStepCountChartAxisX,
    PatientStepCountChartAxisY,
    PatientStepCountChartRender,
    PatientStepCountChartTooltip
} from "./patient-step-count-chart.axis";

const generateChartData = (summary: IPatientStepCountsSummary | undefined): PatientStepCountChartValues[] => {
    if (isNullOrUndefined(summary)) {
        return [];
    }

    return summary!.count!.map(q => ({
        summaryDate: q!.date!,
        steps: q.count
    }));
};

export const PatientStepCountChart = (props: PatientStepCountChartProps) => {
    const chartData = generateChartData(props.summary);
    const startDate = getFirstMonthDay(props.summary?.month)!;
    const endDate = getLastMonthDay(props.summary?.month)!;

    return (<ChartComponent
        height="220px"
        className={props.className}
        primaryXAxis={PatientStepCountChartAxisX(startDate, endDate)}
        primaryYAxis={PatientStepCountChartAxisY(chartData)}
        legendSettings={PatientStepCountAChartLegendSettings}
        tooltip={PatientStepCountChartTooltip}
        tooltipRender={PatientStepCountChartRender}
    >
        <Inject services={[LineSeries, Legend, DateTime, Tooltip]}/>
        <SeriesCollectionDirective>
            <SeriesDirective
                dataSource={chartData}
                xName={PropertyNamePipe(chartData[0], "summaryDate")}
                yName={PropertyNamePipe(chartData[0], "steps")}
                width={1}
                animation={PatientStepCountAreaAnimation}
                marker={PatientStepCountAreaMarkers}
                name={"Number of Steps Per Day"}
                type='Line'
                opacity={0.6}
            >
            </SeriesDirective>
        </SeriesCollectionDirective>
    </ChartComponent>);

};
