import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IPatientOverallSummary} from "doctors-dashboard/http-clients/index";

export enum PatientStatisticActions {
    RESET_PATIENT_STATISTIC = 'GET_PATIENT_STATISTIC: RESET_PATIENT_STATISTIC',

    GET_PATIENT_STATISTIC = 'GET_PATIENT_STATISTIC: GET_PATIENT_STATISTIC',
    GET_PATIENT_STATISTIC_SUCCESSFULLY = 'GET_PATIENT_STATISTIC: GET_PATIENT_STATISTIC_SUCCESSFULLY',
    GET_PATIENT_STATISTIC_FAILED = 'GET_PATIENT_STATISTIC: GET_PATIENT_STATISTIC_FAILED',
}

export const RESET_PATIENT_STATISTIC = (): StoreAction<PatientStatisticActions> => ({
    type: PatientStatisticActions.RESET_PATIENT_STATISTIC
});

export const GET_PATIENT_STATISTIC = (): StoreAction<PatientStatisticActions> => ({
    type: PatientStatisticActions.GET_PATIENT_STATISTIC
});

export const GET_PATIENT_STATISTIC_SUCCESSFULLY = (statistic: IPatientOverallSummary): StoreAction<PatientStatisticActions> => ({
    type: PatientStatisticActions.GET_PATIENT_STATISTIC_SUCCESSFULLY, payload: statistic
});

export const GET_PATIENT_STATISTIC_FAILED = (error: OperationErrorType): StoreAction<PatientStatisticActions> => ({
    type: PatientStatisticActions.GET_PATIENT_STATISTIC_FAILED, payload: error
});