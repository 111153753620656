import {FormikBag} from "formik";
import {PatientSubmitCareNoteFormProps, PatientSubmitCareNoteFormValues} from "./patient-submit-care-note-form.interface";
import {TimeSpan} from "../../../../../../shared/utility/timeSpan";

export const onSubmitPatientSubmitCareNoteForm = (values: PatientSubmitCareNoteFormValues, bag: FormikBag<PatientSubmitCareNoteFormProps, PatientSubmitCareNoteFormValues>) => {
    bag.props.onSubmit({
        comment: values.comment,
        actionId: parseInt(values.actionId, 10),
        timeSpent: values.overrideTime ? TimeSpan.fromHoursAndMinutesAndSecondsToSeconds(values.timeSpent) : undefined,
    });
};