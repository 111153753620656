import * as React from "react";
import {memo} from "react";
import {Icon, IconButton, SvgIcon, TableCell, TableRow, Tooltip} from "@material-ui/core";
import { DocumentItemEvents, DocumentItemMeta } from "./document-item.interface";
import { AdvancedIconButton, DatePipe } from "orpyx-web-common";
import moment from "moment";
import CircularIntegration from "modules/shared/components/circular-progress/circular-progress-indicator";
import { DOWNLOAD_DOCUMENT, GET_DOCUMENT_STATUS } from "modules/doctor-dashboard/store/document-list/document-list.actions";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useDocumentItemStyle } from "./document-item.style";

const DocumentItemTemplate = memo((props: DocumentItemMeta) => {
    const classes = useDocumentItemStyle();
    let timer = React.useRef<number>();

    React.useEffect(() => {
        if(props.document.status !== 'Completed' && props.document.status !== 'Failed')
            onStatusCheck();

        return () => {
          clearTimeout(timer.current);
        };
      }, []);

    const downloadDocument = () => {
        if(props.document.status === 'Completed')
            props.onDownloadDocuments(props.documentId, props.document.fileName);
    }

    const onStatusCheck = () => {
        if(props.document.status !== 'Completed' && props.document.status !== 'Failed') {
            //try 5 times and it should get it done. - 5 * 3000 = 15000 = 15 seconds
            for(let timesTry = 0; timesTry < 5; timesTry++) {
                timer.current = window.setTimeout(() => {
                    props.onDocumentStatusCheck(props.documentId);
                }, 3000 * (timesTry + 1));
            }
        }
    }

    const getProgress = (status: string) : number => {
        if(status === 'Initiated') return 25;
        if(status === 'Uploading') return 55;
        if(status === 'Failed') return -1;
        if(status === 'Completed') return 100;
        return 40;
    }

    return <TableRow>
                <TableCell className={classes.cell}>
                    <Tooltip title={props.document.description || 'No description'} arrow>
                        <span id={props.document.createdOn}>{props.document.fileName}</span>
                    </Tooltip>
                </TableCell>
                <TableCell>{DatePipe(moment(props.document.createdOn))}</TableCell>
                <TableCell>
                    {props.document.status !== 'Completed' && <CircularIntegration progress={getProgress(props.document.status)} />}

                    {props.document.status === 'Completed' && <IconButton 
                        aria-label={`View ${props.document.fileName}`}
                        onClick={downloadDocument}>
                            <SvgIcon viewBox="0 0 24 24">
                                <path d="M19,3H5C3.89,3,3,3.9,3,5v14c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.11,3,19,3z M19,19H5V7h14V19z M13.5,13 c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5c0-0.83,0.67-1.5,1.5-1.5S13.5,12.17,13.5,13z M12,9c-2.73,0-5.06,1.66-6,4 c0.94,2.34,3.27,4,6,4s5.06-1.66,6-4C17.06,10.66,14.73,9,12,9z M12,15.5c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5 c1.38,0,2.5,1.12,2.5,2.5C14.5,14.38,13.38,15.5,12,15.5z"/>
                            </SvgIcon>
                        </IconButton>}
                </TableCell>
            </TableRow>;
});


const mapDispatchToProps = (dispatch: Dispatch): DocumentItemEvents => {
    return bindActionCreators({
        onDownloadDocuments: DOWNLOAD_DOCUMENT,
        onDocumentStatusCheck: GET_DOCUMENT_STATUS
    }, dispatch);
};

export const DocumentItem = memo(connect(null,mapDispatchToProps)(DocumentItemTemplate));
