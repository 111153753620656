import {PatientActivityLogTableProps} from "./patient-activity-log-table.interface";
import React, {memo} from "react";
import {usePatientActivityLogTableStyle} from "./patient-activity-log-table.style";
import Table from "@material-ui/core/Table";
import {PatientActivityLogTableHeader} from "../patient-activity-log-table-header/patient-activity-log-table-header";
import TableBody from "@material-ui/core/TableBody";
import {PatientActivityLogTableItem} from "../patient-activity-log-table-item/patient-activity-log-table-item";
import {isNullOrEmpty} from "orpyx-web-common";
import {PatientActivityLogTableEmptyItem} from "../patient-activity-log-table-empty-item/patient-activity-log-table-empty-item";

export const PatientActivityLogTable = memo((props: PatientActivityLogTableProps) => {
    const classes = usePatientActivityLogTableStyle();

    return <Table className={classes.table}>
        <PatientActivityLogTableHeader classes={classes}/>
        <TableBody>
            {isNullOrEmpty(props.careNotes) ? <PatientActivityLogTableEmptyItem/> : props.careNotes.map(note => <PatientActivityLogTableItem
                onClickedDownloadPatientActivityLog={props.onClickedDownloadPatientActivityLog}
                inProcess={!!props.inProcess[note.rowMarker!]}
                classes={classes}
                key={note.rowMarker!}
                careNote={note}
            />)}
        </TableBody>
    </Table>;
});
