import {combineReducers} from "redux";
import PatientsReducers from "../patients/store/patients.reducers";
import CarePractitionerActionReducers from "../care-practitioner-action/store/care-practitioner-action.reducers";
import DoctorsDashboardAuthReducers from "../auth/store/doctors-dashboard-auth-reducers";
import DoctorsDashboardOrganizationsReducers from "../organizations/store/doctors-dashboard-organizations-reducers";
import DoctorsDashboardRuntimeReducers from "../runtime/runtime-reducers";

export const DoctorDashboardReducers = combineReducers({
    patients: PatientsReducers,
    carePractitionerAction: CarePractitionerActionReducers,
    auth: DoctorsDashboardAuthReducers,
    organizations: DoctorsDashboardOrganizationsReducers,
    runtime: DoctorsDashboardRuntimeReducers,
});