import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const usePatientTemperatureComparisonSensorsLegendStyle = makeStyles((theme: Theme) => createStyles({
    listOfLabels: {
        listStyle: 'none',
        padding: '0',
        margin: '0',
        textAlign: 'left',
    },
    sensor: {
        width: '11px',
        height: '11px',
        borderRadius: '50%',
        display: 'inline-block',
        margin: '0 5px 0 0',
    },
}));