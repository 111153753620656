import {all} from "redux-saga/effects";
import PatientsListSagas from "../patients-list/store/patients-list.sagas";
import PatientInformationSagas from "../patient-information/store/patient-information.sagas";
import ScratchPadNoteSagas from "../patient-information/patient-information-page/store/scratch-pad-notes/scratch-pad-notes.sagas";

export default function* PatientsSagas() {
    yield all([
        PatientsListSagas(),
        PatientInformationSagas(),
        ScratchPadNoteSagas()
    ]);
}
