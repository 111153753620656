import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IGetPatientInformationResponse} from "doctors-dashboard/http-clients/index";

export enum PatientInformationPageActions {
    GET_PATIENT_INFORMATION = 'GET_PATIENT_INFORMATION: GET_PATIENT_INFORMATION',
    GET_PATIENT_INFORMATION_SUCCESSFULLY = 'GET_PATIENT_INFORMATION: GET_PATIENT_INFORMATION_SUCCESSFULLY',
    GET_PATIENT_INFORMATION_FAILED = 'GET_PATIENT_INFORMATION: GET_PATIENT_INFORMATION_FAILED',
    GET_PATIENT_INFORMATION_REFRESH = 'GET_PATIENT_INFORMATION: GET_PATIENT_INFORMATION_REFRESH',
    GET_PATIENT_INFORMATION_REFRESH_SUCCESSFULLY = 'GET_PATIENT_INFORMATION: GET_PATIENT_INFORMATION_REFRESH_SUCCESSFULLY'
}

export const GET_PATIENT_INFORMATION = (patientId: number): StoreAction<PatientInformationPageActions> => ({
    type: PatientInformationPageActions.GET_PATIENT_INFORMATION, payload: patientId
});

export const GET_PATIENT_INFORMATION_SUCCESSFULLY = (patient: IGetPatientInformationResponse): StoreAction<PatientInformationPageActions> => ({
    type: PatientInformationPageActions.GET_PATIENT_INFORMATION_SUCCESSFULLY, payload: patient
});

export const GET_PATIENT_INFORMATION_FAILED = (error: OperationErrorType): StoreAction<PatientInformationPageActions> => ({
    type: PatientInformationPageActions.GET_PATIENT_INFORMATION_FAILED, payload: error
});

export const GET_PATIENT_INFORMATION_REFRESH = (filterDate: Date, patientId: number): StoreAction<PatientInformationPageActions> => ({
    type: PatientInformationPageActions.GET_PATIENT_INFORMATION_REFRESH, payload: {filterDate: filterDate, patientId: patientId}
});

export const GET_PATIENT_INFORMATION_REFRESH_SUCCESSFULLY = (): StoreAction<PatientInformationPageActions> => ({
    type: PatientInformationPageActions.GET_PATIENT_INFORMATION_REFRESH_SUCCESSFULLY
});
