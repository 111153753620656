import {JsonParseResult} from "orpyx-web-common";
import {DoctorsDashboardAzureActor} from "../../models/doctors-dashboard-azure-token";

export class DoctorsDashboardAzureTokenStorage {
    private static readonly keyName = ' DoctorsDashboardAzureToken';

    public static readonly resetActor = (): void => {
        sessionStorage.removeItem(DoctorsDashboardAzureTokenStorage.keyName);
    };

    public static readonly saveActor = (actor: DoctorsDashboardAzureActor): void => {
        sessionStorage.removeItem(DoctorsDashboardAzureTokenStorage.keyName);
        sessionStorage.setItem(DoctorsDashboardAzureTokenStorage.keyName, JSON.stringify(actor));
    };

    public static readonly getActor = (): DoctorsDashboardAzureActor | undefined => {
        const json = sessionStorage.getItem(DoctorsDashboardAzureTokenStorage.keyName);
        if (!json) {
            return undefined;
        }

        const obj: JsonParseResult<DoctorsDashboardAzureActor> = JSON.parse(json);
        if (!obj || !obj.accessToken || !obj.accountIdentifier) {
            return undefined;
        }

        const actor: DoctorsDashboardAzureActor = {
            type: obj.type,
            name: obj.name,
            accountIdentifier: obj.accountIdentifier,
            accessToken: {
                token: obj.accessToken.token,
                expiredAt: new Date(obj.accessToken.expiredAt),
            },
        };

        return actor;
    };
}

export default DoctorsDashboardAzureTokenStorage;
