import {createSelector} from "reselect";
import {getPatientsState} from "../../../store/patients.selectors";

export class PatientTemperatureSelectors {
    static readonly state = createSelector(getPatientsState, state => state.information.temperature);

    static readonly loading = createSelector(PatientTemperatureSelectors.state, state => state.loading);
    static readonly initialized = createSelector(PatientTemperatureSelectors.state, state => state.initialized);
    static readonly error = createSelector(PatientTemperatureSelectors.state, state => state.error);

    static readonly summary = createSelector(PatientTemperatureSelectors.state, state => state.summary);
    static readonly options = createSelector(PatientTemperatureSelectors.state, state => state.options);
}