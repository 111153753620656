import * as React from 'react';
import {PatientFilterType} from "doctors-dashboard/http-clients/index";
import {usePatientsListTypeSelectorStyle} from "./patients-list-type-selector.style";
import Button from '@material-ui/core/Button';
import {Typography} from "@material-ui/core";
import {PatientsListTypeSelectorProps, PatientsTypeButtonSelectorProps} from "./patients-list-type-selector.interface";
import {PatientFilterTypeDisplayPipe} from "../../../../pipes/patient-filter-type-pipe";

const PatientsTypeButtonSelector = (props: PatientsTypeButtonSelectorProps) => {
    return (
        <Button
            variant='contained'
            id={`patients-list-type-selector-${props.patientType}`}
            disabled={props.disabled}
            className={(props.patientType === props.currentPatientType) ? props.classes.primary : props.classes.button}
            onClick={_ => props.onChangePatientType(props.patientType)}
        >
            <Typography>{PatientFilterTypeDisplayPipe(props.patientType)}</Typography>
        </Button>
    );
};

export const PatientsListTypeSelector = (props: PatientsListTypeSelectorProps) => {
    const classes = usePatientsListTypeSelectorStyle();

    return (
        <span className={classes.root} id='patients-list-type-selector-span'>

            <PatientsTypeButtonSelector
                classes={classes}
                disabled={props.loading}
                currentPatientType={props.patientType}
                onChangePatientType={props.onChangePatientType}
                patientType={PatientFilterType.Active}
            />

            <PatientsTypeButtonSelector
                classes={classes}
                disabled={props.loading}
                currentPatientType={props.patientType}
                onChangePatientType={props.onChangePatientType}
                patientType={PatientFilterType.InactivePatients}
            />

            <PatientsTypeButtonSelector
                classes={classes}
                disabled={props.loading}
                currentPatientType={props.patientType}
                onChangePatientType={props.onChangePatientType}
                patientType={PatientFilterType.NewPatients}
            />

            { props.hasCustomerCarePermissions && 
                <PatientsTypeButtonSelector
                    classes={classes}
                    disabled={props.loading}
                    currentPatientType={props.patientType}
                    onChangePatientType={props.onChangePatientType}
                    patientType={PatientFilterType.GlobalSearch}
                />
            }
        </span>
    );
};
