import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

export const PatientsListActionRequiredAlertsStyle = createStyles({
    icon: {
        width: '20px',
        height: '20px',
        margin: '0 10px 0 0',
    }
});

export const usePatientsListActionRequiredAlertsStyle = makeStyles((theme: Theme) => PatientsListActionRequiredAlertsStyle);