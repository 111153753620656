import { ORPYXSI_API_URL } from "appConstants";
import { DoctorsDashboardOrganizationHttpClient, OrganizationOutputDtoPagingResult } from "doctors-dashboard";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { DoctorsDashboardOrganizationsActions, DOCTORS_DASHBOARD_FETCH_ORGANIZATIONS_FAILED, DOCTORS_DASHBOARD_FETCH_ORGANIZATIONS_SUCCESSFULLY } from "./doctors-dashboard-organizations-actions";

const API_VERSION = '1';

function* onFetchOrganizations() {
    try {
        const api = new DoctorsDashboardOrganizationHttpClient(ORPYXSI_API_URL);
        const data: OrganizationOutputDtoPagingResult = yield call(
            [api, api.getOrganizationsList],
            API_VERSION
        );

        yield put(DOCTORS_DASHBOARD_FETCH_ORGANIZATIONS_SUCCESSFULLY(data));
    } catch (e) {
        yield put(DOCTORS_DASHBOARD_FETCH_ORGANIZATIONS_FAILED(e));
    }
}

export function* DoctorsDashboardOrganizationsSagas() {
    yield all([
        takeEvery(DoctorsDashboardOrganizationsActions.FETCH_ORGANIZATIONS, onFetchOrganizations),
    ]);
}