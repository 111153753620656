import {connectRouter, RouterState} from 'connected-react-router';
import {combineReducers} from 'redux';
import {DoctorDashboardState} from "../doctor-dashboard/store/doctor-dashboard.state";
import {DoctorDashboardReducers} from "../doctor-dashboard/store/doctor-dashboard.reducers";
import {applicationReducers, IApplicationState} from "orpyx-web-common";
import {RemotePatientMonitoringSignInReducers, RemotePatientMonitoringSignInState} from "../app/remote-patient-monitoring-signin/store/remote-patient-monitoring-signin.reducers";

export interface IOrpyxApplicationStore {
  readonly router: RouterState,
  readonly doctorsDashboard: DoctorDashboardState;
  readonly remotePatientMonitoring: RemotePatientMonitoringSignInState;
  readonly app: IApplicationState;
}

const rootReducer = (history:any) => combineReducers({
  router: connectRouter(history),
  doctorsDashboard: DoctorDashboardReducers,
  remotePatientMonitoring: RemotePatientMonitoringSignInReducers,
  app: applicationReducers,
});


export default rootReducer;