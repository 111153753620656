import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

export const PatientMonthlyReportsStyle = createStyles({
    icon: {
        verticalAlign: 'sub',
        paddingRight: '10px',
    },
    loadMoreReportsButton: {
        display: "block",
        margin: '25px auto 10px',
        '& span': {
            color: 'white'
        },
    },
    tableContainer: {
        maxHeight: '500px',
    },
});

export const usePatientMonthlyReportsStyle = makeStyles((theme: Theme) => PatientMonthlyReportsStyle);