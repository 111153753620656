import {all, call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {ORPYXSI_API_URL} from "../../../../../appConstants";
import {DoctorsDashboardPatientsHttpClient, IGetPatientsListResponse, IGetPatientsListResponsePagingResult, PatientFilterType} from "doctors-dashboard/http-clients/index";
import {FETCH_PATIENTS_LIST_FAILED, FETCH_PATIENTS_LIST_SUCCESSFULLY, PatientsListActions} from "./patients-list.actions";
import {PatientsListSelectors} from "./patients-list.selectors";
import {PatientsSearchQuery} from "../models/patients-search-query";
import {convertOffsetToPage, isNullOrUndefined, StoreAction} from "orpyx-web-common";
import {push} from "connected-react-router";
import {DoctorsDashboardAuthSelectors} from "../../../auth/store/doctors-dashboard-auth-selectors";
import { DOCTORS_DASHBOARD_FETCH_ORGANIZATIONS } from 'modules/doctor-dashboard/organizations/store/doctors-dashboard-organizations-actions';
import { DoctorsDashboardRuntimeSelectors } from 'modules/doctor-dashboard/runtime/runtime-selectors';
import { DoctorsDashboardRuntimeActions } from 'modules/doctor-dashboard/runtime/runtime-actions';

const API_VERSION = '1';

function* onPatientSelected(action: StoreAction<PatientsListActions, IGetPatientsListResponse>) {
    const patient = action.payload!;
    const patientId = patient.patientId!;

    const url = `/patients/details/${patientId}`;
    yield put(push(url));
}

function* onFetchPatients() {
    try {
        const search: PatientsSearchQuery = yield select(PatientsListSelectors.search);
        const profile = yield select(DoctorsDashboardAuthSelectors.profile);
        const currentOrganization = yield select(DoctorsDashboardRuntimeSelectors.currentOrganization);
        
        // Default state of global search (i.e. no search term entered) is a blank page
        let data: IGetPatientsListResponsePagingResult = {
            items: [],
            total: 0 
        };
        
        if (search.patientsType !== PatientFilterType.GlobalSearch || search.term?.trim()) {
            const api = new DoctorsDashboardPatientsHttpClient(ORPYXSI_API_URL);

            data = yield call(
                [api, api.getPatientsList],
                search.term!,
                search.patientsType!,
                search.filterBy!,

                search.orderByAsc!,
                search.orderBy!,

                currentOrganization?.id,

                convertOffsetToPage(search.offset!),
                search.count!,

                API_VERSION
            );
        }

        yield put(FETCH_PATIENTS_LIST_SUCCESSFULLY(data));

        if (profile.hasCustomerCarePermissions)
            yield put(DOCTORS_DASHBOARD_FETCH_ORGANIZATIONS());
    } catch (e) {
        yield put(FETCH_PATIENTS_LIST_FAILED(e));
    }
}

function* onRouteChanged(action: StoreAction<any, { location: Location }>) {
    const isLoggedIn = yield select(DoctorsDashboardAuthSelectors.isLoggedIn);
    if (isLoggedIn) {
        const path = action.payload?.location?.pathname;
        if (isNullOrUndefined(path) || path === '/')
            yield put(push('/patients'))
    }
}

export default function* PatientsListSagas() {
    yield all([
        yield takeEvery('@@router/LOCATION_CHANGE', onRouteChanged),
        yield takeEvery(PatientsListActions.FETCH_PATIENTS_LIST, onFetchPatients),
        yield takeEvery(PatientsListActions.SELECT_PATIENT, onPatientSelected),
        yield takeLatest(DoctorsDashboardRuntimeActions.UPDATE_CURRENT_ORGANIZATION_SUCCESSFULLY, onFetchPatients),
    ]);
}
