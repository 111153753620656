import {TableCell, TableRow} from "@material-ui/core";
import {DateTimePipe, fullNamePipe} from "orpyx-web-common";
import * as React from "react";
import {memo} from "react";
import {PatientsListGlobalSearchTableItemProps} from "./patients-list-global-search-table-item.interface";

export const PatientsListGlobalSearchTableItem = memo((props: PatientsListGlobalSearchTableItemProps) => {
    const patient = props.patient;
    const { organization } = patient!;

    return (
        <TableRow
            key={patient.patientId}
            onClick={() => props.onPatientSelected(patient)}
            hover
        >
            <TableCell>{fullNamePipe(patient)}</TableCell>
            <TableCell>{organization?.name}</TableCell>
            <TableCell>{organization?.address?.city}/{organization?.address?.state}</TableCell>
            <TableCell>{DateTimePipe(patient.allowAccessStartDate)}</TableCell>
        </TableRow>
    )
})