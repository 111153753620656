import * as React from "react";
import {memo, useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {
    RemotePatientMonitoringSigninPageEvents,
    RemotePatientMonitoringSigninPageMeta,
    RemotePatientMonitoringSigninPageOwnProps,
    RemotePatientMonitoringSigninPageProps
} from "./remote-patient-monitoring-signin-page.interface";
import {IOrpyxApplicationStore} from "../../../../store/application.reducers";
import {SET_REMOTE_USER_TOKEN, SET_REMOTE_USER_TOKEN_FAILED} from "../../store/remote-patient-monitoring-signin.actions";
import {RemotePatientMonitoringSigninSelectors} from "../../store/remote-patient-monitoring-signin.selectors";
import {OperationErrorMessage} from "orpyx-web-common";
import {onRemotePatientMonitoringSignInPageLoaded} from "./remote-patient-monitoring-signin-page.hooks";
import { withRouter } from "react-router-dom";
import {CenteredMessage} from "../../../../shared/components/centered-message/centered-message";

const RemotePatientMonitoringSignInPageTemplate = (props: RemotePatientMonitoringSigninPageMeta) => {
    useEffect(onRemotePatientMonitoringSignInPageLoaded(props), []);

    return (
        <CenteredMessage title={' Welcome to Orpyx.'}>
            <div>
                Please wait until the authorization takes place,
                after authorization you will automatically be taken to the patient's page ..
            </div>

            <OperationErrorMessage error={props.error}/>
        </CenteredMessage>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): RemotePatientMonitoringSigninPageOwnProps => {
    return {
        error: RemotePatientMonitoringSigninSelectors.error(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: RemotePatientMonitoringSigninPageProps): RemotePatientMonitoringSigninPageEvents => {
    return bindActionCreators({
        setRemoteDoctorToken: SET_REMOTE_USER_TOKEN,
        showErrorMessage: SET_REMOTE_USER_TOKEN_FAILED,
    }, dispatch);
};

export const RemotePatientMonitoringSignInPage = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(RemotePatientMonitoringSignInPageTemplate)));
