import { OrganizationOutputDto } from "doctors-dashboard";
import produce from "immer";
import { StoreAction } from "orpyx-web-common";
import DoctorsDashboardActorStorage from "../auth/store/actor/doctors-dashboard-actor-storage";
import DoctorsDashboardAzureTokenStorage from "../auth/store/actor/doctors-dashboard-azure-token-storage";
import { DoctorsDashboardRuntimeActions } from "./runtime-actions";

export interface DoctorsDashboardRuntimeState {
    inProcess: boolean,
    currentOrganization: OrganizationOutputDto | undefined,
}

const createInitialState = (): DoctorsDashboardRuntimeState => {
    return {
        inProcess: false,
        currentOrganization: undefined,
    };
};

const DoctorsDashboardRuntimeReducers = (state = createInitialState(), action: StoreAction<DoctorsDashboardRuntimeActions>): DoctorsDashboardRuntimeState => {
    return produce(state, (draft: DoctorsDashboardRuntimeState) => {
        switch(action.type) {
            case DoctorsDashboardRuntimeActions.UPDATE_CURRENT_ORGANIZATION:
                draft.inProcess = true;
                break;
            case DoctorsDashboardRuntimeActions.UPDATE_CURRENT_ORGANIZATION_SUCCESSFULLY:
                draft.inProcess = false;
                draft.currentOrganization = action.payload as OrganizationOutputDto;
                break;
            case DoctorsDashboardRuntimeActions.UPDATE_CURRENT_ORGANIZATION_FAILED:
                draft.inProcess = false;
                break;
        }
    });
};

export default DoctorsDashboardRuntimeReducers;