import {Tooltip} from "@material-ui/core";
import {DatePipe} from "orpyx-web-common";
import * as React from "react";
import {memo} from "react";
import {PatientActionRequiredAlertPreviewProps} from "./patients-list-action-required-alert.interface";
import {ImageUrlPipe} from "../../../../../shared/pipes/image-url-pipe";

export const PatientsListActionRequiredAlert = memo((props: PatientActionRequiredAlertPreviewProps) => {
    return <>
        <Tooltip title={`${DatePipe(props.alert.creationDate)}: ${props.alert.alertMessage}`}>
            <img
                className={props.classes.icon}
                alt={props.alert.alertMessage!}
                src={ImageUrlPipe(props.alert.imageUrl)}
            />
        </Tooltip>
    </>;
});
