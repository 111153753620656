import {JsonParseResult} from "orpyx-web-common";
import {IGetDashboardUserProfileResponse} from "doctors-dashboard/http-clients/index";

export class DoctorsDashboardActorStorage {
    private static readonly keyName = ' DoctorsDashboardActor';

    public static readonly resetActorProfile = (): void => {
        sessionStorage.removeItem(DoctorsDashboardActorStorage.keyName);
    };

    public static readonly saveActorProfile = (profile: IGetDashboardUserProfileResponse): void => {
        sessionStorage.removeItem(DoctorsDashboardActorStorage.keyName);
        sessionStorage.setItem(DoctorsDashboardActorStorage.keyName, JSON.stringify(profile));
    };

    public static readonly getActorProfile= (): IGetDashboardUserProfileResponse | undefined => {
        const json = sessionStorage.getItem(DoctorsDashboardActorStorage.keyName);
        if (!json) {
            return undefined;
        }

        const obj: JsonParseResult<IGetDashboardUserProfileResponse> = JSON.parse(json);
        if (!obj || !obj.dashboardUserId || !obj.firstName || !obj.lastName) {
            return undefined;
        }

        return obj;
    };
}

export default DoctorsDashboardActorStorage;
