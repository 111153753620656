import * as React from "react";
import {memo} from "react";
import {connect} from "react-redux";

import {PatientInformationTemperatureMeta, PatientInformationTemperatureOwnProps,} from "./patient-information-temperature.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {PatientTemperatureSelectors} from "../../../patient-temperature/store/patient-temperature.selectors";
import {OperationProgress, OperationSkeleton} from "orpyx-web-common";
import {PatientInformationTitle} from "../../../patient-information-title/patient-information-title";
import {PatientTemperatureComparison} from "../../../patient-temperature/components/patient-temperature-comparison/patient-temperature-comparison";
import {PatientTemperatureChart} from "../../../patient-temperature/components/patient-temperature-chart/patient-temperature-chart";
import {Grid} from "@material-ui/core";
import {usePatientInformationPageStyles} from "../patient-information-page/patient-information-page.style";

const PatientInformationTemperatureContent = memo(() => {
    const classes = usePatientInformationPageStyles();

    return (<Grid container spacing={3}>
        <Grid item xs={12}>
            <div className={classes.content} id="patient-information-temperature-comparison">
                <PatientTemperatureComparison/>
            </div>
        </Grid>

        <Grid item xs={12}>
            <div className={classes.content} id="patient-information-temperature-chart">
                <PatientTemperatureChart/>
            </div>
        </Grid>

    </Grid>);
});

const PatientInformationTemperatureLoading = memo((props: PatientInformationTemperatureMeta) => {
    const classes = usePatientInformationPageStyles();

    return (<Grid container spacing={3}>
        <Grid item xs={12}>
            <div className={classes.content}>
                <OperationProgress error={props.error} inProcess={props.loading}/>
                {!props.initialized && <OperationSkeleton height={40} rows={4}/>}
            </div>
        </Grid>

        <Grid item xs={12}>
            <div className={classes.content}>
                <OperationSkeleton height={40} rows={8}/>
            </div>
        </Grid>
    </Grid>)
});

const PatientInformationTemperatureTemplate = (props: PatientInformationTemperatureMeta) => {
    return (
        <>
            <PatientInformationTitle text="Temperature"/>
            {!props.initialized && <PatientInformationTemperatureLoading initialized={props.initialized} loading={props.loading} error={props.error}/>}
            {props.initialized && <PatientInformationTemperatureContent/>}
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientInformationTemperatureOwnProps => {
    return {
        initialized: PatientTemperatureSelectors.initialized(state),
        loading: PatientTemperatureSelectors.loading(state),
        error: PatientTemperatureSelectors.error(state),
    };
};

export const PatientInformationTemperature = memo(connect(mapStateToProps)(PatientInformationTemperatureTemplate));

