// tslint:disable:no-console
// In production, we check is service worker has been registered, and then unregister it.

export function unregister() {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
            registration.unregister()
        }
    }).catch(function(err) {  
        console.log('Service Worker deleting failed: ', err);
    });
}