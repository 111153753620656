import { OrganizationOutputDto } from "doctors-dashboard";
import { StoreAction } from "orpyx-web-common";
import { all, put, takeEvery } from "redux-saga/effects";
import { DoctorsDashboardAuthActions } from "../auth/store/doctors-dashboard-auth-actions";
import { 
    DoctorsDashboardRuntimeActions,
    DOCTORS_DASHBOARD_UPDATE_CURRENT_ORGANIZATION_FAILED,
    DOCTORS_DASHBOARD_UPDATE_CURRENT_ORGANIZATION_SUCCESSFULLY 
} from "./runtime-actions";

const API_VERSION = '1';

function* onUpdateCurrentOrganization(action: StoreAction<DoctorsDashboardAuthActions, OrganizationOutputDto>) {
    try {
        const organization = action.payload!;

        yield put(DOCTORS_DASHBOARD_UPDATE_CURRENT_ORGANIZATION_SUCCESSFULLY(organization));
    } catch (e) {
        yield put(DOCTORS_DASHBOARD_UPDATE_CURRENT_ORGANIZATION_FAILED(e));
    }
}

export function* DoctorsDashboardRuntimeSagas() {
    yield all([
        takeEvery(DoctorsDashboardRuntimeActions.UPDATE_CURRENT_ORGANIZATION, onUpdateCurrentOrganization),
    ]);
}