import {OrderByQuery, PagingQuery, parseEnum} from "orpyx-web-common";
import {GetPatientsListPropertyOrderBy, PatientFilterType, PatientFilterBy} from "doctors-dashboard/http-clients/index";
import {PatientFilterTypeUrlPipe} from "../../../pipes/patient-filter-type-pipe";

export class PatientsSearchQuery extends OrderByQuery<GetPatientsListPropertyOrderBy> {
    static readonly PARAM_FILTER_BY = "filterBy";

    /// <summary>
    /// <para>Search users by</para>
    /// <para>- LastName or patient id</para>
    /// </summary>
    term?: string | undefined;

    patientsType?: PatientFilterType | undefined;

    filterBy?: PatientFilterBy | undefined;

    public static parse(term: string | undefined, filterBy: string | undefined,patientsType: string | undefined, orderBy: string | undefined, orderByAsc: string | undefined, offset: string | undefined, count: string | undefined): PatientsSearchQuery {
        const orderByValue = parseEnum(GetPatientsListPropertyOrderBy, orderBy, GetPatientsListPropertyOrderBy.FirstName);
        const patientsTypeEnum = parseEnum(PatientFilterType, patientsType, PatientFilterType.Active);
        const filterByEnum = parseEnum(PatientFilterBy, filterBy, PatientFilterBy.Name);

        return new PatientsSearchQuery
        (
            term,
            filterByEnum,
            patientsTypeEnum,

            orderByValue,
            !!orderByAsc,

            offset ? parseInt(offset) : PagingQuery.DEFAULT_PAGE,
            count ? parseInt(count) : PagingQuery.DEFAULT_COUNT
        );
    }

    constructor(term?: string | undefined, filterBy?: PatientFilterBy, patientsType?: PatientFilterType, orderBy?: GetPatientsListPropertyOrderBy, orderByAsc?: boolean, offset: number | undefined = PagingQuery.DEFAULT_PAGE, count: number | undefined = PagingQuery.DEFAULT_COUNT) {
        super(orderBy, orderByAsc, offset, count);
        this.patientsType = patientsType;
        this.filterBy = filterBy;
        this.term = term;
    }
}

export const toQueryString = (query: PatientsSearchQuery): string => {
    const patientsType = PatientFilterTypeUrlPipe(query.patientsType!);
    let str = `/patients/${patientsType}`;

    str += `?offset=${query.offset}&count=${query.count}`;

    if (query.term) {
        str += `&term=${query.term}`;
    }

    if (query.filterBy) {
        str += `&${PatientsSearchQuery.PARAM_FILTER_BY}=${query.filterBy}`;
    }

    if (query.orderBy) {
        str += `&${PatientsSearchQuery.PARAM_ORDER_BY}=${query.orderBy}`;
    }

    if (query.orderByAsc) {
        str += `&${PatientsSearchQuery.PARAM_ORDER_BY_ASC}=${query.orderByAsc}`;
    }

    return str;
};
