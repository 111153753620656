import * as React from "react";
import {memo} from "react";
import {PatientsListActionRequiredAlert} from "../patients-list-action-required-alert/patients-list-action-required-alert";
import {PatientsListActionRequiredAlertsProps} from "./patients-list-action-required-alerts.interface";
import {usePatientsListActionRequiredAlertsStyle} from "./patients-list-action-required-alerts.style";

export const PatientsListActionRequiredAlerts = memo((props: PatientsListActionRequiredAlertsProps) => {
    const classes = usePatientsListActionRequiredAlertsStyle();

    return (<>
        {props.alerts && props.alerts!.map(alert => <PatientsListActionRequiredAlert
            key={alert.id}
            alert={alert}
            classes={classes}
        />)}
    </>);

});