import { getPatientsState } from "modules/doctor-dashboard/patients/store/patients.selectors";
import {createSelector} from "reselect";

export class DocumentListSelectors {
    static readonly state = createSelector(getPatientsState, state => state.information.documents);
    static readonly patientState = createSelector(getPatientsState, state => state.information.information);

    static readonly loading = createSelector(DocumentListSelectors.state, state => state.loading);
    static readonly error = createSelector(DocumentListSelectors.state, state => state.error);

    static readonly documents = createSelector(DocumentListSelectors.state, state => state.documents);
    static readonly files = createSelector(DocumentListSelectors.state, state => state.files);

    static readonly addNewDocuments = createSelector(DocumentListSelectors.state, state => state.addNewDocuments);
    static readonly showConfirmation = createSelector(DocumentListSelectors.state, state => state.showConfirmation);
    static readonly viewDocument = createSelector(DocumentListSelectors.state, state => state.viewDocument);

    static readonly description = createSelector(DocumentListSelectors.state, state => state.description);
    static readonly fileContent = createSelector(DocumentListSelectors.state, state => state.fileContent);
    static readonly viewingFileName = createSelector(DocumentListSelectors.state, state => state.viewingFileName);
}