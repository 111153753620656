import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core";
import {Teal} from "orpyx-web-common";

export const useCenteredMessageStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        marginBottom: "50px",
    },
    title: {
        marginTop: '0',
        fontSize: '20px',
        color: Teal,
    },
    content: {
        border: '1.08px solid #EFEFEF',
        borderRadius: '3.23px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 7px 14px 0 rgba(0,0,0,0.01), 0 11px 36px 0 rgba(48,48,48,0.16)',
        padding: '27px 20px',
    },
}));