import * as React from "react";
import {Box, Container} from "@material-ui/core";
import {useDoctorsDashboardPageStyles} from "./doctors-dashboard-page.style";
import {DoctorsDashboardPageProps} from "./doctors-dashboard-page.interface";
import {doctorsDashboardUrls} from "../../enums/doctors-dashboard-urls";
import {DoctorsDashboardUrlBlock} from "../doctors-dashboard-url-block/doctors-dashboard-url-block";

export const DoctorsDashboardPage = (props: DoctorsDashboardPageProps) => {
    const classes = useDoctorsDashboardPageStyles();

    return (
        <Container>
            <div className={classes.root}>
                <Box display="flex" flexDirection="row" mb={3}>
                    {doctorsDashboardUrls.map(url => <DoctorsDashboardUrlBlock key={url.url} url={url}/>)}
                </Box>
            </div>
        </Container>
    );
};