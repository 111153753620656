import { Document } from "documents-client";
import { DocumentListActions } from "./document-list.actions";
import { StoreAction } from "orpyx-web-common";
import produce from "immer";
import { DocumentListState } from "./document-list.state";
import { v4 as uuid } from 'uuid';

const initialState: DocumentListState = {
    loading: false,
    error: undefined,
    addNewDocuments: false,
    showConfirmation: false,
    documents: new Array<Document>(),
    files: new Array<File>(),
    description: '',
    fileContent: undefined,
    viewDocument: false,
    viewingFileName: ''
};

const createANewDocument = (category: string, id: number, description: string | null, filename: string) : Document => {
    return {
        documentId: uuid(),
        associatedId: id,
        description: description ?? '',
        createdOn: new Date().toDateString(),
        dashboardUserEmail: '',
        dashboardUserFirstName: '',
        dashboardUserId: -1,
        dashboardUserLastName: '',
        deleted: false,
        fileName: filename,
        status: 'Initiated',
        updatedOn: new Date().toDateString()
    };
}

const findAndUpdateStatus = (documentId: string, documents: Array<Document>, status: string) : void => {
    const doc : Document | undefined = getDocumentById(documentId, documents);
    if(doc) doc.status = status;
    return;
}

const findAndUpdateDescription = (documentId: string, documents: Array<Document>, description: string) : void => {
    const doc : Document | undefined = getDocumentById(documentId, documents);
    if(doc) doc.description = description;
    return;
}

const getDocumentById = (documentId: string, documents: Array<Document>) : Document | undefined => {
    return documents.find(doc => doc.documentId === documentId);
}

const DocumentListReducers = (state = initialState, action: StoreAction<DocumentListActions>): DocumentListState => {
    return produce(state, (draft: DocumentListState) => {
        switch(action.type) {
            case DocumentListActions.LIST_DOCUMENTS:
                draft.loading = true;
                draft.documents = [];
                break;
            case DocumentListActions.LIST_DOCUMENTS_SUCCESSFUL:
                draft.loading = false;
                draft.error = undefined;
                draft.documents = action.payload;
                break;
            case DocumentListActions.LIST_DOCUMENTS_FAILED:
                draft.loading = false;
                draft.error = action.payload;
                break;
            case DocumentListActions.ADD_NEW_DOCUMENTS:
                draft.loading = false;
                draft.addNewDocuments = true;
                break;
            case DocumentListActions.RESET_DOCUMENTS:
                draft.loading = false;
                draft.addNewDocuments = false;
                draft.documents = []
                break;
            case DocumentListActions.UPLOAD_DOCUMENT:
                draft.error = undefined;
                draft.addNewDocuments = false;
            break;
            case DocumentListActions.UPLOAD_DOCUMENT_SUCCESSFUL:
                draft.error = undefined;
                draft.documents.unshift(action.payload);
                break;
            case DocumentListActions.UPLOAD_DOCUMENT_FAILED:
                draft.error = action.payload;
                break;
            case DocumentListActions.CANCEL_DOCUMENT_UPLOAD:
                draft.addNewDocuments = false;
                draft.showConfirmation = false;
                //case where a person has requested for document upload,
                //but quite immediately opens another dialog and cancels it.
                if(!draft.loading) {
                    draft.files = [];
                    draft.description = '';
                }
                break;
            case DocumentListActions.PREPARE_FOR_UPLOAD:
                draft.addNewDocuments = false;
                draft.showConfirmation = true;
                draft.files = action.payload.files;
                draft.description = action.payload.description;
                break;
            case DocumentListActions.UPLOAD_DOCUMENTS:
                draft.error = undefined;
                draft.loading = true;
                draft.showConfirmation = false;
                draft.addNewDocuments = false;
                draft.documents = [];
            break;
            case DocumentListActions.UPLOAD_DOCUMENTS_SUCCESSFUL:
                draft.error = undefined;
                draft.loading = false;
                draft.documents = action.payload;
                draft.files = [];
                break;
            case DocumentListActions.UPLOAD_DOCUMENTS_FAILED:
                draft.error = action.payload;
                draft.files = [];
                draft.description = '';
                break;
            case DocumentListActions.DOWNLOAD_DOCUMENT:
                draft.error = undefined;
                break;
            case DocumentListActions.DOWNLOAD_DOCUMENT_SUCCESSFUL:
                draft.error = undefined;
                draft.viewDocument = true;
                draft.fileContent = action.payload.fileContent;
                draft.viewingFileName = action.payload.documentName;
                break;
            case DocumentListActions.DOWNLOAD_DOCUMENT_FAILED:
                draft.error = action.payload;
                break;
            case DocumentListActions.GET_DOCUMENT_STATUS:
                draft.error = undefined;
                break;
            case DocumentListActions.GET_DOCUMENT_STATUS_SUCCESSFUL:
                draft.error = undefined;
                findAndUpdateStatus(action.payload.id, draft.documents, action.payload.status);
                break;
            case DocumentListActions.GET_DOCUMENT_STATUS_FAILED:
                draft.error = action.payload;
                findAndUpdateStatus(action.payload.id, draft.documents, 'Failed');
                break;
            case DocumentListActions.UPDATE_DOCUMENT_ATTRIBUTES:
                draft.error = undefined;
                break;
            case DocumentListActions.UPDATE_DOCUMENT_ATTRIBUTES_SUCCESSFUL:
                draft.error = undefined;
                draft.description = '';
                findAndUpdateDescription(action.payload.id, draft.documents, action.payload.description);
                break;
            case DocumentListActions.UPDATE_DOCUMENT_ATTRIBUTES_FAILED:
                draft.description = '';
                draft.error = action.payload;
                break;
            case DocumentListActions.CLOSE_DOCUMENT_VIEWER:
                draft.viewDocument = false;
                draft.fileContent = undefined;
                draft.viewingFileName = '';
                break;
        }
    });
};

export default DocumentListReducers;