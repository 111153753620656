import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {
    PatientInformationStepCountEvents,
    PatientInformationStepCountMeta,
    PatientInformationStepCountOwnProps,
    PatientInformationStepCountProps
} from "./patient-information-step-count.interface";
import {PatientStepCountSelectors} from "../../../patient-step-count/store/patient-step-count.selectors";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {usePatientInformationPageStyles} from "../patient-information-page/patient-information-page.style";
import {Grid} from "@material-ui/core";
import {DateRangePipe, getFirstMonthDay, getLastMonthDay, OperationSkeleton} from "orpyx-web-common";
import {PatientInformationTitle} from "../../../patient-information-title/patient-information-title";
import {PatientStepCountChart} from "../../../patient-step-count/components/patient-step-count-chart/patient-step-count-chart";
import {PatientStepCountAverageChart} from "../../../patient-step-count/components/patient-step-count-average-chart/patient-step-count-average-chart";


const PatientInformationStepCountContent = memo((props: PatientInformationStepCountMeta) => {
    const classes = usePatientInformationPageStyles();
    const {lastMonth, thisMonth} = props.statistic;

    return (<>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <div className={classes.content} id="patient-information-step-count-last-month">
                    <div className={classes.centered}>
                        Last Month <br/>
                        {DateRangePipe({
                            begin: getFirstMonthDay(lastMonth?.month),
                            end: getLastMonthDay(lastMonth?.month),
                        })}
                    </div>
                    <PatientStepCountAverageChart summary={lastMonth}/>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.content} id="patient-information-step-count-this-month">
                    <div className={classes.centered}>
                        This Month <br/>
                        {DateRangePipe({
                            begin: getFirstMonthDay(thisMonth?.month),
                            end: getLastMonthDay(thisMonth?.month),
                        })}
                    </div>
                    <PatientStepCountAverageChart summary={thisMonth}/>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.content} id="patient-information-step-count-this-month-chart">
                    <div className={classes.centered}>
                        This Month <br/>
                        {DateRangePipe({
                            begin: getFirstMonthDay(thisMonth?.month),
                            end: getLastMonthDay(thisMonth?.month),
                        })}
                    </div>
                    <PatientStepCountChart
                        summary={thisMonth}
                        className={classes.chart}
                    />
                </div>
            </Grid>
        </Grid>
    </>);
});

const PatientInformationStepCountLoading = memo((props: PatientInformationStepCountMeta) => {
    const classes = usePatientInformationPageStyles();

    return (<>
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <div className={classes.content}>
                    <OperationSkeleton height={40} rows={8}/>
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className={classes.content}>
                    <OperationSkeleton height={40} rows={8}/>
                </div>
            </Grid>
        </Grid>
    </>)
});

const PatientInformationStepCountTemplate = (props: PatientInformationStepCountMeta) => {
    return (
        <>
            <PatientInformationTitle text="Step count"/>
            {!props.initialized && <PatientInformationStepCountLoading {...props}/>}
            {props.initialized && <PatientInformationStepCountContent {...props}/>}
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientInformationStepCountOwnProps => {
    return {
        initialized: PatientStepCountSelectors.initialized(state),
        loading: PatientStepCountSelectors.loading(state),
        error: PatientStepCountSelectors.error(state),
        statistic: {
            thisMonth: PatientStepCountSelectors.thisMonth(state),
            lastMonth: PatientStepCountSelectors.lastMonth(state),
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientInformationStepCountProps): PatientInformationStepCountEvents => {
    return bindActionCreators({}, dispatch);
};

export const PatientInformationStepCount = memo(connect(mapStateToProps, mapDispatchToProps)(PatientInformationStepCountTemplate));
