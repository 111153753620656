import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {DoctorsDashboardPatientStatisticsHttpClient, IGetPatientInformationResponse, IPatientOverallSummary,} from "doctors-dashboard/http-clients/index";
import {ORPYXSI_API_URL} from "../../../../../../appConstants";
import {PatientInformationPageSelectors} from "../../patient-information-page/store/patient-information-page.selectors";
import {GET_PATIENT_STATISTIC_FAILED, GET_PATIENT_STATISTIC_SUCCESSFULLY, PatientStatisticActions} from "./patient-statistic.actions";

const API_VERSION = '1';

function* onFetchPatientStatistic() {
    try {
        const patient: IGetPatientInformationResponse = yield select(PatientInformationPageSelectors.patient)!;
        const monitoringCycle = yield select(PatientInformationPageSelectors.monitoringCycle)!;

        const api = new DoctorsDashboardPatientStatisticsHttpClient(ORPYXSI_API_URL);

        const data: IPatientOverallSummary = yield call(
            [api, api.getPatientSummaryStatistics],
            patient.patientId!,
            //  TODO: Fix types
            monitoringCycle.begin! as any,
            API_VERSION,
        );

        yield put(GET_PATIENT_STATISTIC_SUCCESSFULLY(data));
    } catch (e) {
        yield put(GET_PATIENT_STATISTIC_FAILED(e));
    }
}

export default function* PatientStatisticSagas() {
    yield all([
        yield takeEvery(PatientStatisticActions.GET_PATIENT_STATISTIC, onFetchPatientStatistic),
    ]);
}