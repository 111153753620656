import * as React from "react";
import {memo, useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {Container} from "@material-ui/core";
import {ListPagination, ListSearch} from "orpyx-web-common";
import {PatientsListTable} from "../patients-list-table/patients-list-table";
import {PatientsListGlobalSearchTable} from "../patient-list-global-search/table/patients-list-global-search-table";
import {usePatientsListPageStyle} from "./patients-list-page.style";
import {PatientsListPageEvents, PatientsListPageMeta, PatientsListPageOwnProps, PatientsListPageProps, AuthProps} from "./patients-list-page.interface";
import {FETCH_PATIENTS_LIST} from "../../store/patients-list.actions";
import {PatientsListSelectors} from "../../store/patients-list.selectors";
import {DoctorsDashboardAuthSelectors} from '../../../../../doctor-dashboard/auth/store/doctors-dashboard-auth-selectors'
import {PatientFilterType} from "doctors-dashboard/http-clients/index";
import {withRouter} from "react-router";
import {
    onPatientsListCountChanged,
    onPatientsListPageChanged,
    onPatientsListPageLoaded,
    onPatientsListPatientTypeChanged,
    onPatientsListSearchOptionsChanged,
    onPatientsListTermValidation,
} from "./patients-list-page.hooks";
import {PatientsListOrderPipeItems} from "../../enum/patients-list-order";
import {PatientsListFilterByPipeItems} from "../../enum/patients-list-search-by";
import {PatientFilterTypeDisplayPipe} from "../../../../pipes/patient-filter-type-pipe";
import {PatientsListTypeSelector} from "../patients-list-type-selector/patients-list-type-selector";
import { OrganizationSelector } from "../patient-list-organization-selector/patient-list-organization-selector";
import { DoctorsDashboardOrganizationsSelectors } from "modules/doctor-dashboard/organizations/store/doctors-dashboard-organizations-selectors";
import { DOCTORS_DASHBOARD_UPDATE_CURRENT_ORGANIZATION } from "modules/doctor-dashboard/runtime/runtime-actions";
import { DoctorsDashboardRuntimeSelectors } from "modules/doctor-dashboard/runtime/runtime-selectors";

const PatientsListPageTemplate = (props: PatientsListPageMeta) => {
    const classes = usePatientsListPageStyle();

    useEffect(onPatientsListPageLoaded(props), [props.location]);

    const { patientType } = props;

    return (
        <Container>
            <div className={classes.root}>
                <PatientsListTypeSelector
                    onChangePatientType={onPatientsListPatientTypeChanged(props)}
                    patientType={patientType}
                    loading={props.loading}
                    hasCustomerCarePermissions={props.hasCustomerCarePermissions}
                />

                <OrganizationSelector 
                    defaultOrganization={props.defaultOrganization}
                    currentOrganization={props.currentOrganization}
                    organizations={props.organizations}
                    renderOrganizationSelect={props.hasCustomerCarePermissions}
                    onOrganizationSelected={props.onOrganizationSelected}
                />

                <div className={classes.content}>
                    <div className={classes.topBlock}>
                        <div className={classes.title} id="patients-list-page-data-count">
                            {PatientFilterTypeDisplayPipe(patientType)}: {!props.loading && props.total}
                        </div>
                    </div>

                    { patientType !== PatientFilterType.GlobalSearch ? 
                        <>
                            <ListSearch
                                term={props.search.term}
                                inProcess={props.loading}
                                orderBy={props.search.orderBy}
                                orderByAsc={props.search.orderByAsc}
                                orderByItems={PatientsListOrderPipeItems}
                                filterBy={props.search.filterBy}
                                isButtonOnLeft={true}
                                filterByItems={PatientsListFilterByPipeItems}
                                placeholder={'Search patients by name or Patient ID'}
                                onSearch={onPatientsListSearchOptionsChanged(props)}
                                onSearchValidate={onPatientsListTermValidation}
                            />
                            <PatientsListTable
                                patientType={patientType}
                            />
                        </>
                        :
                        <>
                            <ListSearch
                                term={props.search.term}
                                inProcess={props.loading}
                                isButtonOnLeft={true}
                                placeholder={'Search by patient name'}
                                onSearch={onPatientsListSearchOptionsChanged(props)}
                                onSearchValidate={onPatientsListTermValidation}
                            />
                            <PatientsListGlobalSearchTable
                                patientType={patientType}
                            />
                        </>
                    }

                    <ListPagination
                        pages={props.pages}
                        page={props.search.offset}
                        count={props.search.count}
                        label={'Patients per page'}
                        onSelectedPageChanged={onPatientsListPageChanged(props)}
                        onDisplayCountChanged={onPatientsListCountChanged(props)}
                    />
                </div>
            </div>
        </Container>
    );

};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientsListPageOwnProps => {
    return {
        search: PatientsListSelectors.search(state),

        loading: PatientsListSelectors.loading(state),

        total: PatientsListSelectors.total(state),
        pages: PatientsListSelectors.pages(state),

        userType: DoctorsDashboardAuthSelectors.actorType(state),
        defaultOrganization: DoctorsDashboardAuthSelectors.defaultOrganization(state),
        organizations: DoctorsDashboardOrganizationsSelectors.organizations(state),
        hasCustomerCarePermissions: DoctorsDashboardAuthSelectors.hasCustomerCarePermissions(state),

        currentOrganization: DoctorsDashboardRuntimeSelectors.currentOrganization(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientsListPageProps): PatientsListPageEvents => {
    return bindActionCreators({
        refreshPatientsList: FETCH_PATIENTS_LIST,
        onOrganizationSelected: DOCTORS_DASHBOARD_UPDATE_CURRENT_ORGANIZATION,
    }, dispatch);
};

const PatientsListPage = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientsListPageTemplate)));

export const buildPatientsListPage = (patientType: PatientFilterType) => () => {
    return <PatientsListPage patientType={patientType}/>
};
