import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {DoctorsDashboardNavbarEvents, DoctorsDashboardNavbarMeta, DoctorsDashboardNavbarOwnProps, DoctorsDashboardNavbarProps} from "./doctors-dashboard-navbar.interface";
import {useDoctorsDashboardNavbarStyle} from "./doctors-dashboard-navbar.style";
import {IOrpyxApplicationStore} from "../../../../store/application.reducers";
import {AdminDashboardNavbarLoginWrapper} from "../doctors-dashboard-navbar-login/doctors-dashboard-navbar-login";
import {Typography} from "@material-ui/core";
import {DoctorsDashboardNavbarProfileWrapper} from "../doctors-dashboard-navbar-profile/doctors-dashboard-navbar-profile";
import clsx from "clsx";
import { DoctorsDashboardAuthSelectors } from "../../../auth/store/doctors-dashboard-auth-selectors";
import {isNullOrUndefined} from "orpyx-web-common";
import { DashboardUserType } from "doctors-dashboard";

const DoctorsDashboardNavbarTemplate = (props: DoctorsDashboardNavbarMeta) => {
    const classes = useDoctorsDashboardNavbarStyle();
    const linkClassName = props.usePrimaryLinkColor ? clsx(classes.link, classes.primaryColor) : classes.link;

    if (!props.canDisplayNavBar) {
        return <></>
    }

    return (
        <div>
            <Typography className={classes.root}>
                {props.isLoggedIn && <DoctorsDashboardNavbarProfileWrapper linkClassName={linkClassName}/>}

                <AdminDashboardNavbarLoginWrapper/>
            </Typography>

        </div>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): DoctorsDashboardNavbarOwnProps => {
    const actorType =  DoctorsDashboardAuthSelectors.actorType(state);
    return {
        isLoggedIn: DoctorsDashboardAuthSelectors.isLoggedIn(state),
        canDisplayNavBar: isNullOrUndefined(actorType) || actorType === DashboardUserType.DashboardUser
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: DoctorsDashboardNavbarProps): DoctorsDashboardNavbarEvents => {
    return bindActionCreators({}, dispatch);
};

export const DoctorsDashboardNavbar = memo(connect(mapStateToProps, mapDispatchToProps)(DoctorsDashboardNavbarTemplate));
