import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const DocumentListTableStyle = createStyles({
    icon: {
        verticalAlign: 'sub',
        paddingRight: '10px',
    },
    loadMoreReportsButton: {
        display: "block",
        margin: '25px auto 10px',
        '& span': {
            color: 'white'
        },
    },
    tableContainer: {
        maxHeight: '450px',
    },
    tableButton: { 
        textAlign: "end", 
        marginTop: "1rem" 
    }
});

export const useDocumentListTableStyle = makeStyles((theme: Theme) => DocumentListTableStyle);