import {PatientSubmitCareNoteMeta} from "./patient-submit-care-note.interface";
import {PatientSubmitCareNoteFormData} from "../patient-submit-care-note-form/patient-submit-care-note-form.interface";
import {ActivityLogReportOptions, IPostCareNoteModel, TemperatureOptions} from "doctors-dashboard/http-clients/index";
import moment from 'moment';

export const onPatientSubmitCareNote = (props: PatientSubmitCareNoteMeta) => (form: PatientSubmitCareNoteFormData): void => {
    const selectedActionRequiredAlerts = Object.keys(props.selectedActionRequiredAlerts).map(q => parseInt(q, 10));

    const model: IPostCareNoteModel = {
        actionRequiredAlertIds: selectedActionRequiredAlerts,
        noteText: form.comment,
        carePractitionerActionTypeId: form.actionId,
        manualTime: form.timeSpent,
        trackingTime: props.trackedTime,
        activityLogReportBuildingOptions: new ActivityLogReportOptions({
            temperature: new TemperatureOptions(props.temperature.options),
            timeZoneOffset: moment().utcOffset() / 60,
            dateOverride: undefined
        })
    };

    props.submitCareNoteForm(model);
};
