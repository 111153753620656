import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core";

export const usePatientListOrganizationSelectorStyle = makeStyles((theme: Theme) => createStyles({
    right: {
        float: 'right',
    },
    icon: {
        width: '24px',
        height: '24px',
        verticalAlign: "middle",
        marginRight: '10px'
    },
}));
