import {memo} from "react";
import walkStepIcon from "../../../../../../../images/walk-step.svg";
import * as React from "react";
import {PatientStepCountAverageChartProps} from "./patient-step-count-average-chart.interface";
import {usePatientStepCountAverageChartStyles} from "./patient-step-count-average-chart.styles";

export const PatientStepCountAverageChart = memo((props: PatientStepCountAverageChartProps) => {
    const classes = usePatientStepCountAverageChartStyles();

    return (
        <div className={classes.summary}>
            <div className={classes.stepCountContainer}>
                <img src={walkStepIcon} alt="" className={classes.stepCountImage}/>
                <div className={classes.centered}>
                    <div className={classes.number}>
                        {props.summary?.averageSteps ? Math.ceil(props.summary!.averageSteps!).toLocaleString(): '-'}
                    </div>
                    <div>
                        Average steps
                    </div>
                </div>
            </div>
        </div>
    );
});