import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

export const usePatientTemperatureChartStyles = makeStyles((theme: Theme) => createStyles({
    dates: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    chart: {
        height: '350px'
    }
}));
