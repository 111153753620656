import { OrganizationOutputDto, OrganizationOutputDtoPagingResult } from "doctors-dashboard";
import produce from "immer";
import { StoreAction } from "orpyx-web-common";
import { DoctorsDashboardOrganizationsActions } from "./doctors-dashboard-organizations-actions";

export interface DoctorsDashboardOrganizationsState {
    inProcess: boolean,
    organizations: Array<OrganizationOutputDto>
}

const createInitialState = (): DoctorsDashboardOrganizationsState => {
    return {
        inProcess: false,
        organizations: [] 
    };
}

const DoctorsDashboardOrganizationsReducers = (state = createInitialState(), action: StoreAction<DoctorsDashboardOrganizationsActions>): DoctorsDashboardOrganizationsState => {
    return produce(state, (draft: DoctorsDashboardOrganizationsState) => {
        switch (action.type) {
            case DoctorsDashboardOrganizationsActions.FETCH_ORGANIZATIONS:
                draft.inProcess = true;
                break;
            case DoctorsDashboardOrganizationsActions.FETCH_ORGANIZATIONS_FAILED:
                draft.inProcess = false;
                break;
            case DoctorsDashboardOrganizationsActions.FETCH_ORGANIZATIONS_SUCCESSFULLY:
                const payload = action.payload as OrganizationOutputDtoPagingResult;
                draft.inProcess = false;
                draft.organizations = payload.items!;
                break;
        }
    });
}

export default DoctorsDashboardOrganizationsReducers;