import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {DoctorsDashboardAzureActor} from "../models/doctors-dashboard-azure-token";
import {IGetDashboardUserProfileResponse} from "doctors-dashboard/http-clients/index";

export enum DoctorsDashboardAuthActions {
    LOGIN = 'DOCTORS_DASHBOARD_AUTH: LOGIN',
    LOGIN_SUCCESSFULLY = 'DOCTORS_DASHBOARD_AUTH: LOGIN_SUCCESSFULLY',
    LOGIN_COMPLETE = 'DOCTORS_DASHBOARD_AUTH: LOGIN_COMPLETE',
    LOGIN_FAILED = 'DOCTORS_DASHBOARD_AUTH: LOGIN_FAILED',

    LOGOUT = 'DOCTORS_DASHBOARD_AUTH: LOGOUT',
    LOGOUT_SUCCESSFULLY = 'DOCTORS_DASHBOARD_AUTH: LOGOUT_SUCCESSFULLY',
    LOGOUT_FAILED = 'DOCTORS_DASHBOARD_AUTH: LOGOUT_FAILED',
    LOGOUT_CANCELLED = 'DOCTORS_DASHBOARD_AUTH: LOGOUT_CANCELLED',

    FETCH_USER_PROFILE = 'DOCTORS_DASHBOARD_AUTH: FETCH_USER_PROFILE',
    FETCH_USER_PROFILE_SUCCESSFULLY = 'DOCTORS_DASHBOARD_AUTH: FETCH_USER_PROFILE_SUCCESSFULLY',
    FETCH_USER_PROFILE_FAILED = 'DOCTORS_DASHBOARD_AUTH: FETCH_USER_PROFILE_FAILED',

    RESET_LAST_ACTIVITY_TIME = 'DOCTORS_DASHBOARD_AUTH: RESET_LAST_ACTIVITY_TIME',

    SET_LOGOUT_CONFIRMATION = 'DOCTORS_DASHBOARD_AUTH: SET_LOGOUT_CONFIRMATION'
}

export const DOCTORS_DASHBOARD_LOGIN = (): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.LOGIN
});

export const DOCTORS_DASHBOARD_LOGIN_SUCCESSFULLY = (actor: DoctorsDashboardAzureActor): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.LOGIN_SUCCESSFULLY, payload: actor
});

export const DOCTORS_DASHBOARD_LOGIN_FAILED = (error: OperationErrorType): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.LOGIN_FAILED, payload: error
});

export const DOCTORS_DASHBOARD_LOGIN_COMPLETE = (): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.LOGIN_COMPLETE
});

export const DOCTORS_DASHBOARD_LOGOUT = (): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.LOGOUT
});

export const DOCTORS_DASHBOARD_LOGOUT_SUCCESSFULLY = (): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.LOGOUT_SUCCESSFULLY
});

export const DOCTORS_DASHBOARD_LOGOUT_FAILED = (error: OperationErrorType): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.LOGOUT_FAILED, payload: error
});

export const DOCTORS_DASHBOARD_LOGOUT_CANCELLED = (): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.LOGOUT_CANCELLED
})

export const DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE = (): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.FETCH_USER_PROFILE
});

export const DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE_SUCCESSFULLY = (roles: IGetDashboardUserProfileResponse): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.FETCH_USER_PROFILE_SUCCESSFULLY, payload: roles
});

export const DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE_FAILED = (error: OperationErrorType): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.FETCH_USER_PROFILE_FAILED, payload: error
});

export const DOCTORS_DASHBOARD_RESET_LAST_ACTIVE_TIME = (): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.RESET_LAST_ACTIVITY_TIME
});

export const DOCTORS_DASHBOARD_AUTH_SET_LOGOUT_CONFIRMATION = (confirmationFlag: boolean): StoreAction<DoctorsDashboardAuthActions> => ({
    type: DoctorsDashboardAuthActions.SET_LOGOUT_CONFIRMATION, payload: confirmationFlag
})
