import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientStatisticActions} from "./patient-statistic.actions";
import {IPatientOverallSummary} from "doctors-dashboard/http-clients/index";

export interface PatientStatisticState {
    initialized: boolean,
    loading: boolean,
    error: OperationErrorType;

    summary: IPatientOverallSummary | undefined;
}

const initialState: PatientStatisticState = {
    initialized: false,
    loading: false,

    error: undefined,

    summary: undefined,
}

const PatientStatisticReducers = (state = initialState, action: StoreAction<PatientStatisticActions>): PatientStatisticState => {
    return produce(state, (draft: PatientStatisticState) => {
        switch (action.type) {
            case PatientStatisticActions.RESET_PATIENT_STATISTIC:
                draft.loading = false;
                draft.initialized = false;
                draft.error = undefined;
                draft.summary = undefined;
                break;

                case PatientStatisticActions.GET_PATIENT_STATISTIC:
                draft.loading = true;
                draft.initialized = false;
                draft.error = undefined;
                draft.summary = undefined;
                break;

            case PatientStatisticActions.GET_PATIENT_STATISTIC_SUCCESSFULLY:
                draft.summary = action.payload;
                draft.error = undefined;
                draft.loading = false;
                draft.initialized = true;
                break;

            case PatientStatisticActions.GET_PATIENT_STATISTIC_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;
        }
    });
};

export default PatientStatisticReducers;