import { OperationError, OrganizationOutputDto } from "doctors-dashboard";
import { StoreAction } from "orpyx-web-common";

export enum DoctorsDashboardRuntimeActions {
    UPDATE_CURRENT_ORGANIZATION = 'DOCTORS_DASHBOARD_CURRENT_ORGANIZATION: UPDATE_CURRENT_ORGANIZATION',
    UPDATE_CURRENT_ORGANIZATION_SUCCESSFULLY = 'DOCTORS_DASHBOARD_CURRENT_ORGANIZATION: UPDATE_CURRENT_ORGANIZATION_SUCCESSFULLY',
    UPDATE_CURRENT_ORGANIZATION_FAILED = 'DOCTORS_DASHBOARD_CURRENT_ORGANIZATION: UPDATE_CURRENT_ORGANIZATION_FAILED',
}

export const DOCTORS_DASHBOARD_UPDATE_CURRENT_ORGANIZATION = (organization: OrganizationOutputDto): StoreAction<DoctorsDashboardRuntimeActions> => ({
    type: DoctorsDashboardRuntimeActions.UPDATE_CURRENT_ORGANIZATION, payload: organization
});

export const DOCTORS_DASHBOARD_UPDATE_CURRENT_ORGANIZATION_SUCCESSFULLY = (organization: OrganizationOutputDto): StoreAction<DoctorsDashboardRuntimeActions> => ({
    type: DoctorsDashboardRuntimeActions.UPDATE_CURRENT_ORGANIZATION_SUCCESSFULLY, payload: organization
});

export const DOCTORS_DASHBOARD_UPDATE_CURRENT_ORGANIZATION_FAILED = (error: OperationError): StoreAction<DoctorsDashboardRuntimeActions> => ({
    type: DoctorsDashboardRuntimeActions.UPDATE_CURRENT_ORGANIZATION_FAILED, payload: error
});