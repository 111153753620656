import moment from "moment";
import {AxisModel, MarkerSettingsModel, AnimationModel, ITooltipRenderEventArgs} from "@syncfusion/ej2-react-charts";
import {FixedNumericPipe} from "orpyx-web-common";

const font = {
    fontFamily: 'Poppins'
};

export const PatientTemperatureAreaAnimation: AnimationModel = {
    enable: false
};

export const PatientTemperatureAreaMarkers = (borderColor: string) : MarkerSettingsModel => ({
    visible: true,
    border: {
        color: borderColor,
    },
});

export const PatientTemperatureAreaData = (startDate: moment.Moment, endDate: moment.Moment) => [
    {x: startDate, low: -2.2, high: 2.2, value: 0},
    {x: endDate, low: -2.2, high: 2.2, value: 0},
];

export const PatientTemperatureChartAxisX = (startDate: moment.Moment, endDate: moment.Moment): AxisModel => ({
    valueType: 'DateTime',
    minimum: startDate,
    maximum: endDate,
    labelFormat: 'MMM dd',
    edgeLabelPlacement: 'Shift',
    titleStyle: font,
    labelStyle: font,
});

export const PatientTemperatureChartAxisY: AxisModel = {
    title: 'Temperature Difference (°C)',
    titleStyle: font,
    labelStyle: font,
    minimum: -7,
    maximum: 7,
    interval: 1,
};

export const PatientTemperatureChartTooltip = {
    enable: true,
    header: '${point.x}',
    textStyle: font,
};

export const PatientTemperatureChartRender = (args: ITooltipRenderEventArgs | undefined) => {
    if (args) {
        const value = FixedNumericPipe(args.point.y as number, 3);
        args.text = `${value} °C`;
    }
}