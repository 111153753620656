import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {
    PatientInformationActionRequiredAlertsEvents,
    PatientInformationActionRequiredAlertsMeta,
    PatientInformationActionRequiredAlertsOwnProps,
    PatientInformationActionRequiredAlertsProps
} from "./patient-information-action-required-alerts.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {PatientInformationActionRequiredAlert} from "../patient-information-action-required-alert/patient-information-action-required-alert";
import {PatientInformationActionRequiredAlertsSelectors} from "../../store/patient-information-action-required-alerts.selectors";
import {SELECT_ACTION_REQUIRED_ALERT} from "../../store/patient-information-action-required-alerts.actions";
import {usePatientInformationPageStyles} from "../../../patient-information-page/components/patient-information-page/patient-information-page.style";

const PatientInformationActionRequiredAlertsTemplate = memo((props: PatientInformationActionRequiredAlertsMeta) => {
    const classes = usePatientInformationPageStyles();

    if (props.loading || !props.actionRequiredAlerts || !props.actionRequiredAlerts.length) {
        //  We are not display progress bar or empty container,
        //  We hidden Action required alerts block
        return null;
    }

    return (
        <div id="patient-information-action-required-alerts">
            <h2 className={classes.title}>
                Action required flags
            </h2>

            {props.actionRequiredAlerts!.map(alert => <PatientInformationActionRequiredAlert
                key={alert.id}
                selected={props.selected}
                onSelected={props.onActionRequiredAlertSelected}
                alert={alert}
            />)}
        </div>
    );
});

const mapStateToProps = (state: IOrpyxApplicationStore): PatientInformationActionRequiredAlertsOwnProps => {
    return {
        actionRequiredAlerts: PatientInformationActionRequiredAlertsSelectors.alerts(state),
        selected: PatientInformationActionRequiredAlertsSelectors.selected(state),

        error: PatientInformationActionRequiredAlertsSelectors.error(state),
        loading: PatientInformationActionRequiredAlertsSelectors.loading(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientInformationActionRequiredAlertsProps): PatientInformationActionRequiredAlertsEvents => {
    return bindActionCreators({
        onActionRequiredAlertSelected: SELECT_ACTION_REQUIRED_ALERT,
    }, dispatch);
};

export const PatientInformationActionRequiredAlerts = memo(connect(mapStateToProps, mapDispatchToProps)(PatientInformationActionRequiredAlertsTemplate));

