import React, { useEffect, useState } from "react";
import { memo } from "react";
import {bindActionCreators, Dispatch} from "redux";
import { Button, CircularProgress, Container, Grid } from "@material-ui/core";
import { ScratchPadNoteEvents, ScratchPadNoteMeta, ScratchPadNoteOwnProps, ScratchPadNoteProps } from "./scratch-pad-notes.interface";
import { usePatientInformationPageStyles } from "../patient-information-page.style";
import { IOrpyxApplicationStore } from "modules/store/application.reducers";
import { ScratchPadNoteSelectors } from "../../../store/scratch-pad-notes/scratch-pad-notes.selectors";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { GET_PATIENT_SCRATCH_PAD_NOTE, GET_PATIENT_SCRATCH_PAD_NOTE_FAILED, SET_PATIENT_SCRATCH_PAD_UNLOAD_LISTENER, UPDATE_PATIENT_SCRATCH_PAD_NOTE } from "../../../store/scratch-pad-notes/scratch-pad-notes.actions";
import { Prompt, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { onPatientScratchPadNotePageLoaded } from "./scratch-pad-notes.hooks";
import { PatientInformationPageSelectors } from "../../../store/patient-information-page.selectors";
import { PatientInformationHeader } from "../../patient-information-header/patient-information-header";
import { GET_PATIENT_INFORMATION, GET_PATIENT_INFORMATION_REFRESH } from "../../../store/patient-information-page.actions";
import { ApplicationBreadcrumbs, ApplicationBreadcrumbsItem, fullNamePipe } from "orpyx-web-common";
import { IGetPatientInformationResponse } from "doctors-dashboard";
import { DOCTORS_DASHBOARD_AUTH_SET_LOGOUT_CONFIRMATION } from "modules/doctor-dashboard/auth/store/doctors-dashboard-auth-actions";
import clipboardIcon from "images/clipboard.svg";
import InventoryIcon from "images/inventory.svg";
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { PatientInformationSubHeader } from "../../patient-information-sub-header/patient-information-sub-header";
import { pages } from "../../patient-information-sub-header/patient-information-sub-header-pages";
import { DocumentList } from "../patient-documents-list/documents-list";
import { DoctorsDashboardAuthSelectors } from "modules/doctor-dashboard/auth/store/doctors-dashboard-auth-selectors";

const generatePageBreadcrumbs = (patient: IGetPatientInformationResponse | undefined): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Patients',
        url: '/patients/Active'
    }];

    if (patient) {
        items.push({
            name: fullNamePipe(patient)!,
            url: `/patients/details/${patient.patientId}`
        });
    }

    return items;
};

export const ScratchPadNotesTemplate = memo((props: ScratchPadNoteMeta) => {
    useEffect(onPatientScratchPadNotePageLoaded(props), []);
    const classes = usePatientInformationPageStyles();

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [renderPrompt, setRenderPrompt] = useState(true);

    // Converts the care note object into readable content
    useEffect(() => {
        if (!careNoteExists()) return;
        
        const { note } = props.careNote!;
        const json = JSON.parse(note!);
        const raw = convertFromRaw(json);
        setEditorState(EditorState.createWithContent(raw));
    }, [props.careNote])


    const onCareNoteSubmit = () => {
        const note = convertCurrentContentToJsonString();

        const version = props.careNote?.version!;
        props.updateScratchPadNote(props.patient, note, version);
    }

    const onFilterDateChanged = (filterDate: Date) => {
        props.onFilterDateChange(filterDate, props.patient.patientId!);
    }

    const getRawContent = () => {
        return convertToRaw(editorState.getCurrentContent());
    }

    const contentStateIsEmpty = () => {
        const currentRawContent = getRawContent();
        if (!currentRawContent) return true;

        const [ block ] = currentRawContent.blocks;
        return currentRawContent.blocks.length === 1 && block.text === '';
    }

    const convertCurrentContentToJsonString = () => {
        const raw = getRawContent();
        return JSON.stringify(raw);
    }

    const careNoteExists = () => {
        const { careNote } = props;
        if (!careNote || !careNote.note) return false;
        return true;
    }

    const noteContentHasChanged = () => {
        if (props.loading) return false;
        if (!careNoteExists() && contentStateIsEmpty()) return false;

        const { note } = props.careNote!;
        const content = convertCurrentContentToJsonString();
        return content !== note;
    }

    // Intercepts navigation events (page back, refresh, tab close, etc.) with a prompt
    const onScratchPadNotesUnload = (event: any) => {
        if (!noteContentHasChanged()) return;
        event.preventDefault();
        event.returnValue = '';
        return '';
    }

    // Prevents multiple alert boxes during logout
    const onScratchPadNotesLogout = () => {
        setRenderPrompt(false);
        window.onbeforeunload = null;

        // Only set on manual click of the logout button so users aren't prompted on session expiry
        props.setLogoutConfirmationRequirement(false);
        if (noteContentHasChanged()) props.setLogoutConfirmationRequirement(true);
    }

    // Sets up window events to warn user about unsaved changes on navigation
    useEffect(() => {
        window.onbeforeunload = noteContentHasChanged() ? onScratchPadNotesUnload : null;
        
        const logoutButton = document.getElementById("logout_button");
        logoutButton?.addEventListener("click", onScratchPadNotesLogout);

        // Clear event listeners on component dismount
        return () => {
            logoutButton?.removeEventListener("click", onScratchPadNotesLogout);
            window.onbeforeunload = null;
        }
    }, [editorState]);

    // Resets the window navigation listeners after a cancelled logout
    useEffect(() => {
        const { createUnloadListener } = props;
        if (createUnloadListener) {
            setRenderPrompt(true);
            
            window.onbeforeunload = onScratchPadNotesUnload;
            props.setUnloadListener(false);
        }
    }, [props.createUnloadListener]);

    const disableSubmit = props.loading || !noteContentHasChanged();
    return (
        <Container className={classes.content} id="patient-information-scratch-pad-container">
            {/* Needed to intercept the breadcrumbs since they use react router */}
            <Prompt
                when={renderPrompt && noteContentHasChanged()}
                message="Changes you made may not be saved."
            />
            <Grid item xs={12}>
                <ApplicationBreadcrumbs items={generatePageBreadcrumbs(props.patient)}/>
            </Grid>

            <Grid container className={classes.root} spacing={4}>
                <Grid item xs={12}>
                    {props.patient && <PatientInformationHeader
                        patient={props.patient}
                        monitoringCycle={props.monitoringCycle}
                        onFilterDateChange={onFilterDateChanged}
                    />}
                </Grid>
                
                <PatientInformationSubHeader
                    patient={props.patient}
                    loading={props.loading}
                    currentPage={pages.careNotes}
                    hasCustomerCarePermissions={props.hasCustomerCarePermissions}
                />

                <Grid item xs={8} id="patient-information-scratch-pad-general-patient-notes">
                    <img src={clipboardIcon} alt="" className={classes.clipboardIcon} />
                    General Patient Notes
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName={classes.careNoteWrapper}
                        editorClassName={classes.careNoteEditor}
                        toolbarClassName={classes.careNoteToolbar}
                    />
                    <Button id="scratch-pad-notes-update-button"
                        variant="contained"
                        color="primary"
                        className={classes.careNoteSubmitButton}
                        disabled={disableSubmit}
                        onClick={onCareNoteSubmit}
                    >
                        {props.loading && <CircularProgress size={20}/>}
                        Update
                    </Button>
                </Grid>

                {props.patient && <Grid item xs={4} id="patient-information-scratch-pad-medical-reference-documents">
                    <img src={InventoryIcon} alt="" className={classes.inventoryIcon} />
                    Medical Reference Documents
                    <DocumentList category="Patient"
                        id={props.patient.patientId!}
                        name={fullNamePipe(props.patient) || ''}
                        documents={[]}
                        loading={false}
                        error={undefined}
                        addNewDocuments={false}
                        files={[]}
                        fileContent={undefined}
                        description={''}
                        showConfirmation={false}
                        key={props.patient.patientId!} 
                        viewDocument={false} 
                        viewingFileName='' />
                    
                </Grid>}
            </Grid>
        </Container>
    );
});

const mapStateToProps = (state: IOrpyxApplicationStore): ScratchPadNoteOwnProps => {
    return {
        patient: PatientInformationPageSelectors.patient(state),
        careNote: ScratchPadNoteSelectors.scratchPadNote(state)!,
        loading: ScratchPadNoteSelectors.loading(state),
        error: ScratchPadNoteSelectors.error(state),
        monitoringCycle: PatientInformationPageSelectors.monitoringCycle(state),
        createUnloadListener: ScratchPadNoteSelectors.setUnloadListener(state),
        hasCustomerCarePermissions: DoctorsDashboardAuthSelectors.hasCustomerCarePermissions(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: ScratchPadNoteProps): ScratchPadNoteEvents => {
    return bindActionCreators({
        loadPatientInformation: GET_PATIENT_INFORMATION,
        loadScratchPadNote: GET_PATIENT_SCRATCH_PAD_NOTE,
        updateScratchPadNote: UPDATE_PATIENT_SCRATCH_PAD_NOTE,
        showErrorMessage: GET_PATIENT_SCRATCH_PAD_NOTE_FAILED,
        onFilterDateChange: GET_PATIENT_INFORMATION_REFRESH,
        setLogoutConfirmationRequirement: DOCTORS_DASHBOARD_AUTH_SET_LOGOUT_CONFIRMATION,
        setUnloadListener: SET_PATIENT_SCRATCH_PAD_UNLOAD_LISTENER,
    }, dispatch);
};

export const ScratchPadNotes = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(ScratchPadNotesTemplate)));