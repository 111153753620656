import {usePatientTemperatureComparisonStyle} from "../patient-temperature-comparison/patient-temperature-comparison.style";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import * as React from "react";
import {memo} from "react";
import {PatientTemperatureRadioGroupProps} from "./patient-temperature-radio-group.interface";

export const PatientTemperatureRadioGroup = memo((props: PatientTemperatureRadioGroupProps) => {
    const classes = usePatientTemperatureComparisonStyle();

    return <div>
        <div className={classes.title}>
            {props.label}
        </div>

        <FormControl component="fieldset">
            <RadioGroup name={props.name} value={props.value}>
                {props.options}
            </RadioGroup>
        </FormControl>
    </div>
});