import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {ICarePractitionerActionDto} from "doctors-dashboard/http-clients/index";

export enum CarePractitionerActionActions {
    FETCH_CARE_PRACTITIONER_ACTIONS = 'FETCH_CARE_PRACTITIONER_ACTIONS: FETCH_CARE_PRACTITIONER_ACTIONS',
    FETCH_CARE_PRACTITIONER_ACTIONS_SUCCESSFULLY = 'FETCH_CARE_PRACTITIONER_ACTIONS: FETCH_CARE_PRACTITIONER_ACTIONS_SUCCESSFULLY',
    FETCH_CARE_PRACTITIONER_ACTIONS_FAILED = 'FETCH_CARE_PRACTITIONER_ACTIONS: FETCH_CARE_PRACTITIONER_ACTIONS_FAILED',
}

export const FETCH_CARE_PRACTITIONER_ACTIONS = (): StoreAction<CarePractitionerActionActions> => ({
    type: CarePractitionerActionActions.FETCH_CARE_PRACTITIONER_ACTIONS
});

export const FETCH_CARE_PRACTITIONER_ACTIONS_SUCCESSFULLY = (actions: ICarePractitionerActionDto[]): StoreAction<CarePractitionerActionActions> => ({
    type: CarePractitionerActionActions.FETCH_CARE_PRACTITIONER_ACTIONS_SUCCESSFULLY, payload: actions
});

export const FETCH_CARE_PRACTITIONER_ACTIONS_FAILED = (error: OperationErrorType): StoreAction<CarePractitionerActionActions> => ({
    type: CarePractitionerActionActions.FETCH_CARE_PRACTITIONER_ACTIONS_FAILED, payload: error
});