import React, { useEffect } from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {Document} from "documents-client"
import { Button, Table, TableBody, TableContainer, CircularProgress, Container } from "@material-ui/core";
import { DocumentListEvents, DocumentListMeta, DocumentListProps } from "./documents-list.interface";
import { ADD_NEW_DOCUMENTS, CLOSE_DOCUMENT_VIEWER, LIST_DOCUMENTS } from "modules/doctor-dashboard/store/document-list/document-list.actions";
import { IOrpyxApplicationStore } from "modules/store/application.reducers";
import { DocumentListSelectors } from "modules/doctor-dashboard/store/document-list/document-list.selectors";
import { usePatientInformationPageStyles } from "../patient-information-page.style";
import { useDocumentListTableStyle } from "./documents-list.style";
import { isNullOrEmpty, OperationSkeleton, PdfViewer } from "orpyx-web-common";
import { DocumentItem } from "../patient-document-item/document-item";
import { DocumentUpload } from "../patient-document-uploads/document-uploads";
import { DocumentUploadConfirmationModal } from "../patient-document-upload-confirmation/document-upload-confirmation";

const DocumentsListTemplate = memo((props: DocumentListMeta) => {
    const classes = usePatientInformationPageStyles();
    const tableClasses = useDocumentListTableStyle();

    const onAddDocuments = () => {
        //open the modal dialog here...
        props.onAddNewDocuments(props.category, props.id);
    };

    useEffect(() => {
        props.onListDocuments(props.category, props.id);
    }, []);
    
    return (
        <Container className={classes.content} /* style={{ height:"400px" }} */>
            {!props.loading && isNullOrEmpty(props.documents) && <span>No documents found.</span>}
            {props.loading && <OperationSkeleton height={60} rows={8}/>}

            <DocumentUpload name={props.name} 
                open={props.addNewDocuments}
                />

            <DocumentUploadConfirmationModal 
                open={props.showConfirmation}
                category={props.category}
                associatedId={props.id} 
                description={props.description} 
                name={props.name} 
                files={props.files}                
                />

            <PdfViewer name={props.viewingFileName} 
                open={props.viewDocument}
                key={props.name}
                fileContent={props.fileContent}
                onClose={props.onCloseDocumentViewer}
                domId = {"pdf-viewer"}
                />

            {props.documents && <TableContainer className={tableClasses.tableContainer}>
                <Table>
                    <TableBody>
                        {props.documents.map((document: Document) =>
                            <DocumentItem category={props.category} 
                                documentId={document.documentId}
                                document={document}
                                fileContent={props.fileContent}
                                key={document.documentId} />
                            )}
                    </TableBody>
                </Table>
            </TableContainer>}
            <div className={tableClasses.tableButton}>
                <Button variant="contained" id="patient-documents-list-upload-button" 
                        color="primary"
                        size="small"
                        onClick={onAddDocuments}>
                            {props.loading && <CircularProgress size={20}/>}
                            Upload
                </Button>
            </div>
        </Container>
    );
});

const mapStateToProps = (state: IOrpyxApplicationStore): DocumentListProps => {
    return {
        addNewDocuments: DocumentListSelectors.addNewDocuments(state),
        documents: DocumentListSelectors.documents(state),
        error: DocumentListSelectors.error(state),
        loading: DocumentListSelectors.loading(state),
        files: DocumentListSelectors.files(state),
        showConfirmation: DocumentListSelectors.showConfirmation(state),
        description: DocumentListSelectors.description(state),
        fileContent: DocumentListSelectors.fileContent(state),
        viewDocument: DocumentListSelectors.viewDocument(state),
        viewingFileName: DocumentListSelectors.viewingFileName(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: DocumentListProps): DocumentListEvents => {
    return bindActionCreators({
        onListDocuments: LIST_DOCUMENTS,
        onAddNewDocuments: ADD_NEW_DOCUMENTS,
        onCloseDocumentViewer: CLOSE_DOCUMENT_VIEWER
    }, dispatch);
};

export const DocumentList = memo(connect(mapStateToProps, mapDispatchToProps)(DocumentsListTemplate));