import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IGetPatientReportsListEntry, IGetPatientReportsListResult} from "doctors-dashboard/http-clients/index";

export enum PatientMonthlyReportsActions {
    RESET_PATIENT_MONTHLY_REPORTS = 'GET_PATIENT_MONTHLY_REPORTS: RESET_PATIENT_MONTHLY_REPORTS',

    GET_PATIENT_MONTHLY_REPORTS = 'GET_PATIENT_MONTHLY_REPORTS: GET_PATIENT_MONTHLY_REPORTS',
    GET_PATIENT_MONTHLY_REPORTS_SUCCESSFULLY = 'GET_PATIENT_MONTHLY_REPORTS: GET_PATIENT_MONTHLY_REPORTS_SUCCESSFULLY',
    GET_PATIENT_MONTHLY_REPORTS_FAILED = 'GET_PATIENT_MONTHLY_REPORTS: GET_PATIENT_MONTHLY_REPORTS_FAILED',

    DOWNLOAD_PATIENT_MONTHLY_REPORT = 'GET_PATIENT_MONTHLY_REPORTS: DOWNLOAD_PATIENT_MONTHLY_REPORT',
    DOWNLOAD_PATIENT_MONTHLY_REPORT_SUCCESSFULLY = 'GET_PATIENT_MONTHLY_REPORTS: DOWNLOAD_PATIENT_MONTHLY_REPORT_SUCCESSFULLY',
    DOWNLOAD_PATIENT_MONTHLY_REPORT_FAILED = 'GET_PATIENT_MONTHLY_REPORTS: DOWNLOAD_PATIENT_MONTHLY_REPORT_FAILED',
}

export const RESET_PATIENT_MONTHLY_REPORTS = (): StoreAction<PatientMonthlyReportsActions> => ({
    type: PatientMonthlyReportsActions.RESET_PATIENT_MONTHLY_REPORTS
});

export const DOWNLOAD_PATIENT_MONTHLY_REPORT = (report: IGetPatientReportsListEntry): StoreAction<PatientMonthlyReportsActions> => ({
    type: PatientMonthlyReportsActions.DOWNLOAD_PATIENT_MONTHLY_REPORT, payload: report
});

export const DOWNLOAD_PATIENT_MONTHLY_REPORT_SUCCESSFULLY = (report: IGetPatientReportsListEntry): StoreAction<PatientMonthlyReportsActions> => ({
    type: PatientMonthlyReportsActions.DOWNLOAD_PATIENT_MONTHLY_REPORT_SUCCESSFULLY, payload: report
});

export const DOWNLOAD_PATIENT_MONTHLY_REPORT_FAILED = (report: IGetPatientReportsListEntry, error: OperationErrorType): StoreAction<PatientMonthlyReportsActions> => ({
    type: PatientMonthlyReportsActions.DOWNLOAD_PATIENT_MONTHLY_REPORT_FAILED, payload: {report, error}
});

export const GET_PATIENT_MONTHLY_REPORTS = (appendMonthlyReports: boolean) => (): StoreAction<PatientMonthlyReportsActions> => ({
    type: PatientMonthlyReportsActions.GET_PATIENT_MONTHLY_REPORTS, payload: appendMonthlyReports
});

export const GET_PATIENT_MONTHLY_REPORTS_SUCCESSFULLY = (reports: IGetPatientReportsListResult): StoreAction<PatientMonthlyReportsActions> => ({
    type: PatientMonthlyReportsActions.GET_PATIENT_MONTHLY_REPORTS_SUCCESSFULLY, payload: reports
});

export const GET_PATIENT_MONTHLY_REPORTS_FAILED = (error: OperationErrorType): StoreAction<PatientMonthlyReportsActions> => ({
    type: PatientMonthlyReportsActions.GET_PATIENT_MONTHLY_REPORTS_FAILED, payload: error
});
