import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core";

export const usePatientAdherenceDailyUseStyle = makeStyles((theme: Theme) => createStyles({
    summary: {
        alignContent: "center",
        textAlign: "center",
    },
    stopwatchIcon: {
        height: '100%',
        opacity: 0.85,
    },
    stopwatchContainer: {
        position: 'relative',
        textAlign: 'center',
        height: '100px',
        marginBottom: '17px',
    },
    centered: {
        position: "absolute",
        top: '54px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    number: {
        fontSize: '30px',
    },
    name: {
        fontSize: '14px',
        marginBottom: '5px',
    },
}));
