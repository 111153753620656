import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";


import {PatientMonthlyReportsEvents, PatientMonthlyReportsMeta, PatientMonthlyReportsOwnProps, PatientMonthlyReportsProps} from "./patient-monthly-reports.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {PatientMonthlyReportsSelectors} from "../../store/patient-monthly-reports.selectors";
import {PatientMonthlyReportsTable} from "../patient-monthly-reports-table/patient-monthly-reports-table";
import {DOWNLOAD_PATIENT_MONTHLY_REPORT, GET_PATIENT_MONTHLY_REPORTS} from "../../store/patient-monthly-reports.actions";
import {usePatientMonthlyReportsStyle} from "./patient-monthly-reports.style";
import {OperationProgress} from "orpyx-web-common";
import {Button} from "@material-ui/core";

const PatientMonthlyReportsTemplate = (props: PatientMonthlyReportsMeta) => {
    const classes = usePatientMonthlyReportsStyle();

    return (<>
        <PatientMonthlyReportsTable
            classes={classes}

            reports={props.monthlyReports.reports}
            inProcess={props.monthlyReports.inProcess}
            onClickedDownloadMonthlyReport={props.onClickedDownloadMonthlyReport}
        />

        <OperationProgress inProcess={props.loading} error={props.error}/>

        {props.monthlyReports.canGetMore && <Button
            variant="contained"
            color="primary"
            className={classes.loadMoreReportsButton}
            onClick={() => props.onClickedLoadMore()}
            disabled={props.loading}
            type="button"
        >
            Load more
        </Button>}
    </>);
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientMonthlyReportsOwnProps => {
    return {
        error: PatientMonthlyReportsSelectors.error(state),
        loading: PatientMonthlyReportsSelectors.loading(state),
        monthlyReports: {
            canGetMore: PatientMonthlyReportsSelectors.canGetMoreCareNotes(state),
            reports: PatientMonthlyReportsSelectors.monthlyReports(state),
            inProcess: PatientMonthlyReportsSelectors.inProcess(state),
        }
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientMonthlyReportsProps): PatientMonthlyReportsEvents => {
    return bindActionCreators({
        onClickedDownloadMonthlyReport: DOWNLOAD_PATIENT_MONTHLY_REPORT,
        onClickedLoadMore: GET_PATIENT_MONTHLY_REPORTS(true),
    }, dispatch);
};

export const PatientMonthlyReports = memo(connect(mapStateToProps, mapDispatchToProps)(PatientMonthlyReportsTemplate));
