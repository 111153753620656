import {azureBlobHost} from "../../../appConstants";
import {isNullOrUndefined} from "orpyx-web-common";

// deal with some legacy issues, where the imageUrl is already a Url address.
export const ImageUrlPipe = (urlOrImage: string | null | undefined, defaultValue: string = ''): string => {
    if (isNullOrUndefined(urlOrImage)) {
        return defaultValue;
    }

    if (urlOrImage!.startsWith("http")) {
        return urlOrImage!;
    }

    if (urlOrImage!.includes("base64,") || urlOrImage!.includes("data:image/")) {
        return urlOrImage!;
    }

    return `${azureBlobHost}/${urlOrImage}`;
}