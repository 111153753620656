import {combineReducers} from "redux";
import PatientInformationPageReducers from "../patient-information-page/store/patient-information-page.reducers";
import PatientInformationActionRequiredAlertsReducers from "../patient-action-required-alerts/store/patient-information-action-required-alerts.reducers";
import PatientTemperatureReducers from "../patient-temperature/store/patient-temperature.reducers";
import PatientStatisticReducers from "../patient-statistic/store/patient-statistic.reducers";
import PatientActivityLogReducers from "../patient-activity-log/store/patient-activity-log.reducers";
import PatientMonthlyReportsReducers from "../patient-monthly-reports/store/patient-monthly-reports.reducers";
import PatientStepCountReducers from "../patient-step-count/store/patient-step-count.reducers";
import DocumentListReducers from "modules/doctor-dashboard/store/document-list/document-list.reducers";

export const PatientInformationReducers = combineReducers({
    information: PatientInformationPageReducers,
    actionRequiredAlerts: PatientInformationActionRequiredAlertsReducers,
    temperature: PatientTemperatureReducers,
    statistic: PatientStatisticReducers,
    stepCounts: PatientStepCountReducers,

    careNotes: PatientActivityLogReducers,
    monthlyReports: PatientMonthlyReportsReducers,
    documents: DocumentListReducers
});