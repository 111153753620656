import {DateRange, getFirstMonthDay, getLastMonthDay, OperationErrorType, StoreAction} from "orpyx-web-common";
import {IGetPatientInformationResponse} from "doctors-dashboard/http-clients/index";
import produce from "immer";
import {PatientInformationPageActions} from "./patient-information-page.actions";
import moment from "moment";

export interface PatientInformationPageState {
    loading: boolean;
    error: OperationErrorType;

    patient: IGetPatientInformationResponse | undefined;

    automationTrackingTimeStartDate: moment.Moment | undefined;
    monitoringCycle: DateRange,

    filterDate: Date;
}

const getCurrentMonitoringCycle = (): DateRange => {
    const startOfMonth = getFirstMonthDay(moment())?.local(true)!;
    const endOfMonth = getLastMonthDay(startOfMonth)!;

    return {
        begin: startOfMonth,
        end: endOfMonth,
    };
}

const getMonitoringCycleByFilter = (filterDate: Date): DateRange => {
    const date = moment(filterDate)?.local(true)!;
    return {
        begin: getFirstMonthDay(date)!,
        end: getLastMonthDay(date)!
    }
}

const initialState: PatientInformationPageState = {
    loading: false,
    error: undefined,

    patient: undefined,
    automationTrackingTimeStartDate: undefined,
    monitoringCycle: getCurrentMonitoringCycle(),
    filterDate: moment().toDate()
};


const PatientInformationPageReducers = (state = initialState, action: StoreAction<PatientInformationPageActions>): PatientInformationPageState => {
    return produce(state, (draft: PatientInformationPageState) => {
        switch (action.type) {
            case PatientInformationPageActions.GET_PATIENT_INFORMATION:
                draft.loading = true;
                draft.error = undefined;
                draft.patient = undefined;
                draft.monitoringCycle = getCurrentMonitoringCycle();
                draft.filterDate = moment().toDate();
                break;

            case PatientInformationPageActions.GET_PATIENT_INFORMATION_SUCCESSFULLY:
                draft.error = undefined;
                draft.patient = action.payload;
                draft.loading = false;
                break;

            case PatientInformationPageActions.GET_PATIENT_INFORMATION_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;

            case PatientInformationPageActions.GET_PATIENT_INFORMATION_REFRESH:
                draft.loading = true;
                draft.error = undefined;
                draft.monitoringCycle = getMonitoringCycleByFilter(action.payload.filterDate);
                draft.filterDate = action.payload.filterDate;
                break;
            
            case PatientInformationPageActions.GET_PATIENT_INFORMATION_REFRESH_SUCCESSFULLY:
                draft.loading = false;
                break;
        }
    });
};

export default PatientInformationPageReducers;
