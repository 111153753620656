import {all} from "redux-saga/effects";
import PatientsSagas from "../patients/store/patients.sagas";
import CarePractitionerActionSagas from "../care-practitioner-action/store/care-practitioner-action.sagas";
import {DoctorsDashboardAuthSagas} from "../auth/store/doctors-dashboard-auth-sagas";
import { DoctorsDashboardOrganizationsSagas } from "../organizations/store/doctors-dashboard-organizations-sagas";
import { DoctorsDashboardRuntimeSagas } from "../runtime/runtime-sagas";

export default function* DoctorDashboardSagas() {
    yield all([
        PatientsSagas(),
        CarePractitionerActionSagas(),
        DoctorsDashboardAuthSagas(),
        DoctorsDashboardOrganizationsSagas(),
        DoctorsDashboardRuntimeSagas()
    ]);
}
