import {createSelector} from "reselect";
import {getDoctorDashboardState} from "../../store/doctor-dashboard.selectors";

export class CarePractitionerActionSelectors {
    static readonly state = createSelector(getDoctorDashboardState, state => state.carePractitionerAction);

    static readonly actions = createSelector(CarePractitionerActionSelectors.state, state => state.actions);
    static readonly initialized = createSelector(CarePractitionerActionSelectors.state, state => state.initialized);
    static readonly error = createSelector(CarePractitionerActionSelectors.state, state => state.error);
}


