import {PatientActivityLogTableItemProps} from "./patient-activity-log-table-item.interface";
import React, {memo} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {AdvancedIconButton, DatePipe, DateTimePipe} from "orpyx-web-common";
import {PatientsListActionRequiredAlerts} from "../../../../patients-list/components/patients-list-action-required-alerts/patients-list-action-required-alerts";
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import {Tooltip} from "@material-ui/core";
import {ImageUrlPipe} from "../../../../../../shared/pipes/image-url-pipe";
import { TimeSpan } from "modules/shared/utility/timeSpan";
import usePatientActivityLogTableItemStyle from "./patient-activity-log-table-item.style";

// Required to preserve the line breaks entered in the original note
const parseNoteTextLineBreaks = (text: string | null | undefined, classes: any) => {
    return text?.split(/\n/).map((line, index) => <p key={index} className={classes.noMargin}>{line}<br /></p>)
}

export const PatientActivityLogTableItem = memo((props: PatientActivityLogTableItemProps) => {
    const careNote = props.careNote;
    const classes = usePatientActivityLogTableItemStyle();
    return <TableRow>
        <TableCell className={props.classes.nowrap}>
            <PatientsListActionRequiredAlerts alerts={careNote.actionRequiredAlerts!}/>
        </TableCell>

        <TableCell className={props.classes.nowrap}>
            <Tooltip title={DateTimePipe(careNote.noteDate)!}>
                <span>
                    {DatePipe(careNote.noteDate)!}
                </span>
            </Tooltip>
        </TableCell>

        <TableCell className={props.classes.noteText}>
            {parseNoteTextLineBreaks(careNote.noteText, classes)}
        </TableCell>

        <TableCell className={props.classes.actionIconCell}>
            {careNote.carePractitionerAction?.url &&
            <Tooltip title={careNote.carePractitionerAction?.name!}>
                <img
                    alt={careNote.carePractitionerAction?.name!}
                    src={ImageUrlPipe(careNote.carePractitionerAction?.url)}
                />
            </Tooltip>}
        </TableCell>

        <TableCell className={props.classes.actionLabelCell}>
            {careNote.carePractitionerAction?.name}
        </TableCell>

        <TableCell className={props.classes.nowrap}>
            {careNote.carePractitionerFullName}
        </TableCell>

        <TableCell>
            {TimeSpan.toHoursAndMinutesAndSeconds(careNote.manualTime ?? careNote.trackingTime)}
        </TableCell>

        <TableCell align="center">
            {careNote.carePractitionerAction?.hasReport && <AdvancedIconButton
                disabled={props.inProcess}
                tooltip={`Download report at ${DateTimePipe(careNote.noteDate)}`}
                icon={GetAppOutlinedIcon}
                onClick={() => props.onClickedDownloadPatientActivityLog(careNote)}
            />}
        </TableCell>
    </TableRow>
});
