import log from 'loglevel';
import {IOrpyxApplicationStore} from "./modules/store/application.reducers";
import {store} from "./modules/store/store";
import {DoctorsDashboardAuthSelectors} from "./modules/doctor-dashboard/auth/store/doctors-dashboard-auth-selectors";
import {BaseHttpClient} from "doctors-dashboard/http-clients/base-http-client";
import {BaseHttpClient as DocumentClientBaseHttpClient} from "documents-client/http-clients/base-http-client";
import {isNullOrUndefined, OrpyxApplicationException} from "orpyx-web-common";
import {unregister} from './registerServiceWorker';

export const azureBlobHost: string = process.env.REACT_APP_AZURE_BLOB_HOST!;
export const ORPYXSI_API_URL: string = process.env.REACT_APP_API_HOST!;
export const DOCUMENTS_API_URL: string = process.env.REACT_APP_DOCUMENTS_API_HOST!;

if (isNullOrUndefined(azureBlobHost)) {
    throw new OrpyxApplicationException('Missed REACT_APP_AZURE_BLOB_HOST')
}

if (isNullOrUndefined(ORPYXSI_API_URL)) {
    throw new OrpyxApplicationException('Missed REACT_APP_API_HOST')
}

if (isNullOrUndefined(DOCUMENTS_API_URL)) {
    throw new OrpyxApplicationException('Missed REACT_APP_DOCUMENTS_API_HOST')
}

export function SetConfiguration() {
    log.setDefaultLevel("DEBUG");

    unregister();

    BaseHttpClient.TokenSelector = () => {
        const state: any = store.getState();
        const accessToken = DoctorsDashboardAuthSelectors.accessToken(state);

        if (accessToken && accessToken.token) {
            //  We allow execute with expired token
            return accessToken.token;
        }
        return '';
    };

    DocumentClientBaseHttpClient.TokenSelector = () => {
        const state: any = store.getState();
        const accessToken = DoctorsDashboardAuthSelectors.accessToken(state);

        if (accessToken && accessToken.token) {
            //  We allow execute with expired token
            return accessToken.token;
        }
        return '';
    };
}
