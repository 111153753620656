import {ComparedTo, Foot, IDailyTemperatureSummaryModel, ITemperatureComparisonModel, ITemperatureOptions, ITemperatureRegionStatsModel, Sensor} from "doctors-dashboard/http-clients/index";
import {OrpyxApplicationException} from "orpyx-web-common";

export const TemperatureComparisonPipe = (options: ITemperatureOptions, model: IDailyTemperatureSummaryModel): number | undefined | null => {
    const foot = TemperatureFootComparisonPipe(options.foot!, model);
    const sensor = TemperatureRegionStatsPipe(options.sensor!, foot);

    if (options.comparedTo === ComparedTo.Contralateral) {
        return sensor?.contra;
    } else if (options.comparedTo === ComparedTo.Ipsilateral) {
        return sensor?.ipsi;
    } else {
        throw new OrpyxApplicationException(`Invalid ComparedTo: ${options.comparedTo}`);
    }
};

const TemperatureFootComparisonPipe = (foot: Foot, model: IDailyTemperatureSummaryModel | null | undefined): ITemperatureRegionStatsModel | null | undefined => {
    if (model) {
        if (foot === Foot.LeftFoot) {
            return model.leftFoot;
        } else if (foot === Foot.RightFoot) {
            return model.rightFoot;
        } else {
            throw new OrpyxApplicationException(`Invalid foot: ${foot}`);
        }
    }
    return undefined;
};

const TemperatureRegionStatsPipe = (sensor: Sensor, model: ITemperatureRegionStatsModel | null | undefined): ITemperatureComparisonModel | null | undefined => {
    if (model) {
        switch (sensor) {
            case Sensor.BigToe:
                return model.bigToe;
            case Sensor.Meta1:
                return model.meta1;
            case Sensor.Meta3:
                return model.meta3;
            case Sensor.Meta5:
                return model.meta5;
            case Sensor.Heel:
                return model.heel;
            default:
                throw new OrpyxApplicationException(`Invalid sensor: ${sensor}`);
        }
    }
    return undefined;
};