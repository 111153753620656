import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core";

export const PatientsListTableStyle = createStyles({
    tableHeadCell: {
        fontSize: '16px',
        color: '#000',
        opacity: 0.6,
    },
});

export const usePatientsListTableStyle = makeStyles((theme: Theme) => PatientsListTableStyle);