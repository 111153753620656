import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IActionRequiredAlertsEntry} from "doctors-dashboard/http-clients/index";

export enum PatientInformationActionRequiredAlertsActions {
    SELECT_ACTION_REQUIRED_ALERT = 'GET_PATIENT_ACTION_REQUIRED_ALERTS: SELECT_ACTION_REQUIRED_ALERT',

    RESET_PATIENT_ACTION_REQUIRED_ALERTS = 'GET_PATIENT_ACTION_REQUIRED_ALERTS: RESET_PATIENT_ACTION_REQUIRED_ALERTS',
    GET_PATIENT_ACTION_REQUIRED_ALERTS = 'GET_PATIENT_ACTION_REQUIRED_ALERTS: GET_PATIENT_ACTION_REQUIRED_ALERTS',
    GET_PATIENT_ACTION_REQUIRED_ALERTS_SUCCESSFULLY = 'GET_PATIENT_ACTION_REQUIRED_ALERTS: GET_PATIENT_ACTION_REQUIRED_ALERTS_SUCCESSFULLY',
    GET_PATIENT_ACTION_REQUIRED_ALERTS_FAILED = 'GET_PATIENT_ACTION_REQUIRED_ALERTS: GET_PATIENT_ACTION_REQUIRED_ALERTS_FAILED',
}

export const SELECT_ACTION_REQUIRED_ALERT = (actionRequiredAlert: number): StoreAction<PatientInformationActionRequiredAlertsActions> => ({
    type: PatientInformationActionRequiredAlertsActions.SELECT_ACTION_REQUIRED_ALERT, payload: actionRequiredAlert
});

export const RESET_PATIENT_ACTION_REQUIRED_ALERTS = (): StoreAction<PatientInformationActionRequiredAlertsActions> => ({
    type: PatientInformationActionRequiredAlertsActions.RESET_PATIENT_ACTION_REQUIRED_ALERTS
});

export const GET_PATIENT_ACTION_REQUIRED_ALERTS = (): StoreAction<PatientInformationActionRequiredAlertsActions> => ({
    type: PatientInformationActionRequiredAlertsActions.GET_PATIENT_ACTION_REQUIRED_ALERTS
});

export const GET_PATIENT_ACTION_REQUIRED_ALERTS_SUCCESSFULLY = (patient: IActionRequiredAlertsEntry[]): StoreAction<PatientInformationActionRequiredAlertsActions> => ({
    type: PatientInformationActionRequiredAlertsActions.GET_PATIENT_ACTION_REQUIRED_ALERTS_SUCCESSFULLY, payload: patient
});

export const GET_PATIENT_ACTION_REQUIRED_ALERTS_FAILED = (error: OperationErrorType): StoreAction<PatientInformationActionRequiredAlertsActions> => ({
    type: PatientInformationActionRequiredAlertsActions.GET_PATIENT_ACTION_REQUIRED_ALERTS_FAILED, payload: error
});