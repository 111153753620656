import React, {memo} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export const PatientActivityLogTableEmptyItem = memo(() => {
    return (<TableRow>
        <TableCell>
            Empty activity log
        </TableCell>

        <TableCell/>
        <TableCell/>
        <TableCell/>
        <TableCell/>
        <TableCell/>
        <TableCell/>
        <TableCell/>
        <TableCell/>

    </TableRow>);
});