import {PatientInformationPageMeta} from "./patient-information-page.interface";
import {OperationResultCode} from "doctors-dashboard/http-clients/index";

export const onPatientInformationPageLoaded = (props: PatientInformationPageMeta) => () => {

    if (props.match.isExact && props.match.params.patientId) {
        const patientId = parseInt(props.match.params.patientId);
        if (isNaN(patientId)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid patient id: ${props.match.params.patientId}`
            });
        } else {
            props.loadPatientInformation(patientId);
        }

    } else {
        props.showErrorMessage({
            code: OperationResultCode.InvalidFilter,
            errorMessage: `Invalid route, patientId: ${props.match.params.patientId}`
        });
    }
};