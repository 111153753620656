import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export const usePatientInformationActionRequiredAlertStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        marginBottom: '5px',
        fontSize: '13px',
    },
    icon: {
        width: '20px',
        height: '20px',
        display: "inline",
        marginRight: '3px',
        verticalAlign: 'sub',
    }, date: {
        display: "inline",
        fontWeight: 'bolder'
    }, message: {
        display: "inline",
        paddingLeft: '5px'
    }, checkbox: {
        display: "inline",
    }
}));