import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {
    ActionRequiredAlertsEntry,
    DoctorsDashboardCareNoteHttpClient,
    IFileResponse,
    IGetPatientInformationResponse,
    IListCareNotesResult,
    IListCareNotesResultEntry,
    DoctorsDashboardDailyReportsHttpClient,
} from "doctors-dashboard/http-clients/index";
import {ORPYXSI_API_URL} from "../../../../../../appConstants";
import {PatientInformationPageSelectors} from "../../patient-information-page/store/patient-information-page.selectors";
import {
    DOWNLOAD_PATIENT_CARE_NOTE_REPORT_FAILED,
    DOWNLOAD_PATIENT_CARE_NOTE_REPORT_SUCCESSFULLY,
    GET_PATIENT_CARE_NOTES_FAILED,
    GET_PATIENT_CARE_NOTES_SUCCESSFULLY,
    PatientActivityLogActions
} from "./patient-activity-log.actions";
import {PatientActivityLogSelectors} from "./patient-activity-log.selectors";
import {base64ToBlob, DateRange, DateTimePipe, ENQUEUE_SNACKBAR, OperationErrorPipe, StoreAction} from "orpyx-web-common";
import FileSaver from "file-saver";

const API_VERSION = '1';

function* onDownloadPatientCareNoteReport(action: StoreAction<PatientActivityLogActions, IListCareNotesResultEntry>) {
    const careNote = action.payload!;

    try {
        const patient: IGetPatientInformationResponse = yield select(PatientInformationPageSelectors.patient)!;

        const api = new DoctorsDashboardDailyReportsHttpClient(ORPYXSI_API_URL);

        const data: IFileResponse = yield call(
            [api, api.getPatientActivityLogReport],
            patient.patientId!,
            careNote.relatedReportName!,
            API_VERSION,
        );

        yield put(DOWNLOAD_PATIENT_CARE_NOTE_REPORT_SUCCESSFULLY(careNote.rowMarker!));
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully downloaded activity report at ${DateTimePipe(careNote.noteDate)}`,
            options: {variant: "success"}
        }));

        const blob = base64ToBlob(data.content!, data.contentType!);
        FileSaver.saveAs(blob, data.fileName!);
    } catch (e) {
        yield put(DOWNLOAD_PATIENT_CARE_NOTE_REPORT_FAILED(careNote.rowMarker!, e));

        yield put(ENQUEUE_SNACKBAR({
            message: `Failed download activity report at ${DateTimePipe(careNote.noteDate)}, error: ${OperationErrorPipe(e)}`,
            options: {variant: "error"}
        }));
    }
}

function* onFetchPatientCareNotes() {
    try {
        const patient: IGetPatientInformationResponse = yield select(PatientInformationPageSelectors.patient)!;
        const lastRowMarker: string | undefined = yield select(PatientActivityLogSelectors.lastRowMarker)!;
        const takeCount: number = yield select(PatientActivityLogSelectors.takeCount)!;

        const monitoringCycle: DateRange = yield select(PatientActivityLogSelectors.monitoringCycle)!;
        const api = new DoctorsDashboardCareNoteHttpClient(ORPYXSI_API_URL);

        const data: IListCareNotesResult = yield call(
            [api, api.searchPatientCareNotes],
            patient.patientId!,
            takeCount,
            lastRowMarker,
            //  TODO: Fix types
            monitoringCycle.begin! as any,
            monitoringCycle.end!  as any,
            API_VERSION
        );

        const safeCareNotes: IListCareNotesResultEntry[] = data.careNotes!.map(careNote => ({
            ...careNote,    //  Need for fix duplicate zero (0) key for action required alerts (not filled by back-end after load from azure)
            actionRequiredAlerts: careNote.actionRequiredAlerts?.map((alert, index) => new ActionRequiredAlertsEntry({
                ...alert,
                id: parseInt(careNote.rowMarker!) + index,
            }))
        }));

        yield put(GET_PATIENT_CARE_NOTES_SUCCESSFULLY(safeCareNotes));
    } catch (e) {
        yield put(GET_PATIENT_CARE_NOTES_FAILED(e));
    }
}

export default function* PatientActivityLogSagas() {
    yield all([
        yield takeEvery(PatientActivityLogActions.DOWNLOAD_PATIENT_CARE_NOTE_REPORT, onDownloadPatientCareNoteReport),
        yield takeEvery(PatientActivityLogActions.GET_PATIENT_CARE_NOTES, onFetchPatientCareNotes),
    ]);
}

