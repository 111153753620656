import * as React from "react";
import {memo} from "react";

import {PatientSafePressureChartProps} from "./patient-safe-pressure-chart.interface";
import {LightGrey, Teal} from "orpyx-web-common";
import {AccumulationChartComponent, AccumulationDataLabel, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, Inject, PieSeries} from "@syncfusion/ej2-react-charts";
import {usePatientSafePressureChartStyle} from "./patient-safe-pressure-chart.style";


export const PatientSafePressureChart = memo((props: PatientSafePressureChartProps) => {
    const classes = usePatientSafePressureChartStyle();

    const safeUsagePercent = props.summary?.overallAverages?.avgPercentTimeInSafePressureZone;

    const pieData = [
        {x: "safe", y: safeUsagePercent ?? 0, p: Teal},
        {x: "unsafe", y: 100 - (safeUsagePercent ?? 0), p: LightGrey},
    ];

    // Repeated null checks should be refactored into a single check. See OSID-303
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                Time in Acceptable Pressure State
            </div>
            <AccumulationChartComponent className={classes.chart}>
                <Inject services={[PieSeries, AccumulationDataLabel]}/>
                <AccumulationSeriesCollectionDirective>
                    <AccumulationSeriesDirective
                        dataSource={pieData}
                        xName='x'
                        yName='y'
                        pointColorMapping='p'
                    />
                </AccumulationSeriesCollectionDirective>
            </AccumulationChartComponent>
            <div className={safeUsagePercent != null ? classes.centered : classes.centeredNoData}>
                {safeUsagePercent  ?? 'No Data'}
                {safeUsagePercent != null && <span className={classes.sup}>%</span>}
            </div>
        </div>
    );
});
