import * as React from 'react';
import {memo} from 'react';
import stopwatchIcon from 'images/stopwatch.png';
import {PatientAdherenceDailyUseOwnProps} from "./patient-adherence-daily-use.interface";
import {usePatientAdherenceDailyUseStyle} from "./patient-adherence-daily-use.style";
import {FixedNumericPipe} from "orpyx-web-common";

export const PatientAdherenceDailyUse = memo((props: PatientAdherenceDailyUseOwnProps) => {
    const classes = usePatientAdherenceDailyUseStyle();

    return (
        <div className={classes.summary}>
            <div className={classes.name}>
                Average Daily Use
            </div>

            <div className={classes.stopwatchContainer}>
                <img src={stopwatchIcon} alt="" className={classes.stopwatchIcon}/>
                <div className={classes.centered}>
                    <div className={classes.number}>
                        {FixedNumericPipe(props.statistic?.overallAverages?.avgDailyUse, 1)}
                    </div>
                    <div>Hours</div>
                </div>
            </div>
        </div>
    );
});