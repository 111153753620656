import {PatientsListGlobalSearchTableProps} from "./patients-list-global-search-table-header.interface";
import {TableCell, TableRow} from "@material-ui/core";
import * as React from "react";
import {memo} from "react";
import TableHead from "@material-ui/core/TableHead";

export const PatientsListGlobalSearchTableHeader = memo((props: PatientsListGlobalSearchTableProps) => {
    const classes = props.classes

    return <TableHead>
        <TableRow>
            <TableCell className={classes.tableHeadCell}>Patient Name</TableCell>
            <TableCell className={classes.tableHeadCell}>Organization</TableCell>
            <TableCell className={classes.tableHeadCell}>Organization City/State</TableCell>
            <TableCell className={classes.tableHeadCell}>Patient Initialization Date</TableCell>
        </TableRow>
    </TableHead>
})