
import {all, call, put, select, takeEvery, delay, fork, ForkEffect} from "redux-saga/effects";
import {DoctorsDashboardCareNoteHttpClient, IGetPatientInformationResponse, IListCareNotesResultEntry, IPostCareNoteModel, PostCareNoteModel,} from "doctors-dashboard/http-clients/index";
import {ORPYXSI_API_URL} from "../../../../../../appConstants";
import {DateRange, ENQUEUE_SNACKBAR, StoreAction} from "orpyx-web-common";
import {
    PATIENT_SUBMIT_CARE_NOTE_FAILED,
    PATIENT_SUBMIT_CARE_NOTE_INCREASE_TIME,
    PATIENT_SUBMIT_CARE_NOTE_RESET,
    PATIENT_SUBMIT_CARE_NOTE_SUCCESSFULLY,
    PatientSubmitCareNoteActions
} from "./patient-submit-care-note.actions";
import {PatientInformationPageSelectors} from "../../patient-information-page/store/patient-information-page.selectors";
import {PatientInformationPageActions} from "../../patient-information-page/store/patient-information-page.actions";
import moment from "moment";

const API_VERSION = '1';

function* onSubmitPatientCareNote(action: StoreAction<PatientSubmitCareNoteActions, IPostCareNoteModel>) {
    try {
        const model = new PostCareNoteModel(action.payload!);
        const patient: IGetPatientInformationResponse = yield select(PatientInformationPageSelectors.patient)!;
        const monitoringCycle: DateRange = yield select(PatientInformationPageSelectors.monitoringCycle)!;

        const api = new DoctorsDashboardCareNoteHttpClient(ORPYXSI_API_URL);

        const data: IListCareNotesResultEntry = yield call(
            [api, api.addPatientCareNote],
            patient.patientId!,
            // hack.  There seems to be a bug with GetPreviousMonth in the WebApi.  Maybe to deal with monthly reports
            // Need to clone monitoringCycle so it doesn't get mutated when we add a day
            // May be able to clean this up after https://bitbucket.org/orpyxinc/orpyxsidashboard-external/pull-requests/420 is merged
            moment(monitoringCycle.begin!).add(1, "day"), 
            API_VERSION,
            model
        );

        yield put(PATIENT_SUBMIT_CARE_NOTE_SUCCESSFULLY(data));

        yield put(ENQUEUE_SNACKBAR({
            message: `Care note successfully added`,
            options: {variant: 'success'}
        }));
    } catch (e) {
        yield put(PATIENT_SUBMIT_CARE_NOTE_FAILED(e));
    }
}

function* increaseTrackedTime() {
    while (true) {
        //  Wait 1 second
        yield delay(1000)

        //  Then increase tracked time
        yield put(PATIENT_SUBMIT_CARE_NOTE_INCREASE_TIME());
    }
}

let increaseTrackedTimeTask: ForkEffect | undefined = undefined;

function* onPatientInformationLoaded() {
    yield put(PATIENT_SUBMIT_CARE_NOTE_RESET());
    if (!increaseTrackedTimeTask) {
        increaseTrackedTimeTask = yield fork(increaseTrackedTime)
    }
}

export default function* PatientSubmitCareNoteSagas(): any {
    yield all([
        yield takeEvery(PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE, onSubmitPatientCareNote),
        yield takeEvery(PatientInformationPageActions.GET_PATIENT_INFORMATION, onPatientInformationLoaded),
    ]);
}