import * as React from 'react';
import {memo} from 'react';
import {usePatientInformationTitleStyle} from "./patient-information-title.style";
import {PatientInformationTitleProps} from "./patient-information-title.interface";

export const PatientInformationTitle = memo((props: PatientInformationTitleProps) => {
    const classes = usePatientInformationTitleStyle();

    return (
        <div className={classes.titleStyle}>
            {props.text}
        </div>
    );
});