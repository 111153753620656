import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {ComparedTo, Foot, IPatientTemperatureSummaryModel, Sensor} from "doctors-dashboard/http-clients/index";

export enum PatientTemperatureActions {
    RESET_PATIENT_TEMPERATURE_SUMMARY = 'GET_PATIENT_TEMPERATURE_SUMMARY: RESET_PATIENT_TEMPERATURE_SUMMARY',

    GET_PATIENT_TEMPERATURE_SUMMARY = 'GET_PATIENT_TEMPERATURE_SUMMARY: GET_PATIENT_TEMPERATURE_SUMMARY',
    GET_PATIENT_TEMPERATURE_SUMMARY_SUCCESSFULLY = 'GET_PATIENT_TEMPERATURE_SUMMARY: GET_PATIENT_TEMPERATURE_SUMMARY_SUCCESSFULLY',
    GET_PATIENT_TEMPERATURE_SUMMARY_FAILED = 'GET_PATIENT_TEMPERATURE_SUMMARY: GET_PATIENT_TEMPERATURE_SUMMARY_FAILED',


    CHANGE_PATIENT_TEMPERATURE_COMPARED_TO = 'GET_PATIENT_TEMPERATURE_SUMMARY: CHANGE_PATIENT_TEMPERATURE_COMPARED_TO',
    CHANGE_PATIENT_TEMPERATURE_SENSOR = 'GET_PATIENT_TEMPERATURE_SUMMARY: CHANGE_PATIENT_TEMPERATURE_SENSOR',
    CHANGE_PATIENT_TEMPERATURE_FOOT = 'GET_PATIENT_TEMPERATURE_SUMMARY: CHANGE_PATIENT_TEMPERATURE_FOOT',
}

export const CHANGE_PATIENT_TEMPERATURE_COMPARED_TO = (comparedTo: ComparedTo): StoreAction<PatientTemperatureActions> => ({
    type: PatientTemperatureActions.CHANGE_PATIENT_TEMPERATURE_COMPARED_TO, payload: comparedTo
});

export const CHANGE_PATIENT_TEMPERATURE_SENSOR = (sensor: Sensor): StoreAction<PatientTemperatureActions> => ({
    type: PatientTemperatureActions.CHANGE_PATIENT_TEMPERATURE_SENSOR, payload: sensor
});

export const CHANGE_PATIENT_TEMPERATURE_FOOT = (foot: Foot): StoreAction<PatientTemperatureActions> => ({
    type: PatientTemperatureActions.CHANGE_PATIENT_TEMPERATURE_FOOT, payload: foot
});

export const RESET_PATIENT_TEMPERATURE_SUMMARY = (): StoreAction<PatientTemperatureActions> => ({
    type: PatientTemperatureActions.RESET_PATIENT_TEMPERATURE_SUMMARY
});

export const GET_PATIENT_TEMPERATURE_SUMMARY = (): StoreAction<PatientTemperatureActions> => ({
    type: PatientTemperatureActions.GET_PATIENT_TEMPERATURE_SUMMARY
});

export const GET_PATIENT_TEMPERATURE_SUMMARY_SUCCESSFULLY = (summary: IPatientTemperatureSummaryModel): StoreAction<PatientTemperatureActions> => ({
    type: PatientTemperatureActions.GET_PATIENT_TEMPERATURE_SUMMARY_SUCCESSFULLY, payload: summary
});

export const GET_PATIENT_TEMPERATURE_SUMMARY_FAILED = (error: OperationErrorType): StoreAction<PatientTemperatureActions> => ({
    type: PatientTemperatureActions.GET_PATIENT_TEMPERATURE_SUMMARY_FAILED, payload: error
});