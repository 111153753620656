import React, { ChangeEvent, useMemo, useState } from "react";
import {
    ModalWindowCancelButton,
    ModalWindowSubmitButton,
    useModalWindowStyle
} from "orpyx-web-common";
import {Dialog, DialogActions, DialogTitle, DialogContent, Grid, List, ListItem, ListItemText, ListItemIcon, TextField} from "@material-ui/core";
import {memo} from "react";
import { useDocumentUploadStyles } from "./document-upload.style";
import Alert from "@material-ui/lab/Alert";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ClearIcon from '@material-ui/icons/Clear';
import { nameLengthValidator } from "modules/shared/utility/file-extension";
import WarningIcon from '@material-ui/icons/Warning';
import { DocumentUploadEvents, DocumentUploadMeta } from "./document-upload.interface";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { CANCEL_DOCUMENT_UPLOAD, PREPARE_FOR_UPLOAD } from "modules/doctor-dashboard/store/document-list/document-list.actions";
import Dropzone, {DropEvent, FileRejection} from 'react-dropzone';

const DocumentUploadTemplate = memo((props: DocumentUploadMeta) => {
    const MAX_FILE_SIZE = 5242880;
    const MAX_NUMBER_OF_DOCUMENTS_AT_ONCE = 10;
    const classes = useModalWindowStyle();
    const docUploadClasses = useDocumentUploadStyles();

    const [validationError, setValidationError] = useState<null | string>(null);
    const [description, setDescription] = useState("");
    const [myFiles, setMyFiles] = React.useState<File[]>([]);
    const [myRejectedFiles, setMyRejectedFiles] = React.useState<FileRejection[]>([]);

    const onDrop = React.useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
            if(myFiles.length + acceptedFiles.length > MAX_NUMBER_OF_DOCUMENTS_AT_ONCE)
                setValidationError("Too many files. You can upload a maximum of " + MAX_NUMBER_OF_DOCUMENTS_AT_ONCE + " documents at once.");
            setMyFiles([...myFiles, ...acceptedFiles]);
            setMyRejectedFiles([...myRejectedFiles, ...fileRejections]);
        },
        [myFiles, myRejectedFiles]
    );

    const handleSubmit = () => {
        props.onPrepareDocumentUpload(myFiles, description);
        resetLocalState();
    };

    const handleClose = () => {
        props.onCancelDocumentUpload();
        resetLocalState();
    };

    const resetLocalState = () => {
        setDescription('');
        setValidationError(null);
        setMyFiles([]);
        setMyRejectedFiles([]);
    }

    const handleDescriptionChange = (x: any) => {
        if(x && x.target && x.target.value) {
            if(x.target.value.length > 500)
                setValidationError("Description cannot be more than 500 characters in length.");
            else {
                setValidationError(null);
                setDescription(x.target.value);
            }
        }
    }

    const removeFile = (filename: string) => {
        const newFiles = [...myFiles]
        const fileIndex = newFiles.findIndex(x => x.name === filename);
        newFiles.splice(fileIndex, 1);
        setMyFiles(newFiles);
        if(newFiles.length <= MAX_NUMBER_OF_DOCUMENTS_AT_ONCE)
            setValidationError(null);
    }

    const removeRejectedFile = (filename: string) => {
        const newRejectedFiles = [...myRejectedFiles]
        const fileIndex = myRejectedFiles.findIndex(x => x.file.name === filename);
        newRejectedFiles.splice(fileIndex, 1);
        setMyRejectedFiles(newRejectedFiles);
    }
    
    return (<Dialog
                fullWidth={true}
                open={props.open}
                classes = {{paper: classes.dialog}}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Upload documents (pdf) for {props.name}</DialogTitle>
                    <DialogContent>
                        <div className={docUploadClasses.margin} id="document-uploads-grid">
                            {validationError && <Alert variant="outlined" severity="error" className={docUploadClasses.margin}>{validationError}</Alert>}
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={12}>
                                    <Dropzone onDrop={onDrop} maxSize={MAX_FILE_SIZE}
                                        validator={nameLengthValidator} accept={{ 'application/pdf': [".pdf"] }}>
                                        {({getRootProps, getInputProps, isDragActive}) => (
                                        <div {...getRootProps({className: docUploadClasses.baseStyle })}>
                                            <input {...getInputProps()} />
                                            {isDragActive ? (
                                                <p>                        Drop the files here ...                         </p>
                                            ) : (
                                                <p>Drag 'n' drop medical reference documents here, or click to select files</p>
                                            )}
                                        </div>)}
                                    </Dropzone>
                                </Grid>
                                {myFiles && myFiles.length !== 0 &&  
                                <Grid item xs={12}>
                                    <div>
                                        <List dense={false}>
                                            {myFiles.map((file, index) => (
                                                <ListItem key={index}>
                                                    <ListItemIcon>
                                                        <PictureAsPdfIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={file.name} />
                                                    <ListItemIcon className={docUploadClasses.clickable} onClick={() => removeFile(file.name)}>
                                                        <ClearIcon fontSize="small" />
                                                    </ListItemIcon>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                </Grid>}
                                {myRejectedFiles && myRejectedFiles.length !== 0 && <Grid item xs={12}>
                                    <div>
                                        <List dense={false}>
                                            {myRejectedFiles.map((rejection: FileRejection, index: number) => (
                                                <ListItem key={index}>
                                                    <ListItemIcon>
                                                        <WarningIcon color="error" fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={rejection.file.name} />
                                                    <ul>
                                                        {rejection.errors.map(e => <li key={e.code}>{e.message}</li>)}
                                                    </ul>
                                                    <ListItemIcon className={docUploadClasses.clickable} onClick={() => removeRejectedFile(rejection.file.name)}>
                                                        <ClearIcon fontSize="small" />
                                                    </ListItemIcon>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                </Grid>}
                                <Grid item xs={12}>
                                    {myFiles && myFiles.length !== 0 && <TextField
                                        fullWidth
                                        multiline={true}
                                        id="description"
                                        name="description"
                                        label="Description"
                                        onChange={handleDescriptionChange}
                                        />}
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <ModalWindowSubmitButton onSubmit={handleSubmit}
                        content={"Upload"} disabled={!(myFiles && myFiles.length !== 0) || (validationError !== null)} />
                    <ModalWindowCancelButton onCancel={handleClose}
                        content={"Close"} disabled={false} />
                </DialogActions>
            </Dialog>)
});


const mapDispatchToProps = (dispatch: Dispatch): DocumentUploadEvents => {
    return bindActionCreators({
        onCancelDocumentUpload: CANCEL_DOCUMENT_UPLOAD,
        onPrepareDocumentUpload: PREPARE_FOR_UPLOAD
    }, dispatch);
};

export const DocumentUpload = memo(connect(null,mapDispatchToProps)(DocumentUploadTemplate));