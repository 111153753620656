import {all, call, delay, put, select, spawn, takeEvery, takeLatest} from "redux-saga/effects";

import {push} from "connected-react-router";
import log from 'loglevel';
import {
    DOCTORS_DASHBOARD_LOGIN_COMPLETE,
    DOCTORS_DASHBOARD_LOGIN_FAILED,
    DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE,
    DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE_FAILED,
    DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE_SUCCESSFULLY,
    DOCTORS_DASHBOARD_LOGIN_SUCCESSFULLY,
    DOCTORS_DASHBOARD_LOGOUT_FAILED,
    DOCTORS_DASHBOARD_LOGOUT_SUCCESSFULLY,
    DoctorsDashboardAuthActions,
    DOCTORS_DASHBOARD_RESET_LAST_ACTIVE_TIME,
    DOCTORS_DASHBOARD_LOGOUT_CANCELLED
} from "./doctors-dashboard-auth-actions";
import {signInProviders} from "../doctors-dashboard-azure-b2c-credentials";
import {AuthResponse, InteractionRequiredAuthError} from "msal";
import {ENQUEUE_SNACKBAR, OperationErrorPipe, OperationErrorType, StoreAction} from "orpyx-web-common";
import {DoctorsDashboardAuthSelectors} from "./doctors-dashboard-auth-selectors";
import {DoctorsDashboardDashboardUserProfileHttpClient, IGetDashboardUserProfileResponse, DashboardUserType} from "doctors-dashboard/http-clients/index";
import {DoctorsDashboardAzureActor} from "../models/doctors-dashboard-azure-token";
import {ORPYXSI_API_URL} from "../../../../appConstants";
import DoctorsDashboardAzureTokenStorage from "./actor/doctors-dashboard-azure-token-storage";
import DoctorsDashboardActorStorage from "./actor/doctors-dashboard-actor-storage";
import moment from "moment";
import { PatientsListActions } from "../../patients/patients-list/store/patients-list.actions";
import { SET_PATIENT_SCRATCH_PAD_UNLOAD_LISTENER } from "modules/doctor-dashboard/patients/patient-information/patient-information-page/store/scratch-pad-notes/scratch-pad-notes.actions";

const API_VERSION = '1';
const OFFLINE_ACCESS_SCOPE = 'offline_access';
const REDIRECT_URI = encodeURI(origin + '/redirecturi');
// see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/docs/errors.md#token-renewal-operation-failed-due-to-timeout
// and https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-avoid-page-reloads#specify-different-html-for-the-iframe

function* authorize() {
    try {
        yield signInProviders.signIn.agentApp.loginPopup({
            scopes: signInProviders.signIn.SCOPES.login,
            redirectUri: REDIRECT_URI,
            forceRefresh: true,

            //  See more in: https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-prompt-behavior
            prompt: 'login',
        });

        const authResponse: AuthResponse = yield signInProviders.signIn.agentApp.acquireTokenSilent({
            scopes: [signInProviders.signIn.SCOPES.acquireToken, OFFLINE_ACCESS_SCOPE],
            redirectUri: REDIRECT_URI,
            forceRefresh: true
        });

        const actor: DoctorsDashboardAzureActor = {
            type: DashboardUserType.DashboardUser,
            accountIdentifier: authResponse.account.accountIdentifier,
            name: authResponse.account.name,
            accessToken: {
                token: authResponse.accessToken,
                expiredAt: authResponse.expiresOn
            },
        };

        yield put(DOCTORS_DASHBOARD_LOGIN_SUCCESSFULLY(actor));
        yield put(DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE());
    } catch (error) {
        yield put(DOCTORS_DASHBOARD_LOGIN_FAILED(error));
    }
}

function* onFetchOrpyxUserRoles() {
    try {
        const api = new DoctorsDashboardDashboardUserProfileHttpClient(ORPYXSI_API_URL);
        const data: IGetDashboardUserProfileResponse = yield call(
            [api, api.getDashboardUserProfile],
            API_VERSION
        );

        yield put(DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE_SUCCESSFULLY(data));
        yield put(DOCTORS_DASHBOARD_LOGIN_COMPLETE());
    } catch (e) {
        yield put(DOCTORS_DASHBOARD_LOGIN_FETCH_USER_PROFILE_FAILED(e));
    }
}

function* tryAcquireToken() {
    try {
        const response: AuthResponse = yield signInProviders.signIn.agentApp.acquireTokenSilent({
            scopes: [signInProviders.signIn.SCOPES.acquireToken, OFFLINE_ACCESS_SCOPE],
            redirectUri: REDIRECT_URI,
            forceRefresh: true
        });
        return response;
    } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
            try {
                const response: AuthResponse = yield signInProviders.signIn.agentApp.acquireTokenPopup({
                    scopes: [signInProviders.signIn.SCOPES.acquireToken, OFFLINE_ACCESS_SCOPE],
                    redirectUri: REDIRECT_URI
                });
                return response;
            }
            catch(ee) {
                yield onLogout();
            }
        } 
        return undefined; // return undefined to allow retry
    }
}

function* onProcessRefreshToken() {
    const azureActor = (yield select(DoctorsDashboardAuthSelectors.azureActor)) as DoctorsDashboardAzureActor;
    if (!azureActor || azureActor.type === DashboardUserType.RemotePatientMonitoringUser) {
        return;
    }

    const response: AuthResponse | undefined = yield tryAcquireToken();
    if (response == undefined) {
        return; 
    }

    if (response && response.accessToken && response.expiresOn) {
        const actor: DoctorsDashboardAzureActor = {
            type: DashboardUserType.DashboardUser,
            name: azureActor.name,
            accountIdentifier: azureActor.accountIdentifier,
            accessToken: {
                token: response.accessToken,
                expiredAt: response.expiresOn
            }
        };

        yield put(DOCTORS_DASHBOARD_LOGIN_SUCCESSFULLY(actor));
    } 
}

function* onCheckIfUserIsActive() {
    while (true) {
        const timeoutInMinutes = 60;  // user will time out of session after this amount of minutes
        const renewAccessTokenWindowInMinutes = 5; // renew token, if the token will expire with this amount of minutes
        const delayInMinutes = 1; // delay these amount of minutes before rechecking for Active user

        try {
            yield delay(delayInMinutes * 60 * 1000);
            const azureActor = (yield select(DoctorsDashboardAuthSelectors.azureActor)) as DoctorsDashboardAzureActor;
            if (!azureActor || azureActor.type === DashboardUserType.RemotePatientMonitoringUser) {
                continue;
            }
            const lastActiveDate = (yield select(DoctorsDashboardAuthSelectors.lastActive)) as Date;
            const lastActive = moment(lastActiveDate);
            const now = moment();

            const inactiveDurationInSecs = now.diff(lastActive, "seconds");
            if (inactiveDurationInSecs >= (timeoutInMinutes * 60)) {
                yield onLogout();
                yield put(ENQUEUE_SNACKBAR({
                    message: `Logout due to inactivity`
                }));
            } 
            else {
                const tokenExpiry = moment(azureActor.accessToken.expiredAt);
                const nextDate = now.add(renewAccessTokenWindowInMinutes, "minute");
                if (tokenExpiry <= nextDate) {
                    yield onProcessRefreshToken();
                }
            }
        } catch(e) {
            console.log("onCheckIfUserIsActive exception", e);
        }
    }
}

function onFetchOrpyxUserRolesSuccess(action: StoreAction<DoctorsDashboardAuthActions, IGetDashboardUserProfileResponse>) {
    DoctorsDashboardActorStorage.saveActorProfile(action.payload!);
}

function* onLogout() {
    try {
        const logoutRequiresConfirmation = yield select(DoctorsDashboardAuthSelectors.logoutRequiresConfirmation);
        if (logoutRequiresConfirmation) {
            if (!window.confirm('Changes you made may not be saved.')) {
                yield put(DOCTORS_DASHBOARD_LOGOUT_CANCELLED());
                yield put(SET_PATIENT_SCRATCH_PAD_UNLOAD_LISTENER(true));
                return;
            }
        }

        // Clear event set by scratch pad note component so avoid double prompt on logout
        // TODO: Figure out a way to contain this in the scratch pad note component instead of here
        window.onbeforeunload = null;
        //  Reset cache & state, then process logout
        yield put(DOCTORS_DASHBOARD_LOGOUT_SUCCESSFULLY());

        yield signInProviders.signIn.agentApp.logout();
    } catch (e) {
        yield put(DOCTORS_DASHBOARD_LOGOUT_FAILED(e));
    }
}

function* onLogoutComplete() {
    DoctorsDashboardAzureTokenStorage.resetActor();
    DoctorsDashboardActorStorage.resetActorProfile();

    yield put(push("/"));
}

function onLogoutFailed(action: StoreAction<DoctorsDashboardAuthActions, OperationErrorType>) {
    log.error(`onLogoutFailed: ${OperationErrorPipe(action.payload)}`);
}

function onLoginSuccess(action: StoreAction<DoctorsDashboardAuthActions, DoctorsDashboardAzureActor>) {
    const actor = action.payload!;
    DoctorsDashboardAzureTokenStorage.saveActor(actor);
}

function* onLoginComplete() {
    yield put(push("/patients/Active"));
    yield put(ENQUEUE_SNACKBAR({
        message: `Welcome to Orpyx!`,
        options: {variant: 'success'}
    }));

}

function* onLoginFailed(action: StoreAction<DoctorsDashboardAuthActions, OperationErrorType>) {
    DoctorsDashboardAzureTokenStorage.resetActor();
    DoctorsDashboardActorStorage.resetActorProfile();

    yield put(ENQUEUE_SNACKBAR({
        message: `Login failed. ${OperationErrorPipe(action.payload)}`,
        options: {variant: 'error'}
    }));

    yield put(push("/"));
}

function* resetLastActiveTime() {
    yield put(DOCTORS_DASHBOARD_RESET_LAST_ACTIVE_TIME());
}

export function* DoctorsDashboardAuthSagas() {
    yield all([
        spawn(onCheckIfUserIsActive),
        takeEvery(DoctorsDashboardAuthActions.LOGIN, authorize),
        takeEvery(DoctorsDashboardAuthActions.LOGIN_SUCCESSFULLY, onLoginSuccess),
        takeEvery(DoctorsDashboardAuthActions.LOGIN_COMPLETE, onLoginComplete),
        takeEvery(DoctorsDashboardAuthActions.LOGIN_FAILED, onLoginFailed),

        takeEvery(DoctorsDashboardAuthActions.FETCH_USER_PROFILE, onFetchOrpyxUserRoles),
        takeEvery(DoctorsDashboardAuthActions.FETCH_USER_PROFILE_SUCCESSFULLY, onFetchOrpyxUserRolesSuccess),
        takeEvery(DoctorsDashboardAuthActions.FETCH_USER_PROFILE_FAILED, onLoginFailed),

        takeEvery(DoctorsDashboardAuthActions.LOGOUT, onLogout),
        takeEvery(DoctorsDashboardAuthActions.LOGOUT_SUCCESSFULLY, onLogoutComplete),
        takeEvery(DoctorsDashboardAuthActions.LOGOUT_FAILED, onLogoutComplete),
        takeEvery(DoctorsDashboardAuthActions.LOGOUT_FAILED, onLogoutFailed),

        takeLatest(PatientsListActions.FETCH_PATIENTS_LIST_SUCCESSFULLY, resetLastActiveTime),
        takeLatest(PatientsListActions.SELECT_PATIENT, resetLastActiveTime)
    ]);
}
