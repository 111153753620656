import {isNullOrUndefined} from "orpyx-web-common";
import {pad} from "./string-pad";

export class TimeSpan {
    static readonly parse = (value: string, limit?: number): number[] => {
        const values = value!.split(':', limit);
        const result = values.map(q => parseInt(q, 10));
        return result;
    };

    static readonly fromMinutesAndSecondsToSeconds = (value: string | null | undefined, defaultValue?: number | undefined): number | undefined => {
        if (isNullOrUndefined(value))
            return defaultValue;

        const [minutes, seconds] = TimeSpan.parse(value!);
        const result = (seconds ?? 0) + minutes * 60;
        return result;
    };

    static readonly toMinutesAndSeconds = (value: number | null | undefined, defaultValue: string = '__:__'): string => {
        if (isNullOrUndefined(value)) {
            return defaultValue;
        }

        const minutes = Math.floor(value! / 60.0);
        const seconds = value! % 60;

        return `${pad(minutes, 2)}:${pad(seconds, 2)}`;
    };

    static readonly fromHoursAndMinutesAndSecondsToSeconds = (value: string | null | undefined, defaultValue?: number | undefined): number | undefined => {
        if (isNullOrUndefined(value))
            return defaultValue;
        var values = TimeSpan.parse(value!);
        if (values.length == 1) { // minutes only
            const v:number[] = [];
            values = v.concat([0], values, [0]);
        }
        else if (values.length == 2) { // minutes and seconds
            const v:number[] = [];
            values = v.concat([0], values);  // hour is 0               
        }
        const [hour, minutes, seconds] = values;
        const result = (seconds ?? 0) + minutes * 60 + hour * 3600;
        return result;
    };

    static readonly toHoursAndMinutesAndSeconds = (value: number | null | undefined, defaultValue: string = '__:__:__'): string => {
        if (isNullOrUndefined(value)) {
            return defaultValue;
        }

        const hours = Math.floor(value! / 3600.0);
        const minutes = Math.floor(value! / 60.0 % 60);
        const seconds = value! % 60;

        return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`;
    };
}
