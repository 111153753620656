import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

export const useDoctorsDashboardNavbarLoginStyle = makeStyles((theme: Theme) => createStyles({
    buttonRoot: {
        width: '170px',
        height: '50px',
        marginLeft: '20px',
        '& span': {
            color: '#FFF',
        },

    },
}));
