import {TableCell, TableRow} from "@material-ui/core";
import {PatientsListActionRequiredAlerts} from "../patients-list-action-required-alerts/patients-list-action-required-alerts";
import {DateTimePipe, fullNamePipe} from "orpyx-web-common";
import * as React from "react";
import {memo} from "react";
import {PatientsListTableItemProps} from "./patients-list-table-item.interface";

export const PatientsListTableItem = memo((props: PatientsListTableItemProps) => {
    const patient = props.patient;

    return (
        <TableRow
            key={patient.patientId}
            onClick={() => props.onPatientSelected(patient)}
            hover
        >
            {props.canDisplayActionRequiredAlerts && <TableCell>
                <PatientsListActionRequiredAlerts alerts={patient.actionRequiredAlerts}/>
            </TableCell>}
            <TableCell>{fullNamePipe(patient)}</TableCell>
            <TableCell>{patient.clientPatientIdentifier}</TableCell>
            {props.canDisplayDateOfPatientAuthorization && <TableCell>{DateTimePipe(patient.allowAccessStartDate)}</TableCell>}

            {props.canDisplayDaysSinceLastReview && <TableCell>{patient.daysSinceLastReview}</TableCell>}
            {props.canDisplayAllowAccessEndDate && <TableCell>{DateTimePipe(patient.allowAccessEndDate)}</TableCell>}

            {props.canDisplayInsoleSerialNumbers && <>
                <TableCell>
                    {patient.product?.leftInsoleSerialNumber}
                </TableCell>
                <TableCell>
                    {patient.product?.rightInsoleSerialNumber}
                </TableCell>
            </>}
        </TableRow>
    );
});