import React, { memo } from "react";
import { ModalWindowHeader, ModalWindowSubmitButton, ModalWindowCancelButton, useModalWindowStyle} from "orpyx-web-common";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useDocumentUploadStyles } from "../patient-document-uploads/document-upload.style";
import { CANCEL_DOCUMENT_UPLOAD, UPLOAD_DOCUMENTS } from "modules/doctor-dashboard/store/document-list/document-list.actions";
import { DocumentUploadConfirmationModalListEvents, DocumentUploadConfirmationModalMeta } from "./document-upload-confirmation.interface";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const DocumentUploadConfirmationModalTemplate = (props: DocumentUploadConfirmationModalMeta) => {
    const docUploadClasses = useDocumentUploadStyles();
    const classes = useModalWindowStyle();
    
    return (
        <Dialog
            fullWidth={true}
            open={props.open}
            classes = {{paper: classes.dialog}}
            onClose={props.onCancelDocumentUpload}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Confirm Document Upload</DialogTitle>

            <DialogContent>
                <div className={docUploadClasses.margin}>
                    <Grid container spacing={1} alignItems="flex-end">
                        <Grid item xs={12}>
                            Are you sure you want to attach the following document(s) to {props.name}?
                        </Grid>
                        {props.files && props.files.length !== 0 && <Grid item xs={12}>
                            <div>
                                <List dense={false}>
                                    {props.files.map(file => (
                                        <ListItem key={file.name}>
                                            <ListItemIcon>
                                                <PictureAsPdfIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary={file.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                        </Grid>}
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
                <ModalWindowSubmitButton onSubmit={() => props.onUploadDocuments(props.category, props.associatedId, props.files, props.files.map(x => x.name), props.description)}
                    content={"Confirm"} disabled={false} />
                <ModalWindowCancelButton onCancel={props.onCancelDocumentUpload}
                    content={"Cancel"} disabled={false} />
            </DialogActions>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch: Dispatch): DocumentUploadConfirmationModalListEvents => {
    return bindActionCreators({
        onUploadDocuments: UPLOAD_DOCUMENTS,
        onCancelDocumentUpload: CANCEL_DOCUMENT_UPLOAD
    }, dispatch);
};

export const DocumentUploadConfirmationModal = memo(connect(null,mapDispatchToProps)(DocumentUploadConfirmationModalTemplate));