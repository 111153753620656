import {createListSearchFilterByItem, ListSearchFilterByItem} from "orpyx-web-common";
import {PatientFilterBy} from "doctors-dashboard/http-clients/index";

export const PatientsListFilterByPipe = (filterBy: PatientFilterBy): string => {
    switch (filterBy) {
        case PatientFilterBy.InsoleSerialNumbers:
            return 'Insole Serial Numbers';
        case PatientFilterBy.Name:
            return 'Name';
        case PatientFilterBy.PatientId:
            return 'Patient ID';
    }
    return `Unknown: ${filterBy}`;
};

export const PatientsListFilterByPipeItems: ListSearchFilterByItem[] = [
    createListSearchFilterByItem<PatientFilterBy>(PatientFilterBy.Name, PatientsListFilterByPipe),
    createListSearchFilterByItem<PatientFilterBy>(PatientFilterBy.PatientId, PatientsListFilterByPipe),
    createListSearchFilterByItem<PatientFilterBy>(PatientFilterBy.InsoleSerialNumbers, PatientsListFilterByPipe),
];

