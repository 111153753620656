import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";


import {PatientActivityLogEvents, PatientActivityLogMeta, PatientActivityLogOwnProps, PatientActivityLogProps} from "./patient-activity-log.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {PatientActivityLogTable} from "../patient-activity-log-table/patient-activity-log-table";
import {OperationProgress} from "orpyx-web-common";
import {PatientActivityLogSelectors} from "../../store/patient-activity-log.selectors";
import {DOWNLOAD_PATIENT_CARE_NOTE_REPORT, GET_PATIENT_CARE_NOTES} from "../../store/patient-activity-log.actions";
import {Button} from "@material-ui/core";
import {usePatientActivityLogTableStyle} from "../patient-activity-log-table/patient-activity-log-table.style";


const PatientActivityLogTemplate = (props: PatientActivityLogMeta) => {
    const classes = usePatientActivityLogTableStyle();

    return (
        <>
            <PatientActivityLogTable
                onClickedDownloadPatientActivityLog={props.onClickedDownloadPatientActivityLog}
                careNotes={props.careNotes.list}
                inProcess={props.careNotes.inProcess}
            />

            <OperationProgress inProcess={props.loading} error={props.error}/>

            {props.careNotes.canGetMore && <Button
                variant="contained"
                color="primary"
                className={classes.loadMoreNotesButton}
                onClick={() => props.onClickedLoadMore()}
                disabled={props.loading}
                type="button"
            >
                Load more notes
            </Button>}
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientActivityLogOwnProps => {
    return {
        loading: PatientActivityLogSelectors.loading(state),
        error: PatientActivityLogSelectors.error(state),

        careNotes: {
            list: PatientActivityLogSelectors.careNotes(state),
            canGetMore: PatientActivityLogSelectors.canGetMoreCareNotes(state),
            inProcess: PatientActivityLogSelectors.inProcess(state),
        }
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientActivityLogProps): PatientActivityLogEvents => {
    return bindActionCreators({
        onClickedLoadMore: GET_PATIENT_CARE_NOTES(true),
        onClickedDownloadPatientActivityLog: DOWNLOAD_PATIENT_CARE_NOTE_REPORT,
    }, dispatch);
};

export const PatientActivityLog = memo(connect(mapStateToProps, mapDispatchToProps)(PatientActivityLogTemplate));

