import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {PatientsListTableEvents, PatientsListTableMeta, PatientsListTableOwnProps, PatientsListTableProps} from "./patients-list-table.interface";
import {TableBody} from "@material-ui/core";
import {IOrpyxApplicationStore} from "../../../../../store/application.reducers";
import {PatientsListSelectors} from "../../store/patients-list.selectors";
import {OperationProgress, OperationSkeleton} from "orpyx-web-common";
import {PatientFilterType, IGetPatientsListResponse} from "doctors-dashboard/http-clients/index";
import {PatientsListTableHeader} from "../patients-list-table-header/patients-list-table-header";
import {PatientsListTableItem} from "../patients-list-table-item/patients-list-table-item";
import {PATIENTS_LIST_SELECT_PATIENT} from "../../store/patients-list.actions";
import Table from "@material-ui/core/Table";
import {usePatientsListTableStyle} from "./patients-list-table.style";

const PatientsListTableTemplate = memo((props: PatientsListTableMeta) => {
    const classes = usePatientsListTableStyle();

    const canDisplayDaysSinceLastReview = props.patientType === PatientFilterType.Active;
    const canDisplayAllowAccessEndDate = props.patientType === PatientFilterType.InactivePatients;
    const canDisplayActionRequiredAlerts = props.patientType === PatientFilterType.Active;
    const canDisplayInsoleSerialNumbers = props.patientType === PatientFilterType.Active;
    const canDisplayDateOfPatientAuthorization = props.patientType !== PatientFilterType.NewPatients;

    if (props.loading || props.error) {
        return <>
            <OperationProgress inProcess={props.loading} error={props.error}/>
            <OperationSkeleton height={60} rows={8}/>
        </>;
    }

    const onPatientSelected = (patient: IGetPatientsListResponse | undefined) => {
        if (patient && props.patientType === PatientFilterType.Active) {
            props.onPatientSelected(patient);
        }
    };

    return (<Table id="patient-list-table">
            <PatientsListTableHeader
                classes={classes}
                canDisplayDaysSinceLastReview={canDisplayDaysSinceLastReview}
                canDisplayAllowAccessEndDate={canDisplayAllowAccessEndDate}
                canDisplayActionRequiredAlerts={canDisplayActionRequiredAlerts}
                canDisplayDateOfPatientAuthorization={canDisplayDateOfPatientAuthorization}
                canDisplayInsoleSerialNumbers={canDisplayInsoleSerialNumbers}
            />

            <TableBody>
                {props.patients.map(patient =>
                    <PatientsListTableItem
                        key={patient.patientId}
                        canDisplayDaysSinceLastReview={canDisplayDaysSinceLastReview}
                        canDisplayAllowAccessEndDate={canDisplayAllowAccessEndDate}
                        canDisplayActionRequiredAlerts={canDisplayActionRequiredAlerts}
                        canDisplayDateOfPatientAuthorization={canDisplayDateOfPatientAuthorization}
                        canDisplayInsoleSerialNumbers={canDisplayInsoleSerialNumbers}
                        onPatientSelected={onPatientSelected}
                        patient={patient}
                    />)}
            </TableBody>
        </Table>
    );
});

const mapStateToProps = (state: IOrpyxApplicationStore): PatientsListTableOwnProps => {
    return {
        patients: PatientsListSelectors.patients(state),

        loading: PatientsListSelectors.loading(state),
        error: PatientsListSelectors.error(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientsListTableProps): PatientsListTableEvents => {
    return bindActionCreators({
        onPatientSelected: PATIENTS_LIST_SELECT_PATIENT
    }, dispatch);
};

export const PatientsListTable = memo(connect(mapStateToProps, mapDispatchToProps)(PatientsListTableTemplate));

