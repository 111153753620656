import {all, put, select, takeEvery} from "redux-saga/effects";
import PatientInformationPageSagas from "../patient-information-page/store/patient-information-page.sagas";
import PatientInformationActionRequiredAlertsSagas from "../patient-action-required-alerts/store/patient-information-action-required-alerts.sagas";
import PatientSubmitCareNoteSagas from "../patient-submit-care-note/store/patient-submit-care-note.sagas";
import PatientTemperatureSagas from "../patient-temperature/store/patient-temperature.sagas";
import PatientStatisticSagas from "../patient-statistic/store/patient-statistic.sagas";
import PatientActivityLogSagas from "../patient-activity-log/store/patient-activity-log.sagas";
import {GET_PATIENT_ACTION_REQUIRED_ALERTS, RESET_PATIENT_ACTION_REQUIRED_ALERTS} from "../patient-action-required-alerts/store/patient-information-action-required-alerts.actions";
import {PatientInformationPageActions} from "../patient-information-page/store/patient-information-page.actions";
import {APPEND_PATIENT_CARE_NOTE, GET_PATIENT_CARE_NOTES, RESET_PATIENT_CARE_NOTES} from "../patient-activity-log/store/patient-activity-log.actions";
import {GET_PATIENT_STATISTIC, RESET_PATIENT_STATISTIC} from "../patient-statistic/store/patient-statistic.actions";
import {GET_PATIENT_TEMPERATURE_SUMMARY, RESET_PATIENT_TEMPERATURE_SUMMARY} from "../patient-temperature/store/patient-temperature.actions";
import {CarePractitionerActionSelectors} from "../../../care-practitioner-action/store/care-practitioner-action.selectors";
import {FETCH_CARE_PRACTITIONER_ACTIONS} from "../../../care-practitioner-action/store/care-practitioner-action.actions";
import {PatientSubmitCareNoteActions} from "../patient-submit-care-note/store/patient-submit-care-note.actions";
import {StoreAction} from "orpyx-web-common";
import {IListCareNotesResultEntry} from "doctors-dashboard/http-clients/index";
import PatientMonthlyReportsSagas from "../patient-monthly-reports/store/patient-monthly-reports.sagas";
import {GET_PATIENT_MONTHLY_REPORTS, RESET_PATIENT_MONTHLY_REPORTS} from "../patient-monthly-reports/store/patient-monthly-reports.actions";
import PatientStepCountSagas from "../patient-step-count/store/patient-step-count.sagas";
import {GET_PATIENT_STEP_COUNT_SUMMARY, RESET_PATIENT_STEP_COUNT_SUMMARY} from "../patient-step-count/store/patient-step-count.actions";
import { LIST_DOCUMENTS, RESET_DOCUMENTS } from "modules/doctor-dashboard/store/document-list/document-list.actions";
import DocumentListSagas from "modules/doctor-dashboard/store/document-list/document-list.saga";

function* onBeginPatientInformationLoad() {
    yield all([
        put(RESET_PATIENT_ACTION_REQUIRED_ALERTS()),
        put(RESET_PATIENT_CARE_NOTES()),
        put(RESET_PATIENT_MONTHLY_REPORTS()),
        put(RESET_PATIENT_STATISTIC()),
        put(RESET_PATIENT_TEMPERATURE_SUMMARY()),
        put(RESET_PATIENT_STEP_COUNT_SUMMARY()),
        put(RESET_DOCUMENTS())
    ]);
}

function* onPatientInformationLoaded() {
    yield all([
        put(GET_PATIENT_ACTION_REQUIRED_ALERTS()),
        put(GET_PATIENT_CARE_NOTES(false)()),
        put(GET_PATIENT_MONTHLY_REPORTS(false)()),
        put(GET_PATIENT_STATISTIC()),
        put(GET_PATIENT_TEMPERATURE_SUMMARY()),
        put(GET_PATIENT_STEP_COUNT_SUMMARY())
    ]);
}

function* LoadCarePractitionerActions() {
    const initialized = yield select(CarePractitionerActionSelectors.initialized);
    if(!initialized){
        yield put(FETCH_CARE_PRACTITIONER_ACTIONS());
    }
}

function* onSuccessfullySubmitCareNote(action: StoreAction<PatientSubmitCareNoteActions, IListCareNotesResultEntry>) {
    yield all([
        put(RESET_PATIENT_ACTION_REQUIRED_ALERTS()),
        put(APPEND_PATIENT_CARE_NOTE(action.payload!)),
    ]);
}

export default function* PatientInformationSagas() {
    yield all([
        PatientInformationPageSagas(),
        PatientInformationActionRequiredAlertsSagas(),
        PatientSubmitCareNoteSagas(),
        PatientTemperatureSagas(),
        PatientStatisticSagas(),
        PatientActivityLogSagas(),
        PatientMonthlyReportsSagas(),
        PatientStepCountSagas(),
        DocumentListSagas(),

        takeEvery(PatientInformationPageActions.GET_PATIENT_INFORMATION_SUCCESSFULLY, LoadCarePractitionerActions),
        takeEvery(PatientInformationPageActions.GET_PATIENT_INFORMATION_SUCCESSFULLY, onPatientInformationLoaded),
        takeEvery(PatientInformationPageActions.GET_PATIENT_INFORMATION, onBeginPatientInformationLoad),

        takeEvery(PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE_SUCCESSFULLY, onSuccessfullySubmitCareNote),
    ]);
}
