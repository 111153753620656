import {createSelector} from "reselect";
import {getPatientsState} from "../../../store/patients.selectors";

export class PatientMonthlyReportsSelectors {
    static readonly state = createSelector(getPatientsState, state => state.information.monthlyReports);

    static readonly initialized = createSelector(PatientMonthlyReportsSelectors.state, state => state.initialized);
    static readonly loading = createSelector(PatientMonthlyReportsSelectors.state, state => state.loading);
    static readonly error = createSelector(PatientMonthlyReportsSelectors.state, state => state.error);

    static readonly inProcess = createSelector(PatientMonthlyReportsSelectors.state, state => state.monthlyReports.inProcess);

    static readonly monthlyReports = createSelector(PatientMonthlyReportsSelectors.state, state => state.monthlyReports.list);
    static readonly lastRowMarker = createSelector(PatientMonthlyReportsSelectors.state, state => state.monthlyReports.lastRowMarker);
    static readonly canGetMoreCareNotes = createSelector(PatientMonthlyReportsSelectors.state, state => state.monthlyReports.canGetMore);
    static readonly takeCount = createSelector(PatientMonthlyReportsSelectors.state, state => state.monthlyReports.takeCount);
}