import {PatientTemperatureSensors} from "../../data/enums/patient-temperature-sensors";
import * as React from "react";
import {memo} from "react";
import {usePatientTemperatureComparisonSensorsLegendStyle} from "./patient-temperature-comparison-sensors-legend.style";

export const PatientTemperatureComparisonSensorsLegend = memo(() => {
    const classes = usePatientTemperatureComparisonSensorsLegendStyle();

    const legendStyle = (color: string) => ({
        backgroundColor: color
    });

    return (<ul className={classes.listOfLabels}>
        {PatientTemperatureSensors.map(sensor =>
            <li key={sensor.value}>
                <span
                    className={classes.sensor}
                    style={legendStyle(sensor.color!)}
                />
                {sensor.name}
            </li>
        )}
    </ul>)
});