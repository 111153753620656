import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core";
import {Black} from "orpyx-web-common";

export const usePatientInformationTitleStyle = makeStyles((theme: Theme) => createStyles({
    titleStyle: {
        color: Black,
        margin: '20px 0 10px 30px',
        fontSize: '20px',
    },
}));