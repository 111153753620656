import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IActionRequiredAlertsEntry} from "doctors-dashboard/http-clients/index";
import produce from "immer";
import {PatientInformationActionRequiredAlertsActions} from "./patient-information-action-required-alerts.actions";
import {SelectedActionRequiredAlerts} from "./patient-information-action-required-alerts.types";

export interface PatientInformationActionRequiredAlertsState {
    loading: boolean;
    error: OperationErrorType;

    alerts: IActionRequiredAlertsEntry[];
    selected: SelectedActionRequiredAlerts,
}

const initialState: PatientInformationActionRequiredAlertsState = {
    loading: false,
    error: undefined,

    alerts: [],
    selected: {},
};

const PatientInformationActionRequiredAlertsReducers = (state = initialState, action: StoreAction<PatientInformationActionRequiredAlertsActions>): PatientInformationActionRequiredAlertsState => {
    return produce(state, (draft: PatientInformationActionRequiredAlertsState) => {
        switch (action.type) {
            case PatientInformationActionRequiredAlertsActions.SELECT_ACTION_REQUIRED_ALERT:
                const requiredActionAlertId: number = action.payload!;

                const selected: boolean | undefined = draft.selected[requiredActionAlertId];
                if (selected) {
                    delete draft.selected[requiredActionAlertId];
                } else {
                    draft.selected[requiredActionAlertId] = true;
                }
                break;

            case PatientInformationActionRequiredAlertsActions.RESET_PATIENT_ACTION_REQUIRED_ALERTS:
                draft.loading = false;
                draft.error = undefined;
                draft.selected = {};
                draft.alerts = [];
                break;

            case PatientInformationActionRequiredAlertsActions.GET_PATIENT_ACTION_REQUIRED_ALERTS:
                draft.loading = true;
                draft.error = undefined;
                draft.selected = {};
                draft.alerts = [];
                break;

            case PatientInformationActionRequiredAlertsActions.GET_PATIENT_ACTION_REQUIRED_ALERTS_SUCCESSFULLY:
                draft.error = undefined;
                draft.alerts = action.payload;
                draft.loading = false;
                break;

            case PatientInformationActionRequiredAlertsActions.GET_PATIENT_ACTION_REQUIRED_ALERTS_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;
        }
    });
};

export default PatientInformationActionRequiredAlertsReducers;
