import * as React from 'react';
import {memo} from 'react';
import footImg from 'images/foot.png'
import {usePatientTemperatureComparisonStyle} from "./patient-temperature-comparison.style";
import {
    PatientTemperatureComparisonEvents,
    PatientTemperatureComparisonMeta,
    PatientTemperatureComparisonOwnProps,
    PatientTemperatureComparisonProps
} from "./patient-temperature-comparison.interface";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {CHANGE_PATIENT_TEMPERATURE_COMPARED_TO, CHANGE_PATIENT_TEMPERATURE_FOOT, CHANGE_PATIENT_TEMPERATURE_SENSOR} from "../../store/patient-temperature.actions";
import {PatientTemperatureSelectors} from "../../store/patient-temperature.selectors";
import {PatientTemperatureSensors} from "../../data/enums/patient-temperature-sensors";
import {PatientTemperatureFeet} from "../../data/enums/patient-temperature-feet";
import {PatientTemperatureComparedTo} from "../../data/enums/patient-temperature-compared-to";
import {PatientTemperatureComparisonRadioButton} from "../patient-temperature-comparison-radio-button/patient-temperature-comparison-radio-button";
import {PatientTemperatureComparisonSensorsLegend} from "../patient-temperature-comparison-sensors-legend/patient-temperature-comparison-sensors-legend";
import {PatientTemperatureRadioGroup} from "../patient-temperature-radio-group/patient-temperature-radio-group";
import { ComparedTo, Foot, Sensor } from 'doctors-dashboard';

const PatientTemperatureComparisonTemplate = (props: PatientTemperatureComparisonMeta) => {
    const classes = usePatientTemperatureComparisonStyle();

    return (
        <div className={classes.root}>
            <div className={classes.wrapper} id="patient-temperature-comparison">
                <img src={footImg} alt="foot"/>

                <PatientTemperatureComparisonSensorsLegend/>

                <PatientTemperatureRadioGroup                    
                    name="foot"
                    label="Foot"
                    value={props.options.foot}
                    options={PatientTemperatureFeet.map(foot =>
                        <PatientTemperatureComparisonRadioButton
                            key={foot.value}
                            device={foot}
                            onChecked={() => props.onChangedFoot(foot.value)}
                        />)}
                />

               
                <PatientTemperatureRadioGroup
                    name="comparedTo"
                    label="Compared to"
                    value={props.options.comparedTo}
                    options={PatientTemperatureComparedTo.map(comparedTo =>
                        <PatientTemperatureComparisonRadioButton
                            key={comparedTo.value}
                            device={comparedTo}
                            onChecked={() => props.onChangedComparedTo(comparedTo.value)}
                            disabled={comparedTo.value === ComparedTo.Ipsilateral && props.options.sensor === Sensor.BigToe}
                            disabledText="Ipsilateral foot data not available for Big Toe"
                        />
                    )}
                />

                <PatientTemperatureRadioGroup
                    name="sensor"
                    label="Sensor"
                    value={props.options.sensor}
                    options={PatientTemperatureSensors.map(sensor =>
                        <PatientTemperatureComparisonRadioButton
                            key={sensor.value}
                            device={sensor}
                            onChecked={() => props.onChangedSensor(sensor.value)}
                            disabled={sensor.value === Sensor.BigToe && props.options.comparedTo === ComparedTo.Ipsilateral}
                            disabledText="Ipsilateral foot data not available for Big Toe"
                        />
                    )}
                />
            </div>
        </div>
    );
};


const mapStateToProps = (state: IOrpyxApplicationStore): PatientTemperatureComparisonOwnProps => {
    return {
        options: PatientTemperatureSelectors.options(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientTemperatureComparisonProps): PatientTemperatureComparisonEvents => {
    return bindActionCreators({
        onChangedComparedTo: CHANGE_PATIENT_TEMPERATURE_COMPARED_TO,
        onChangedSensor: CHANGE_PATIENT_TEMPERATURE_SENSOR,
        onChangedFoot: CHANGE_PATIENT_TEMPERATURE_FOOT,
    }, dispatch);
};

export const PatientTemperatureComparison = memo(connect(mapStateToProps, mapDispatchToProps)(PatientTemperatureComparisonTemplate));