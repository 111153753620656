import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {DoctorsDashboardNavbarLoginEvents, DoctorsDashboardNavbarLoginMeta, DoctorsDashboardNavbarLoginOwnProps, DoctorsDashboardNavbarLoginProps} from "./doctors-dashboard-navbar-login.interface";
import {useDoctorsDashboardNavbarLoginStyle} from "./doctors-dashboard-navbar-login.style";
import {IOrpyxApplicationStore} from "../../../../store/application.reducers";
import {Button, CircularProgress} from "@material-ui/core";
import {DOCTORS_DASHBOARD_LOGIN, DOCTORS_DASHBOARD_LOGOUT} from "../../../auth/store/doctors-dashboard-auth-actions";
import {DoctorsDashboardAuthSelectors} from "../../../auth/store/doctors-dashboard-auth-selectors";

export const DoctorsDashboardNavbarLogin = (props: DoctorsDashboardNavbarLoginMeta) => {
    const classes = useDoctorsDashboardNavbarLoginStyle();

    if (props.isLoggedIn) {
        return (
            <Button
                id = "logout_button"
                variant = "contained"
                color = "primary"

                disabled={props.inProcess}
                className = {classes.buttonRoot}
                onClick = {props.logout}>

                {props.inProcess && <CircularProgress/>}
                Logout
            </Button>);
    }

    return (
        <Button
            variant = "contained"
            color = "primary"
            disabled={props.inProcess}
            className = {classes.buttonRoot}
            onClick = {props.login}>
            
            {props.inProcess && <CircularProgress/>}
            Doctor login
        </Button>);
};

const mapStateToProps = (state: IOrpyxApplicationStore): DoctorsDashboardNavbarLoginOwnProps => {
    return {
        isLoggedIn: DoctorsDashboardAuthSelectors.isLoggedIn(state),
        inProcess: DoctorsDashboardAuthSelectors.inProcess(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: DoctorsDashboardNavbarLoginProps): DoctorsDashboardNavbarLoginEvents => {
    return bindActionCreators({
        login: DOCTORS_DASHBOARD_LOGIN,
        logout: DOCTORS_DASHBOARD_LOGOUT
    }, dispatch);
};

export const AdminDashboardNavbarLoginWrapper = memo(connect(mapStateToProps, mapDispatchToProps)(DoctorsDashboardNavbarLogin));
