import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {PatientsListTableEvents, PatientsListTableMeta, PatientsListTableOwnProps, PatientsListTableProps} from "../../patients-list-table/patients-list-table.interface";
import {TableBody} from "@material-ui/core";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {PatientsListSelectors} from "../../../store/patients-list.selectors";
import {OperationProgress, OperationSkeleton} from "orpyx-web-common";
import {PatientFilterType, IGetPatientsListResponse} from "doctors-dashboard/http-clients/index";
import {PatientsListGlobalSearchTableHeader} from "../table-header/patients-list-global-search-table-header";
import {PatientsListGlobalSearchTableItem} from "../table-item/patients-list-global-search-table-item";
import {PATIENTS_LIST_SELECT_PATIENT} from "../../../store/patients-list.actions";
import Table from "@material-ui/core/Table";
import {usePatientsListTableStyle} from "../../patients-list-table/patients-list-table.style";

const PatientsListGlobalSearchTemplate = memo((props: PatientsListTableMeta) => {
    const classes = usePatientsListTableStyle();

    if (props.loading || props.error) {
        return <>
            <OperationProgress inProcess={props.loading} error={props.error} />
            <OperationSkeleton height={60} rows={8} />
        </>
    }

    const onPatientSelected = (patient: IGetPatientsListResponse | undefined) => {
        if (patient && props.patientType === PatientFilterType.GlobalSearch) {
            props.onPatientSelected(patient);
        }
    }

    return (<Table id='patients-list-global-search-table'>
        <PatientsListGlobalSearchTableHeader
            classes={classes}
        />

        <TableBody>
            {props.patients.map(patient => 
                <PatientsListGlobalSearchTableItem
                    key={patient.patientId}
                    onPatientSelected={onPatientSelected}
                    patient={patient}
                />)}
        </TableBody>
    </Table>)
});

const mapStateToProps = (state: IOrpyxApplicationStore): PatientsListTableOwnProps => {
    return {
        patients: PatientsListSelectors.patients(state),
        loading: PatientsListSelectors.loading(state),
        error: PatientsListSelectors.error(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientsListTableProps): PatientsListTableEvents => {
    return bindActionCreators({
        onPatientSelected: PATIENTS_LIST_SELECT_PATIENT
    }, dispatch);
}

export const PatientsListGlobalSearchTable = memo(connect(mapStateToProps, mapDispatchToProps)(PatientsListGlobalSearchTemplate));