import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core";
import {Teal} from "orpyx-web-common";

export const usePatientsListTypeSelectorStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        flexDirection: 'row',
        marginBottom: '15px',
    },
    button: {
        marginRight: '10px',
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: 'none',
        width: '180px',
        background: '#FFF',
    },
    primary: {
        marginRight: '10px',
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: 'none',
        width: '180px',
        background: Teal,
        '& span': {
            color: '#FFF',
        }
    },
    right: {
        float: 'right'
    }
}));
