import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientTemperatureActions} from "./patient-temperature.actions";
import {ComparedTo, Foot, IPatientTemperatureSummaryModel, ITemperatureOptions, Sensor} from "doctors-dashboard/http-clients/index";

export interface PatientTemperatureState {
    loading: boolean,
    initialized: boolean,
    error: OperationErrorType;

    summary: IPatientTemperatureSummaryModel | undefined;
    options: ITemperatureOptions;
}

const initialState: PatientTemperatureState = {
    loading: false,
    initialized: false,
    error: undefined,

    summary: undefined,
    options: {
        comparedTo: ComparedTo.Contralateral,
        // OSID-403
        // sensor: Sensor.BigToe,
        sensor: Sensor.Meta1,
        foot: Foot.LeftFoot,
    },
}

const PatientTemperatureReducers = (state = initialState, action: StoreAction<PatientTemperatureActions>): PatientTemperatureState => {
    return produce(state, (draft: PatientTemperatureState) => {
        switch (action.type) {
            case PatientTemperatureActions.CHANGE_PATIENT_TEMPERATURE_COMPARED_TO:
                draft.options.comparedTo = action.payload as ComparedTo;
                break;

            case PatientTemperatureActions.CHANGE_PATIENT_TEMPERATURE_SENSOR:
                draft.options.sensor = action.payload as Sensor;
                break;

            case PatientTemperatureActions.CHANGE_PATIENT_TEMPERATURE_FOOT:
                draft.options.foot = action.payload as Foot;
                break;

            case PatientTemperatureActions.RESET_PATIENT_TEMPERATURE_SUMMARY:
                draft.loading = false;
                draft.initialized = false;
                draft.error = undefined;
                draft.summary = undefined;
                break;

            case PatientTemperatureActions.GET_PATIENT_TEMPERATURE_SUMMARY:
                draft.loading = true;
                draft.initialized = false;
                draft.error = undefined;
                draft.summary = undefined;
                break;

            case PatientTemperatureActions.GET_PATIENT_TEMPERATURE_SUMMARY_SUCCESSFULLY:
                draft.summary = action.payload;
                draft.error = undefined;
                draft.initialized = true;
                draft.loading = false;
                break;

            case PatientTemperatureActions.GET_PATIENT_TEMPERATURE_SUMMARY_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;
        }
    });
};

export default PatientTemperatureReducers;