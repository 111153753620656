import {RemotePatientMonitoringSigninPageMeta} from "./remote-patient-monitoring-signin-page.interface";
import {isNullOrEmpty} from "orpyx-web-common";
import {OperationResultCode} from "doctors-dashboard";
import Cookies from "js-cookie";

export const onRemotePatientMonitoringSignInPageLoaded = (props: RemotePatientMonitoringSigninPageMeta) => () => {
    const token = Cookies.get("JwtToken");
    if (props.match.isExact && props.match.params.patientId) {
        const patientId = props.match.params.patientId;
        if (token == undefined || isNullOrEmpty(token)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid token: ${token}`
            });
        } else if (isNullOrEmpty(patientId)) {
            props.showErrorMessage({
                code: OperationResultCode.InvalidFilter,
                errorMessage: `Invalid patientId: ${props.match.params.patientId}`
            });
        } else {
            const patient = parseInt(patientId);
            const containsDot = token.includes('.');

            if (isNaN(patient)) {
                props.showErrorMessage({
                    code: OperationResultCode.InvalidFilter,
                    errorMessage: `Invalid patientId: ${props.match.params.patientId}`
                });
            } else if (!containsDot) {
                props.showErrorMessage({
                    code: OperationResultCode.InvalidFilter,
                    errorMessage: `Invalid token format: ${token}`
                });
            } else {
                props.setRemoteDoctorToken(token);
                props.history.push(`/patients/details/${patient}`)
            }
        }
    } else {
        props.showErrorMessage({
            code: OperationResultCode.InvalidFilter,
            errorMessage: `Invalid route, patientId: ${props.match.params.patientId}, token: ${token}`
        });
    }
}