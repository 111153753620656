import {OperationErrorType, StoreAction} from "orpyx-web-common";
import produce from "immer";
import {PatientsListActions} from "./patients-list.actions";
import {PatientsSearchQuery} from "../models/patients-search-query";
import {IGetPatientsListResponse, IGetPatientsListResponsePagingResult} from "doctors-dashboard/http-clients/index";

export interface PatientsListState {
    loading: boolean;
    error: OperationErrorType;

    search: PatientsSearchQuery,

    patients: IGetPatientsListResponse[];
    total: number;
}

const initialState: PatientsListState = {
    error: undefined,
    loading: false,

    search: new PatientsSearchQuery(),

    patients: [],
    total: 0,
};


const PatientsListReducers = (state = initialState, action: StoreAction<PatientsListActions>): PatientsListState => {
    return produce(state, (draft: PatientsListState) => {
        switch (action.type) {
            case PatientsListActions.FETCH_PATIENTS_LIST:
                draft.loading = true;
                draft.search = action.payload;
                draft.error = undefined;
                draft.patients = [];
                break;

            case PatientsListActions.FETCH_PATIENTS_LIST_BEGIN:
                draft.error = undefined;
                draft.loading = true;
                break;

            case PatientsListActions.FETCH_PATIENTS_LIST_SUCCESSFULLY: {
                const result = action.payload as IGetPatientsListResponsePagingResult;
                draft.error = undefined;
                draft.patients = result.items!;
                draft.total = result.total!;
                draft.loading = false;
                break;
            }
            case PatientsListActions.FETCH_PATIENTS_LIST_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;
        }
    });
};

export default PatientsListReducers;
