import {Sensor} from "doctors-dashboard/http-clients/index";
import {LightGrey, Teal} from "orpyx-web-common";
import {PatientTemperatureOption} from "../interfaces/patient-temperature-option";

export const PatientTemperatureSensors: PatientTemperatureOption<Sensor>[] = [
    {name: 'Big toe', value: Sensor.BigToe, color: Teal},
    {name: 'Meta 1', value: Sensor.Meta1, color: LightGrey},
    {name: 'Meta 3', value: Sensor.Meta3, color: '#58d5a3'},
    {name: 'Meta 5', value: Sensor.Meta5, color: '#4A90E2'},
    {name: 'Heel', value: Sensor.Heel, color: '#EC9BF0'},
];

