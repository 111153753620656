import {AnimationModel, AxisModel, ITooltipRenderEventArgs, MarkerSettingsModel} from "@syncfusion/ej2-react-charts";
import moment from "moment";
import {FixedNumericPipe} from "orpyx-web-common";

const font = {
    fontFamily: 'Poppins'
};

export const patientMetricsAlertsChartLegendSettings = {
    toggleVisibility: false,
    textStyle: font
};

export const patientMetricsAlertsChartAnimation: AnimationModel = {
    enable: false
};

export const patientMetricsAlertsChartMarkers: MarkerSettingsModel = {
    visible: true
};

export const patientMetricsAlertsChartAxisX = (startDate: moment.Moment, endDate: moment.Moment): AxisModel => ({
    valueType: 'DateTime',
    minimum: startDate,
    maximum: endDate,
    labelFormat: 'MMM dd',
    edgeLabelPlacement: 'Shift',
    titleStyle: font,
    labelStyle: font,
});

export const patientMetricsAlertsChartAxisY: AxisModel = {
    minimum: 0,
    title: 'Number Of Alerts',
    titleStyle: font,
    labelStyle: font,
};

export const patientMetricsAlertsBorder = {color: '#FF0031', width: 1}


export const patientMetricsAlertsChartTooltip = {
    enable: true,
    header: '${point.x}',
    textStyle: font,
};

export const patientMetricsAlertsChartTooltipRender = (args: ITooltipRenderEventArgs | undefined) => {
    if (args) {
        const value = FixedNumericPipe(args.point.y as number, 0);
        args.text = `${value} Alerts`;
    }
}