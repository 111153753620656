import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";

import {
    DoctorsDashboardNavbarProfileEvents,
    DoctorsDashboardNavbarProfileMeta,
    DoctorsDashboardNavbarProfileOwnProps,
    DoctorsDashboardNavbarProfileProps
} from "./doctors-dashboard-navbar-profile.interface";
import {IOrpyxApplicationStore} from "../../../../store/application.reducers";
import {fullNamePipe} from "orpyx-web-common";
import {DoctorsDashboardAuthSelectors} from "../../../auth/store/doctors-dashboard-auth-selectors";
import user from 'images/user-md-symbol.png';
import {Link} from "react-router-dom";
import {useDoctorsDashboardNavbarProfileStyleStyle} from "./doctors-dashboard-navbar-profile.style";

export const DoctorsDashboardNavbarProfile = (props: DoctorsDashboardNavbarProfileMeta) => {
    const classes = useDoctorsDashboardNavbarProfileStyleStyle();

    return (
        <>
            <img src={user} className={classes.icon} id="doctors-dashboard-navbar-profile-image" alt={fullNamePipe(props.profile)}/>

            <span className={classes.profile}>
                <Link to="#" className={props.linkClassName} id="doctors-dashboard-navbar-profile-monitoring-personnel">
                    {fullNamePipe(props.profile)}
                </Link>
            </span>

            
        </>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): DoctorsDashboardNavbarProfileOwnProps => {
    return {
        profile: DoctorsDashboardAuthSelectors.profile(state),
        isLoggedIn: DoctorsDashboardAuthSelectors.isLoggedIn(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: DoctorsDashboardNavbarProfileProps): DoctorsDashboardNavbarProfileEvents => {
    return bindActionCreators({}, dispatch);
};

export const DoctorsDashboardNavbarProfileWrapper = memo(connect(mapStateToProps, mapDispatchToProps)(DoctorsDashboardNavbarProfile));