import produce from "immer";
import {DoctorsDashboardAuthActions} from "./doctors-dashboard-auth-actions";
import {StoreAction} from "orpyx-web-common";
import {IGetDashboardUserProfileResponse} from "doctors-dashboard/http-clients/index";
import {DoctorsDashboardAzureActor} from "../models/doctors-dashboard-azure-token";
import DoctorsDashboardActorStorage from "./actor/doctors-dashboard-actor-storage";
import DoctorsDashboardAzureTokenStorage from "./actor/doctors-dashboard-azure-token-storage";

export interface DoctorsDashboardAuthState {
    isLoggedIn: boolean,
    inProcess: boolean,

    azureActor: DoctorsDashboardAzureActor | undefined,
    profile: IGetDashboardUserProfileResponse | undefined,
    lastActive: Date | undefined,

    logoutRequiresConfirmation: boolean
}

const createInitialState = (): DoctorsDashboardAuthState => {
    const azureActor = DoctorsDashboardAzureTokenStorage.getActor();
    const profile = DoctorsDashboardActorStorage.getActorProfile();

    if (azureActor && azureActor.accessToken && profile) {
        if (azureActor.accessToken.expiredAt > new Date()) {
            return {
                isLoggedIn: true,
                inProcess: false,
                azureActor,
                profile,
                lastActive: new Date(),
                logoutRequiresConfirmation: false
            };
        }
    }

    return {
        isLoggedIn: false,
        inProcess: false,

        profile: undefined,
        azureActor: undefined,
        lastActive: undefined,

        logoutRequiresConfirmation: false
    };
};

const DoctorsDashboardAuthReducers = (state = createInitialState(), action: StoreAction<DoctorsDashboardAuthActions>): DoctorsDashboardAuthState => {
    return produce(state, (draft: DoctorsDashboardAuthState) => {
        switch (action.type) {
            case DoctorsDashboardAuthActions.LOGIN:
                draft.inProcess = true;
                draft.isLoggedIn = false;
                draft.azureActor = undefined;
                draft.profile = undefined;
                break;

            case DoctorsDashboardAuthActions.LOGOUT:
                draft.inProcess = true;
                break;

            case DoctorsDashboardAuthActions.LOGOUT_CANCELLED:
                draft.inProcess = false;
                break;

            case DoctorsDashboardAuthActions.LOGIN_FAILED:
            case DoctorsDashboardAuthActions.LOGOUT_SUCCESSFULLY:
            case DoctorsDashboardAuthActions.FETCH_USER_PROFILE_FAILED:
                draft.inProcess = false;
                draft.isLoggedIn = false;
                draft.azureActor = undefined;
                draft.profile = undefined;
                draft.lastActive = undefined;
                break;

            case DoctorsDashboardAuthActions.LOGIN_SUCCESSFULLY:
                draft.azureActor = action.payload as DoctorsDashboardAzureActor;
                break;

            case DoctorsDashboardAuthActions.FETCH_USER_PROFILE_SUCCESSFULLY:
                draft.profile = action.payload as IGetDashboardUserProfileResponse;
                break;

            case DoctorsDashboardAuthActions.LOGIN_COMPLETE:
                draft.isLoggedIn = true;
                draft.inProcess = false;
                draft.lastActive = new Date();
                break;
                
            case DoctorsDashboardAuthActions.RESET_LAST_ACTIVITY_TIME:
                draft.lastActive = new Date();
                break;
            
            case DoctorsDashboardAuthActions.SET_LOGOUT_CONFIRMATION:
                draft.logoutRequiresConfirmation = action.payload;
                break;
        }
    });
};

export default DoctorsDashboardAuthReducers;


