import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {
    DoctorsDashboardMonthlyReportsHttpClient,
    IFileResponse,
    IGetPatientInformationResponse,
    IGetPatientReportsListEntry,
    IGetPatientReportsListResult,
} from "doctors-dashboard/http-clients/index";
import {ORPYXSI_API_URL} from "../../../../../../appConstants";
import {PatientInformationPageSelectors} from "../../patient-information-page/store/patient-information-page.selectors";
import {base64ToBlob,  ENQUEUE_SNACKBAR, OperationErrorPipe, StoreAction} from "orpyx-web-common";
import FileSaver from "file-saver";
import {PatientMonthlyReportsSelectors} from "./patient-monthly-reports.selectors";
import {
    DOWNLOAD_PATIENT_MONTHLY_REPORT_FAILED,
    DOWNLOAD_PATIENT_MONTHLY_REPORT_SUCCESSFULLY,
    GET_PATIENT_MONTHLY_REPORTS_FAILED,
    GET_PATIENT_MONTHLY_REPORTS_SUCCESSFULLY,
    PatientMonthlyReportsActions
} from "./patient-monthly-reports.actions";

const API_VERSION = '1';

function* onDownloadPatientMonthlyReport(action: StoreAction<PatientMonthlyReportsActions, IGetPatientReportsListEntry>) {
    const reportEntry = action.payload!;

    try {
        const patient: IGetPatientInformationResponse = yield select(PatientInformationPageSelectors.patient)!;

        const api = new DoctorsDashboardMonthlyReportsHttpClient(ORPYXSI_API_URL);

        const data: IFileResponse = yield call(
            [api, api.downloadActivityLogReport],
            patient.patientId!,
            reportEntry.name!,
            API_VERSION,
        );

        yield put(DOWNLOAD_PATIENT_MONTHLY_REPORT_SUCCESSFULLY(reportEntry));
        yield put(ENQUEUE_SNACKBAR({
            message: `Successfully downloaded monthly report at ${reportEntry.name}`,
            options: {variant: "success"}
        }));

        const blob = base64ToBlob(data.content!, data.contentType!);
        FileSaver.saveAs(blob, data.fileName!);
    } catch (e) {
        yield put(DOWNLOAD_PATIENT_MONTHLY_REPORT_FAILED(reportEntry, e));

        yield put(ENQUEUE_SNACKBAR({
            message: `Failed download activity report at ${reportEntry.name}, error: ${OperationErrorPipe(e)}`,
            options: {variant: "error"}
        }));
    }
}

function* onFetchPatientCareNotes() {
    try {
        const patient: IGetPatientInformationResponse = yield select(PatientInformationPageSelectors.patient)!;
        const lastRowMarker: string | undefined = yield select(PatientMonthlyReportsSelectors.lastRowMarker);
        const takeCount: number = yield select(PatientMonthlyReportsSelectors.takeCount)!;
        
        const api = new DoctorsDashboardMonthlyReportsHttpClient(ORPYXSI_API_URL);

        const data: IGetPatientReportsListResult = yield call(
            [api, api.getPatientMonthlyReportsList],
            patient.patientId!,
            takeCount,
            lastRowMarker,
            API_VERSION,
        );

        yield put(GET_PATIENT_MONTHLY_REPORTS_SUCCESSFULLY(data));
    } catch (e) {
        yield put(GET_PATIENT_MONTHLY_REPORTS_FAILED(e));
    }
}

export default function* PatientMonthlyReportsSagas() {
    yield all([
        yield takeEvery(PatientMonthlyReportsActions.DOWNLOAD_PATIENT_MONTHLY_REPORT, onDownloadPatientMonthlyReport),
        yield takeEvery(PatientMonthlyReportsActions.GET_PATIENT_MONTHLY_REPORTS, onFetchPatientCareNotes),
    ]);
}