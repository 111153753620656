import {OperationErrorType, StoreAction} from "orpyx-web-common";

export enum RemotePatientMonitoringSigninActions {
    SET_USER_TOKEN = 'GET_REMOTE_USER_INFORMATION: SET_USER_TOKEN',
    SET_USER_TOKEN_FAILED = 'GET_REMOTE_USER_INFORMATION: SET_USER_TOKEN_FAILED',
}

export const SET_REMOTE_USER_TOKEN = (token: string): StoreAction<RemotePatientMonitoringSigninActions> => ({
    type: RemotePatientMonitoringSigninActions.SET_USER_TOKEN,
    payload: token
});

export const SET_REMOTE_USER_TOKEN_FAILED = (error: OperationErrorType): StoreAction<RemotePatientMonitoringSigninActions> => ({
    type: RemotePatientMonitoringSigninActions.SET_USER_TOKEN_FAILED,
    payload: error
});