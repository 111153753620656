import {Grid} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import Calendar from "@material-ui/icons/CalendarToday"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import * as React from "react";
import {memo} from "react";
import {PatientInformationHeaderProps} from "./patient-information-header.interface";
import {usePatientInformationPageStyles} from "../patient-information-page/patient-information-page.style";
import {fullNamePipe} from "orpyx-web-common";
import moment from "moment";
import MomentUtils from "@date-io/moment"
import {ArrowDropDown} from "@material-ui/icons";

export const PatientInformationHeader = memo((props: PatientInformationHeaderProps) => {
    const classes = usePatientInformationPageStyles();

    const onFilterDateChange = (event: any) => {
        props.onFilterDateChange(event);
    }

    const contactPhoneNumber = props.patient.contactPhoneNumber;
    const hasPhoneNumber = contactPhoneNumber != null;
    
    // Formats phone number as (###) ###-####
    const formatPhoneNumber = (phoneNumber: string | null | undefined) => {
        if (phoneNumber == null) return null;
        return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
    }

    return (
        <div className={classes.header}>
            <Grid container className={classes.dashboardHeader} id="patient-information-header">

                <Grid item xs={4} className={classes.horizontalLayout}>
                    <PersonIcon className={classes.icon} id="patient-information-header-patient-name-icon"/>
                    <span className={classes.horizontalLayout}>
                        <span id='patient-information-header-span' className={`${classes.verticalLayout} ${classes.patientDetailsHeader}`}>
                            <span>Patient Name:</span>
                            {hasPhoneNumber && <span>Contact Phone:</span>}
                        </span>
                        <span className={`${classes.verticalLayout} ${classes.patientDetailsHeader}`}>
                            <span  id='patient-information-header-fullname-span' className={classes.altText}>{fullNamePipe(props.patient)}</span>
                            {hasPhoneNumber && <span  id='patient-information-header-phone-span' className={classes.altText}>{formatPhoneNumber(contactPhoneNumber)}</span>}
                        </span>
                    </span>
                </Grid>

                <Grid item xs={4} className={classes.horizontalLayout}>
                    <AssignmentIndIcon className={classes.icon} id="patient-information-header-patient-id-icon"/>
                    <div className={classes.patientDetailsHeader} id='patient-information-header-id'>
                        <span>Patient ID:</span>
                        <span className={classes.altText}>{props.patient.clientPatientIdentifier}</span>
                    </div>
                </Grid>

                <Grid item xs={4} className={classes.horizontalLayout}>
                    <Calendar className={classes.icon} id="patient-information-header-patient-calendar-icon" />
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker                           
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label="Month"
                            format="MMM yyyy"
                            onChange={onFilterDateChange}
                            value={props.monitoringCycle.begin}
                            disableFuture={true}
                            openTo="month"
                            minDate={props.patient.allowAccessStartDate?.startOf("month")}
                            views={["year", "month"]}
                            keyboardIcon={<ArrowDropDown style={{color: 'white' }} />}
                            KeyboardButtonProps={{size: "medium"}}
                            className = {classes.datePicker}
                            InputProps={{ readOnly: true,
                                classes: {
                                    input: classes.datePickerInput, 
                                    notchedOutline: classes.datePickerNotchedOutline } }}
                            InputLabelProps={{ focused: false,
                                classes: { 
                                    root: classes.datePickerLabel } }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        </div>
    );
});