import {isNullOrUndefined, OperationErrorType, StoreAction} from "orpyx-web-common";
import {IGetPatientReportsListEntry, IGetPatientReportsListResult} from "doctors-dashboard/http-clients/index";
import produce from "immer";
import {PatientMonthlyReportsDownloadInProcess} from "../types/patient-monthly-reports-download-in-process";
import {PatientMonthlyReportsActions} from "./patient-monthly-reports.actions";
import moment from "moment";

//  10 years
const DEFAULT_TAKE_MONTHLY_REPORTS_COUNT = 120;

export interface PatientMonthlyReportsState {
    loading: boolean;
    initialized: boolean;
    error: OperationErrorType;

    monthlyReports: {
        inProcess: PatientMonthlyReportsDownloadInProcess;
        list: IGetPatientReportsListEntry[];
        lastRowMarker: string | undefined;
        canGetMore: boolean;
        takeCount: number;
    }
}

const initialState: PatientMonthlyReportsState = {
    loading: false,
    initialized: false,
    error: undefined,

    monthlyReports: {
        inProcess: {},
        list: [],
        lastRowMarker: undefined,
        canGetMore: true,
        takeCount: DEFAULT_TAKE_MONTHLY_REPORTS_COUNT,
    }
};

const PatientMonthlyReportsReducers = (state = initialState, action: StoreAction<PatientMonthlyReportsActions>): PatientMonthlyReportsState => {
    return produce(state, (draft: PatientMonthlyReportsState) => {
        switch (action.type) {

            case PatientMonthlyReportsActions.DOWNLOAD_PATIENT_MONTHLY_REPORT: {
                const reportEntry: IGetPatientReportsListEntry = action.payload;
                draft.monthlyReports.inProcess[reportEntry.name!] = true;
                break;
            }

            case PatientMonthlyReportsActions.DOWNLOAD_PATIENT_MONTHLY_REPORT_SUCCESSFULLY:
            case PatientMonthlyReportsActions.DOWNLOAD_PATIENT_MONTHLY_REPORT_FAILED: {
                const reportEntry: IGetPatientReportsListEntry = action.payload;
                delete draft.monthlyReports.inProcess[reportEntry.name!];
                break;
            }

            case PatientMonthlyReportsActions.GET_PATIENT_MONTHLY_REPORTS:
                const appendReports: boolean = action.payload;
                if (appendReports) {
                    draft.loading = true;
                } else {
                    draft.loading = true;
                    draft.initialized = false;
                    draft.error = undefined;
                    draft.monthlyReports = {
                        inProcess: {},
                        canGetMore: false,
                        list: [],
                        takeCount: DEFAULT_TAKE_MONTHLY_REPORTS_COUNT,
                        lastRowMarker: undefined
                    };
                }
                break;

            case PatientMonthlyReportsActions.GET_PATIENT_MONTHLY_REPORTS_SUCCESSFULLY: {
                draft.error = undefined;
                draft.initialized = true;
                draft.loading = false;

                const getMonthlyReportsResult = action.payload as IGetPatientReportsListResult;
                const monthlyReports = getMonthlyReportsResult.reports as IGetPatientReportsListEntry[];


                const hasNextMarker = !isNullOrUndefined(getMonthlyReportsResult.nextMarker);
                const hasNextRows = monthlyReports.length === draft.monthlyReports.takeCount;

                draft.monthlyReports = {
                    list: (draft.monthlyReports.list.concat(monthlyReports)).sort((left, right) => {
                        return moment.utc(right.reportMonth).diff(moment.utc(left.reportMonth))
                    }),
                    canGetMore: hasNextMarker && hasNextRows,
                    lastRowMarker: getMonthlyReportsResult.nextMarker as string | undefined,
                    takeCount: draft.monthlyReports.takeCount,
                    inProcess: draft.monthlyReports.inProcess,
                };

                break;
            }

            case PatientMonthlyReportsActions.GET_PATIENT_MONTHLY_REPORTS_FAILED:
                draft.error = action.payload;
                draft.loading = false;
                break;

            case PatientMonthlyReportsActions.RESET_PATIENT_MONTHLY_REPORTS:
                draft.error = undefined;
                draft.loading = false;
                draft.initialized = false;
                draft.monthlyReports.list = [];
                break;
        }
    });
};

export default PatientMonthlyReportsReducers;
