import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IListCareNotesResultEntry, IPostCareNoteModel} from "doctors-dashboard/http-clients/index";

export enum PatientSubmitCareNoteActions {
    PATIENT_SUBMIT_CARE_NOTE = 'PATIENT_SUBMIT_CARE_NOTE: PATIENT_SUBMIT_CARE_NOTE',
    PATIENT_SUBMIT_CARE_NOTE_SUCCESSFULLY = 'PATIENT_SUBMIT_CARE_NOTE: PATIENT_SUBMIT_CARE_NOTE_SUCCESSFULLY',
    PATIENT_SUBMIT_CARE_NOTE_FAILED = 'PATIENT_SUBMIT_CARE_NOTE: PATIENT_SUBMIT_CARE_NOTE_FAILED',
    PATIENT_SUBMIT_CARE_NOTE_RESET = 'PATIENT_SUBMIT_CARE_NOTE: PATIENT_SUBMIT_CARE_NOTE_RESET',
    PATIENT_SUBMIT_CARE_NOTE_INCREASE_TIME = 'PATIENT_SUBMIT_CARE_NOTE: PATIENT_SUBMIT_CARE_NOTE_INCREASE_TIME',
}

export const PATIENT_SUBMIT_CARE_NOTE_INCREASE_TIME = (): StoreAction<PatientSubmitCareNoteActions> => ({
    type: PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE_INCREASE_TIME
});

export const PATIENT_SUBMIT_CARE_NOTE_RESET = (): StoreAction<PatientSubmitCareNoteActions> => ({
    type: PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE_RESET
});

export const PATIENT_SUBMIT_CARE_NOTE = (careNote: IPostCareNoteModel): StoreAction<PatientSubmitCareNoteActions> => ({
    type: PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE, payload: careNote
});

export const PATIENT_SUBMIT_CARE_NOTE_SUCCESSFULLY = (careNote: IListCareNotesResultEntry): StoreAction<PatientSubmitCareNoteActions> => ({
    type: PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE_SUCCESSFULLY, payload: careNote
});

export const PATIENT_SUBMIT_CARE_NOTE_FAILED = (error: OperationErrorType): StoreAction<PatientSubmitCareNoteActions> => ({
    type: PatientSubmitCareNoteActions.PATIENT_SUBMIT_CARE_NOTE_FAILED, payload: error
});