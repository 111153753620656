import * as React from "react";
import {memo} from "react";
import {connect} from "react-redux";

import {PatientMetricsAlertsChartMeta, PatientMetricsAlertsChartOwnProps, PatientMetricsAlertsChartValues} from "./patient-metrics-alerts-chart.interface";
import {ChartComponent, DateTime, Inject, Legend, LineSeries, SeriesCollectionDirective, SeriesDirective, Tooltip} from "@syncfusion/ej2-react-charts";
import {PatientStatisticSelectors} from "../../store/patient-statistic.selectors";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {
    patientMetricsAlertsBorder,
    patientMetricsAlertsChartAnimation,
    patientMetricsAlertsChartAxisX,
    patientMetricsAlertsChartAxisY,
    patientMetricsAlertsChartLegendSettings, patientMetricsAlertsChartMarkers, patientMetricsAlertsChartTooltip, patientMetricsAlertsChartTooltipRender
} from "./patient-metrics-alerts-chart.axis";
import {getFirstMonthDay, getLastMonthDay, isNullOrUndefined} from "orpyx-web-common";

const generateChartData = (props: PatientMetricsAlertsChartMeta): PatientMetricsAlertsChartValues[] => {
    if (isNullOrUndefined(props.statistic?.dailySummary)) {
        return [];
    }

    return props.statistic!.dailySummary!.map(q => ({
        summaryDate: q.dateTimeToDay!,
        totalAlerts: q.summary!.overall!.alerts!,
    }));
};

const PatientMetricsAlertsChartTemplate = (props: PatientMetricsAlertsChartMeta) => {
    const chartData = generateChartData(props);
    const startDate = getFirstMonthDay(props.statistic?.month)!;
    const endDate = getLastMonthDay(props.statistic?.month)!;

    return (<ChartComponent id="patient-metrics-alert-chart"
        height="220px"
        primaryXAxis={patientMetricsAlertsChartAxisX(startDate, endDate)}
        primaryYAxis={patientMetricsAlertsChartAxisY}
        legendSettings={patientMetricsAlertsChartLegendSettings}
        tooltip={patientMetricsAlertsChartTooltip}
        tooltipRender={patientMetricsAlertsChartTooltipRender}
    >
        <Inject services={[LineSeries, Legend, DateTime, Tooltip]}/>
        <SeriesCollectionDirective>
            <SeriesDirective  
                dataSource={chartData}
                xName='summaryDate'
                yName='totalAlerts'
                width={1}
                animation={patientMetricsAlertsChartAnimation}
                marker={patientMetricsAlertsChartMarkers}
                border={patientMetricsAlertsBorder}
                name={"Number of Alerts Per Day"}
                type='Line'
                opacity={0.6}
            >
            </SeriesDirective>
        </SeriesCollectionDirective>
    </ChartComponent>);
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientMetricsAlertsChartOwnProps => {
    return {
        statistic: PatientStatisticSelectors.thisMonth(state),
    };
};

export const PatientMetricsAlertsChart = memo(connect(mapStateToProps)(PatientMetricsAlertsChartTemplate));

