export class BaseHttpClient {
    transformOptions(options) {
        if (BaseHttpClient.TokenSelector) {
            const token = BaseHttpClient.TokenSelector();
            if (token) {
                options.headers = Object.assign(Object.assign({}, options.headers), { "Authorization": `Bearer ${token}` });
            }
        }
        options.headers = Object.assign(Object.assign({}, options.headers), { "Page": window.location.href });
        return Promise.resolve(options);
    }
}
