import * as React from "react";
import {memo} from "react";
import {PatientMetricsAlertRegionProps} from "./patient-metrics-alert-region.interface";
import {Grid} from "@material-ui/core";
import footmapImageLeft from "../../../../../../../images/Alert-Regions-Footmap-Left.png";
import footmapImageRight from "../../../../../../../images/Alert-Regions-Footmap-Right.png";
import {usePatientMetricsAlertRegionStyle} from "./patient-metrics-alert-region.style";
import {PercentNumericPipe} from "orpyx-web-common";

export const PatientMetricsAlertRegion = memo((props: PatientMetricsAlertRegionProps) => {
    const classes = usePatientMetricsAlertRegionStyle();

    const leftFootRegionAlerts = props.summary?.leftFootRegionAlerts;
    const rightFootRegionAlerts = props.summary?.rightFootRegionAlerts;

    const totalAlerts = leftFootRegionAlerts && rightFootRegionAlerts ? leftFootRegionAlerts.total! + rightFootRegionAlerts.total! : 0;

    
    return (
        <>
            <div className={classes.title} id="patient-metrics-alert-region-title">
                Alerts by Region
            </div>

            <div className={classes.root} id="patient-metrics-alert-region">
                <Grid container>
                    <Grid item xs={6}>
                        <img height="200px" src={footmapImageLeft} alt={"Left foot map"}/>
                    </Grid>
                    <Grid item xs={6} className={classes.right}>
                        <img height="200px" src={footmapImageRight}  alt={"Right foot map"}/>
                    </Grid>
                </Grid>

                <span className={`${classes.stats} ${classes.lOuterToes}`}>{PercentNumericPipe(leftFootRegionAlerts?.outerToes, totalAlerts, 0)}</span>
                <span className={`${classes.stats} ${classes.lInnerToes}`}>{PercentNumericPipe(leftFootRegionAlerts?.innerToes, totalAlerts, 0)}</span>
                <span className={`${classes.stats} ${classes.lOuterMetas}`}>{PercentNumericPipe(leftFootRegionAlerts?.outerMetas, totalAlerts, 0)}</span>
                <span className={`${classes.stats} ${classes.lInnerMetas}`}>{PercentNumericPipe(leftFootRegionAlerts?.innerMetas, totalAlerts, 0)}</span>
                <span className={`${classes.stats} ${classes.lMid}`}>{PercentNumericPipe(leftFootRegionAlerts?.midfoot, totalAlerts, 0)}</span>
                <span className={`${classes.stats} ${classes.lHeel}`}>{PercentNumericPipe(leftFootRegionAlerts?.heel, totalAlerts, 0)}</span>

                <span className={`${classes.stats} ${classes.rOuterToes}`}>{PercentNumericPipe(rightFootRegionAlerts?.outerToes, totalAlerts, 0)}</span>
                <span className={`${classes.stats} ${classes.rInnerToes}`}>{PercentNumericPipe(rightFootRegionAlerts?.innerToes, totalAlerts, 0)}</span>
                <span className={`${classes.stats} ${classes.rOuterMetas}`}>{PercentNumericPipe(rightFootRegionAlerts?.outerMetas, totalAlerts, 0)}</span>
                <span className={`${classes.stats} ${classes.rInnerMetas}`}>{PercentNumericPipe(rightFootRegionAlerts?.innerMetas, totalAlerts, 0)}</span>
                <span className={`${classes.stats} ${classes.rMid}`}>{PercentNumericPipe(rightFootRegionAlerts?.midfoot, totalAlerts, 0)}</span>
                <span className={`${classes.stats} ${classes.rHeel}`}>{PercentNumericPipe(rightFootRegionAlerts?.heel, totalAlerts, 0)}</span>

            </div>
        </>
    );
});