import {createStyles, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

export const usePatientSafePressureChartStyle = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'relative',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        padding: '0 50px',
    },
    centered: {
        position: "absolute",
        top: 'calc(55% - 30px)',
        left: '51%',
        transform: 'translate(-50%, 0px)',
        fontSize: '34px',
    },
    centeredNoData: {
        position: "absolute",
        top: 'calc(63% - 30px)',
        left: '47%',
        transform: 'translate(-40%, 0px)',
        fontSize: '26px',
        color: 'white'
    },
    sup: {
        verticalAlign: 'super',
        fontSize: '14px',
        margin: '0 0 0 -5px',
    },
    chart: {
        height: '150px',
    },
    title: {
        fontSize: '14px',
        marginBottom: '5px',
    },
    dates: {
        fontSize: '14px',
    }
}));
