import { getPatientsState } from "modules/doctor-dashboard/patients/store/patients.selectors";
import { createSelector } from "reselect";

export class ScratchPadNoteSelectors {
    static readonly state = createSelector(getPatientsState, state => state.scratchPadNote);

    static readonly loading = createSelector(ScratchPadNoteSelectors.state, state => state.loading);
    static readonly error = createSelector(ScratchPadNoteSelectors.state, state => state.error);

    static readonly scratchPadNote = createSelector(ScratchPadNoteSelectors.state, state => state.scratchPadNote);
    
    static readonly setUnloadListener = createSelector(ScratchPadNoteSelectors.state, state => state.setUnloadListener);
}