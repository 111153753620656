import {isNullOrUndefined} from "orpyx-web-common";
import {FormControlValidatorSignature, ValidatorResultMessage} from "orpyx-web-common/dist/utility/form/form-control-validators";
import {TimeSpan} from "../utility/timeSpan";


export class FormControlValidators {
    private static readonly regex = /^\d*[:]?\d*[:]?\d*$/;

    static readonly timespanRange = (minSeconds: number, maxSeconds: number): FormControlValidatorSignature => (value: string | null | undefined): ValidatorResultMessage => {
        const totalSeconds = TimeSpan.fromHoursAndMinutesAndSecondsToSeconds(value)!;

        if (totalSeconds < minSeconds) {
            return `Must be more ${TimeSpan.toHoursAndMinutesAndSeconds(minSeconds)}`;
        }

        if (totalSeconds > maxSeconds) {
            return `Must be less ${TimeSpan.toHoursAndMinutesAndSeconds(maxSeconds)}`;
        }
        return undefined;
    }

    static readonly timespan: FormControlValidatorSignature = (value: string | null | undefined): ValidatorResultMessage => {
        if (value) {
            if (!value.match(FormControlValidators.regex)) {
                return 'Invalid duration, required mm OR mm:ss OR hh:mm:ss';
            }

            var values = TimeSpan.parse(value);
            if (values.length > 3) {
                return 'Invalid duration, required mm OR mm:ss OR hh:mm:ss';
            }

            if (values.length == 1) { // minutes only
                const v:number[] = [];
                values = v.concat([0], values, [0]);
            }
            else if (values.length == 2) { // minutes and seconds
                const v:number[] = [];
                values = v.concat([0], values);  // hour is 0               
            }
            const [hours, minutes, seconds] = values;
            
            if (isNaN(hours) || hours < 0 || hours >= 24)
                return 'Invalid hour';

            if (isNaN(minutes) || minutes < 0 || minutes >= 60)
                return 'Invalid minutes';

            if (!isNullOrUndefined(seconds)) {
                if (isNaN(seconds) || seconds < 0 || seconds >= 60)
                    return 'Invalid seconds';
            }

            const totalSeconds = TimeSpan.fromHoursAndMinutesAndSecondsToSeconds(value)!;
            if (isNullOrUndefined(totalSeconds) || isNaN(totalSeconds)) {
                return 'Invalid duration';
            }
        }

        return undefined;
    };
}
