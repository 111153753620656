import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {IPatientStepCountsSummary} from "doctors-dashboard/http-clients/index";

export enum PatientStepCountActions {
    RESET_PATIENT_STEP_COUNT_SUMMARY = 'GET_PATIENT_STEP_COUNT_SUMMARY: RESET_PATIENT_STEP_COUNT_SUMMARY',

    GET_PATIENT_STEP_COUNT_SUMMARY = 'GET_PATIENT_STEP_COUNT_SUMMARY: GET_PATIENT_STEP_COUNT_SUMMARY',
    GET_PATIENT_STEP_COUNT_SUMMARY_SUCCESSFULLY = 'GET_PATIENT_STEP_COUNT_SUMMARY: GET_PATIENT_STEP_COUNT_SUMMARY_SUCCESSFULLY',
    GET_PATIENT_STEP_COUNT_SUMMARY_FAILED = 'GET_PATIENT_STEP_COUNT_SUMMARY: GET_PATIENT_STEP_COUNT_SUMMARY_FAILED',
}

export const RESET_PATIENT_STEP_COUNT_SUMMARY = (): StoreAction<PatientStepCountActions> => ({
    type: PatientStepCountActions.RESET_PATIENT_STEP_COUNT_SUMMARY
});

export const GET_PATIENT_STEP_COUNT_SUMMARY = (): StoreAction<PatientStepCountActions> => ({
    type: PatientStepCountActions.GET_PATIENT_STEP_COUNT_SUMMARY
});

export const GET_PATIENT_STEP_COUNT_SUMMARY_SUCCESSFULLY = (summary: IPatientStepCountsSummary[]): StoreAction<PatientStepCountActions> => ({
    type: PatientStepCountActions.GET_PATIENT_STEP_COUNT_SUMMARY_SUCCESSFULLY, payload: summary
});

export const GET_PATIENT_STEP_COUNT_SUMMARY_FAILED = (error: OperationErrorType): StoreAction<PatientStepCountActions> => ({
    type: PatientStepCountActions.GET_PATIENT_STEP_COUNT_SUMMARY_FAILED, payload: error
});