import * as React from "react";
import {memo} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {Container, Grid} from "@material-ui/core";
import {IOrpyxApplicationStore} from "../../../../../../../store/application.reducers";
import {PatientInformationActionRequiredAlerts} from "../../../../patient-action-required-alerts/components/patient-information-action-required-alerts/patient-information-action-required-alerts";
import {PatientSubmitCareNote} from "../../../../patient-submit-care-note/components/patient-submit-care-note/patient-submit-care-note";
import {PatientInformationPressure} from "../../patient-information-pressure/patient-information-pressure";
import {PatientInformationAdherence} from "../../patient-information-adherence/patient-information-adherence";
import {PatientInformationTemperature} from "../../patient-information-temperature/patient-information-temperature";
import {PatientInformationActivityLog} from "../../patient-information-activity-log/patient-information-activity-log";
import {withRouter} from "react-router";
import {usePatientInformationPageStyles} from "../patient-information-page.style";
import {PatientInformationMonthlyReports} from "../../patient-information-monthly-reports/patient-information-monthly-reports";
import {PatientInformationStepCount} from "../../patient-information-step-count/patient-information-step-count";
import { PatientChartEvents, PatientChartMeta, PatientChartOwnProps, PatientChartProps } from "./patient-chart.interface";

const PatientChartTemplate = (props: PatientChartMeta) => {
    const classes = usePatientInformationPageStyles();

    return (
        <Container>
            <Grid container className={classes.root} spacing={4}>
                <Grid item xs={8} id="patient-chart-grid">
                    <PatientInformationAdherence/>

                    <PatientInformationPressure/>

                    <PatientInformationStepCount/>

                    <PatientInformationTemperature/>

                </Grid>

                <Grid item xs={4}>
                    <div className={classes.content}>
                        <PatientInformationActionRequiredAlerts/>
                        <PatientSubmitCareNote/>
                    </div>

                    <PatientInformationMonthlyReports/>
                </Grid>

                <PatientInformationActivityLog/>

            </Grid>
        </Container>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientChartOwnProps => {
    return {
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientChartProps): PatientChartEvents => {
    return bindActionCreators({
    }, dispatch);
};

export const PatientChart = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientChartTemplate)));
