import * as React from 'react';
import {NavLink} from 'react-router-dom';

const NoMatch = () => (
  <div>
    No Match. Go <NavLink
          exact={true}
          to="/"
          activeClassName="active"
          className="linkBtn">
          Home
        </NavLink>
  </div>
);

export default NoMatch;
