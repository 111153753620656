import {OperationErrorType, StoreAction} from "orpyx-web-common";
import {PatientsSearchQuery} from "../models/patients-search-query";
import {IGetPatientsListResponse, IGetPatientsListResponsePagingResult} from "doctors-dashboard/http-clients/index";

export enum PatientsListActions {
    REFRESH_PATIENTS_LIST = 'FETCH_PATIENTS_LIST: REFRESH_PATIENTS_LIST',

    FETCH_PATIENTS_LIST = 'FETCH_PATIENTS_LIST: FETCH_PATIENTS_LIST',
    FETCH_PATIENTS_LIST_BEGIN = 'FETCH_PATIENTS_LIST: FETCH_PATIENTS_LIST_BEGIN',
    FETCH_PATIENTS_LIST_SUCCESSFULLY = 'FETCH_PATIENTS_LIST: FETCH_PATIENTS_LIST_SUCCESSFULLY',
    FETCH_PATIENTS_LIST_FAILED = 'FETCH_PATIENTS_LIST: FETCH_PATIENTS_LIST_FAILED',

    SELECT_PATIENT = 'FETCH_PATIENTS_LIST: SELECT_PATIENT',
}

export const REFRESH_PATIENTS_LIST = (): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.REFRESH_PATIENTS_LIST
});

export const PATIENTS_LIST_SELECT_PATIENT = (patient: IGetPatientsListResponse): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.SELECT_PATIENT, payload: patient
});

export const FETCH_PATIENTS_LIST = (query: PatientsSearchQuery): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.FETCH_PATIENTS_LIST, payload: query
});

export const FETCH_PATIENTS_LIST_SUCCESSFULLY = (result: IGetPatientsListResponsePagingResult): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.FETCH_PATIENTS_LIST_SUCCESSFULLY, payload: result
});

export const FETCH_PATIENTS_LIST_FAILED = (error: OperationErrorType): StoreAction<PatientsListActions> => ({
    type: PatientsListActions.FETCH_PATIENTS_LIST_FAILED, payload: error
});
