import * as React from "react";
import {memo, useEffect} from "react";
import {connect} from "react-redux";

import {DoctorDashboardPageRouteMeta, DoctorDashboardPageRouteOwnProps, DoctorDashboardPageRouteProps, DoctorDashboardPageRouteRenderProps, DoctorsDashboardPageRouteEvents} from "./doctor-dashboard-page-route.interface";
import {IOrpyxApplicationStore} from "../../../store/application.reducers";
import {DoctorsDashboardAuthSelectors} from "../../auth/store/doctors-dashboard-auth-selectors";
import {Route, withRouter} from "react-router";
import {isNullOrUndefined} from "orpyx-web-common";
import {CenteredMessage} from "../../../shared/components/centered-message/centered-message";
import { throttle } from "underscore";
import { Dispatch, bindActionCreators } from "redux";
import { DOCTORS_DASHBOARD_RESET_LAST_ACTIVE_TIME } from "../../auth/store/doctors-dashboard-auth-actions";

const DoctorDashboardPageRouteRender = memo((props: DoctorDashboardPageRouteRenderProps) => {
    const Component = props.component;

    if (!props.isLoggedIn || isNullOrUndefined(props.actorType)) {
        return <CenteredMessage title={'Access error'} severity={"error"}>
            You not authorized for access for this page
        </CenteredMessage>
    }

    if (!props.allowedFor.includes(props.actorType!)) {
        return <CenteredMessage title={'Access error'} severity={"error"}>
            You do not have permission to access this page
        </CenteredMessage>
    }

    if (props.requiresCustomerCarePermissions && !props.hasCustomerCarePermissions) {
        return <CenteredMessage title={'Access error'} severity={"error"}>
            You do not have permission to access this page
        </CenteredMessage>
    }

    return <Component/>;
});

const DoctorDashboardPageRouteTemplate = (props: DoctorDashboardPageRouteMeta) => {
    useEffect(() => {
        // Throttles mouse moves to only call the onMouseMoveEvent once every 5 seconds.
        // The onMouseMoveEvent will dispatch the Action to Reset the last active time.
        const throttleMouseMove = throttle(props.onMouseMoveEvent, 5000);
    
        document.addEventListener('mousemove', throttleMouseMove);

        return () => {
            document.removeEventListener('mousemove', throttleMouseMove);
        }
    }, []);

    return <Route path={props.path} exact={props.exact} render={() => <DoctorDashboardPageRouteRender
        component={props.component}
        isLoggedIn={props.isLoggedIn}
        actorType={props.actorType}
        allowedFor={props.allowedFor}
        requiresCustomerCarePermissions={props.requiresCustomerCarePermissions}
        hasCustomerCarePermissions={props.hasCustomerCarePermissions}
    />}/>
};

const mapStateToProps = (state: IOrpyxApplicationStore): DoctorDashboardPageRouteOwnProps => {
    return {
        isLoggedIn: DoctorsDashboardAuthSelectors.isLoggedIn(state),
        actorType: DoctorsDashboardAuthSelectors.actorType(state),
        hasCustomerCarePermissions: DoctorsDashboardAuthSelectors.hasCustomerCarePermissions(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: DoctorDashboardPageRouteProps): DoctorsDashboardPageRouteEvents => {
    return bindActionCreators({
        onMouseMoveEvent: DOCTORS_DASHBOARD_RESET_LAST_ACTIVE_TIME
    }, dispatch);
};

export const DoctorDashboardPageRoute = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(DoctorDashboardPageRouteTemplate)));
