import {all, call, put, takeEvery} from "redux-saga/effects";
import {DoctorsDashboardPatientsHttpClient, IGetPatientInformationResponse} from "doctors-dashboard/http-clients/index";
import {ORPYXSI_API_URL} from "../../../../../../appConstants";
import {DateRange, StoreAction} from "orpyx-web-common";
import {GET_PATIENT_INFORMATION_FAILED, GET_PATIENT_INFORMATION_REFRESH_SUCCESSFULLY, GET_PATIENT_INFORMATION_SUCCESSFULLY, PatientInformationPageActions} from "./patient-information-page.actions";
import { GET_PATIENT_TEMPERATURE_SUMMARY } from "../../patient-temperature/store/patient-temperature.actions";
import { GET_PATIENT_STEP_COUNT_SUMMARY } from "../../patient-step-count/store/patient-step-count.actions";
import { GET_PATIENT_STATISTIC } from "../../patient-statistic/store/patient-statistic.actions";
import { GET_PATIENT_ACTION_REQUIRED_ALERTS } from "../../patient-action-required-alerts/store/patient-information-action-required-alerts.actions";
import { GET_PATIENT_CARE_NOTES, GET_PATIENT_CARE_NOTES_DATA_CHANGE } from "../../patient-activity-log/store/patient-activity-log.actions";
import { GET_PATIENT_MONTHLY_REPORTS } from "../../patient-monthly-reports/store/patient-monthly-reports.actions";

const API_VERSION = '1';

function* onFetchPatient(action: StoreAction<PatientInformationPageActions, number>) {
    try {
        const patientId = action.payload!;
        const api = new DoctorsDashboardPatientsHttpClient(ORPYXSI_API_URL);

        const data: IGetPatientInformationResponse = yield call(
            [api, api.getPatientInformation],
            patientId,
            API_VERSION
        );

        yield put(GET_PATIENT_INFORMATION_SUCCESSFULLY(data));
    } catch (e) {
        yield put(GET_PATIENT_INFORMATION_FAILED(e));
    }
}

function* onFilterDateChanged(action: StoreAction<PatientInformationPageActions, DateRange>) {
    const dateRange = action.payload!;
    yield put(GET_PATIENT_CARE_NOTES_DATA_CHANGE(dateRange)());
    yield put(GET_PATIENT_INFORMATION_REFRESH_SUCCESSFULLY());
}

function* onPatientInformationLoaded() {
    yield all([
        put(GET_PATIENT_ACTION_REQUIRED_ALERTS()),
        put(GET_PATIENT_CARE_NOTES(false)()),
        put(GET_PATIENT_MONTHLY_REPORTS(false)()),
        put(GET_PATIENT_STATISTIC()),
        put(GET_PATIENT_TEMPERATURE_SUMMARY()),
        put(GET_PATIENT_STEP_COUNT_SUMMARY())
    ]);
}

export default function* PatientInformationPageSagas() {
    yield all([       
        yield takeEvery(PatientInformationPageActions.GET_PATIENT_INFORMATION, onFetchPatient),
        yield takeEvery(PatientInformationPageActions.GET_PATIENT_INFORMATION_REFRESH, onFilterDateChanged),
        yield takeEvery(PatientInformationPageActions.GET_PATIENT_INFORMATION_REFRESH, onPatientInformationLoaded)
    ]);
}
