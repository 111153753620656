import {makeStyles} from "@material-ui/styles";
import {createStyles, Theme} from "@material-ui/core";
import {Teal} from "orpyx-web-common";

export const usePatientInformationPageStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        marginBottom: "50px",
    },
    title: {
        marginTop: '0',
        fontSize: '20px',
        color: Teal,
    },
    header: {
        zIndex: 99,
    },
    patientDetailsHeader: {
        display: 'flex',
        marginLeft: '10px',
        alignItems: 'left',
        fontSize: '20px',
    },
    patientDetails: {
        fontWeight: "bold",
    },
    paper: {
        padding: "10px",
    },
    icon: {
        height: "38px",
        color: '#000',
        width: 'initial',
        marginRight: '15px'
    },
    dashboardHeader: {
        backgroundColor: Teal,
        color: 'white',
        padding: '30px 0px',
        borderRadius: '4px',
    },
    altText: {
        color: "black",
        marginLeft: '10px',
    },
    border: {
        borderWidth: "3px",
        borderStyle: "solid",
        borderColor: Teal,
        borderRadius: '4px',
        boxShadow: '0 0 5px 0 rgba(232,232,232,0.53)',
        background: '#fff',
        padding: '20px 0',
    },
    horizontalLayout: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRight: '1px solid #11D5D0',
        '&:last-child': {
            border: '0'
        }
    },
    centered: {
        alignContent: "center",
        textAlign: "center",
    },
    verticalLayout: {
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
    },
    content: {
        border: '1.08px solid #EFEFEF',
        borderRadius: '3.23px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 7px 14px 0 rgba(0,0,0,0.01), 0 11px 36px 0 rgba(48,48,48,0.16)',
        padding: '27px 20px',
    },
    chart: {
        height: '220px',
    },
    dateLine: {
        display: "flex",
        float: 'right',
        textAlign: 'right',
        fontWeight: 'bold'
    },
    dateItem: {
        color: "#00B6B1",
        textIndent: '0.5em'
    },
    datePicker: {
        width: '200px'
    },
    datePickerNotchedOutline: {
        borderWidth: "1px",
        borderColor: "white !important"
    },
    datePickerLabel: {
        color: "white"
    },
    datePickerInput: {
        padding: '5px'
    },
    href: {
        'text-decoration': 'none',
        color: Teal,
        paddingRight: '20px',
        marginRight: '20px'
    },
    borderRight: {
        borderRight: '1px solid grey'
    },
    bold: {
        fontWeight: 'bold'
    },
    careNoteWrapper: {
        padding: '10px',
        border: '1px solid #ccc',
    },
    careNoteEditor: {
        backgroundColor: 'lightgray',
        padding: '10px',
        border: '1px solid #ccc',
        height: '400px',
        overflowX: 'auto'
    },
    careNoteToolbar: {
        border: '1px solid #ccc'
    },
    careNoteSubmitButton: {
        marginTop: '5px',
        float: 'right',
        width: '84x',
        height: '36px',
        marginLeft: '20px',
        '& span': {
            color: '#FFF',
        },
    },
    clipboardIcon: {
        height: "15px",
        color: '#000',
        width: 'initial',
        marginRight: '5px'
    },
    inventoryIcon: {
        height: "15px",
        color: '#000',
        width: 'initial',
        marginRight: '5px'
    }
}));
