import * as React from "react";
import {memo, useEffect} from "react";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {PatientInformationPageEvents, PatientInformationPageMeta, PatientInformationPageOwnProps, PatientInformationPageProps} from "./patient-information-page.interface";
import {Container, Grid} from "@material-ui/core";
import {GET_PATIENT_INFORMATION, GET_PATIENT_INFORMATION_FAILED, GET_PATIENT_INFORMATION_REFRESH} from "../../store/patient-information-page.actions";
import {IOrpyxApplicationStore} from "../../../../../../store/application.reducers";
import {PatientInformationHeader} from "../patient-information-header/patient-information-header";
import {ApplicationBreadcrumbs, ApplicationBreadcrumbsItem, fullNamePipe, OperationErrorMessage, DateFormat} from "orpyx-web-common";
import {IGetPatientInformationResponse} from "doctors-dashboard/http-clients/index";
import {onPatientInformationPageLoaded} from "./patient-information-page.hooks";
import {PatientInformationPageSelectors} from "../../store/patient-information-page.selectors";
import {withRouter} from "react-router";
import {usePatientInformationPageStyles} from "./patient-information-page.style";
import { DoctorsDashboardAuthSelectors } from "modules/doctor-dashboard/auth/store/doctors-dashboard-auth-selectors";
import { PatientChart } from './patient-chart/patient-chart';
import { PatientInformationSubHeader } from "../patient-information-sub-header/patient-information-sub-header";
import { pages } from "../patient-information-sub-header/patient-information-sub-header-pages";

const generatePageBreadcrumbs = (patient: IGetPatientInformationResponse | undefined): ApplicationBreadcrumbsItem[] => {
    const items = [{
        name: 'Patients',
        url: '/patients/Active'
    }];

    if (patient) {
        items.push({
            name: fullNamePipe(patient)!,
            url: `/patients/details/${patient.patientId}`
        });
    }

    return items;
};

const PatientInformationPageTemplate = (props: PatientInformationPageMeta) => {
    useEffect(onPatientInformationPageLoaded(props), []);

    const classes = usePatientInformationPageStyles();

    const onFilterDateChanged = (filterDate: Date) => {
        props.onFilterDateChange(filterDate, props.patient.patientId!);
    }

    return (
        <Container>
            <Grid container className={classes.root} spacing={4}>

                <Grid item xs={12}>
                    <ApplicationBreadcrumbs items={generatePageBreadcrumbs(props.patient)}/>
                </Grid>

                <Grid item xs={12}>
                    {props.patient && <PatientInformationHeader
                        patient={props.patient}
                        monitoringCycle={props.monitoringCycle}
                        onFilterDateChange={onFilterDateChanged}
                    />}
                </Grid>

                {props.error && <Grid item xs={12}>
                    <div className={classes.content}>
                        <OperationErrorMessage error={props.error}/>
                    </div>
                </Grid>}

                <PatientInformationSubHeader 
                    patient={props.patient}
                    loading={props.loading}
                    currentPage={pages.patientChart}
                    hasCustomerCarePermissions={props.hasCustomerCarePermissions}
                />
                
                <PatientChart />

            </Grid>
        </Container>
    );
};

const mapStateToProps = (state: IOrpyxApplicationStore): PatientInformationPageOwnProps => {
    return {
        patient: PatientInformationPageSelectors.patient(state)!,
        error: PatientInformationPageSelectors.error(state),
        loading: PatientInformationPageSelectors.loading(state),

        monitoringCycle: PatientInformationPageSelectors.monitoringCycle(state),

        hasCustomerCarePermissions: DoctorsDashboardAuthSelectors.hasCustomerCarePermissions(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: PatientInformationPageProps): PatientInformationPageEvents => {
    return bindActionCreators({
        loadPatientInformation: GET_PATIENT_INFORMATION,
        showErrorMessage: GET_PATIENT_INFORMATION_FAILED,
        onFilterDateChange: GET_PATIENT_INFORMATION_REFRESH
    }, dispatch);
};

export const PatientInformationPage = memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientInformationPageTemplate)));
