import {combineReducers} from "redux";
import PatientsListReducers from "../patients-list/store/patients-list.reducers";
import PatientSubmitCareNoteReducers from "../patient-information/patient-submit-care-note/store/patient-submit-care-note.reducers";
import {PatientInformationReducers} from "../patient-information/store/patient-information.reducers";
import ScratchPadNotesReducers from "../patient-information/patient-information-page/store/scratch-pad-notes/scratch-pad-notes.reducers";

const PatientsReducers = combineReducers({
    list: PatientsListReducers,
    information: PatientInformationReducers,

    submitCareNote: PatientSubmitCareNoteReducers,
    scratchPadNote: ScratchPadNotesReducers
});

export default PatientsReducers;