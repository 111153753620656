import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {IGetPatientInformationResponse, DoctorsDashboardPatientStepCountHttpClient, IPatientStepCountsSummary,} from "doctors-dashboard/http-clients/index";
import {ORPYXSI_API_URL} from "../../../../../../appConstants";
import {PatientInformationPageSelectors} from "../../patient-information-page/store/patient-information-page.selectors";
import {GET_PATIENT_STEP_COUNT_SUMMARY_FAILED, GET_PATIENT_STEP_COUNT_SUMMARY_SUCCESSFULLY, PatientStepCountActions} from "./patient-step-count.actions";

const API_VERSION = '1';

function* onFetchPatientTemperatureSummary() {
    try {
        const patient: IGetPatientInformationResponse = yield select(PatientInformationPageSelectors.patient)!;
        const monitoringCycle = yield select(PatientInformationPageSelectors.monitoringCycle)!;

        const api = new DoctorsDashboardPatientStepCountHttpClient(ORPYXSI_API_URL);

        const data: IPatientStepCountsSummary[] = yield call(
            [api, api.getPatientStepCountSummary],
            patient.patientId!,
            //  TODO: Fix types
            monitoringCycle.begin! as any,
            API_VERSION,
        );

        yield put(GET_PATIENT_STEP_COUNT_SUMMARY_SUCCESSFULLY(data));
    } catch (e) {
        yield put(GET_PATIENT_STEP_COUNT_SUMMARY_FAILED(e));
    }
}

export default function* PatientStepCountSagas() {
    yield all([
        yield takeEvery(PatientStepCountActions.GET_PATIENT_STEP_COUNT_SUMMARY, onFetchPatientTemperatureSummary),
    ]);
}
