import React from "react";
import { CircularProgress, Fab } from "@material-ui/core";
import { useCircularProgressStyles } from "./circular-progress-styles";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';

interface CircularIntegrationProps {
    progress: number
}

export default function CircularIntegration(props: CircularIntegrationProps) {
    const classes = useCircularProgressStyles();
    const timer = React.useRef<number>();
  
    React.useEffect(() => {
      return () => {
        clearTimeout(timer.current);
      };
    }, []);
  
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Fab
            color="primary"
            size="small"
            className={props.progress === 100 ? 'classes.buttonSuccess' : ''}
          >
            {props.progress === 100 && <CheckIcon fontSize="small" />}
            {props.progress < 0 && <WarningIcon color="error" fontSize="small" />}
            {props.progress < 100 && props.progress > 0 && <CloudUploadIcon fontSize="small" />}
          </Fab>
          {props.progress < 100 && props.progress > 0 && <CircularProgress size={45} 
                            className={classes.fabProgress} 
                            /* variant="determinate"
                            value={props.progress} */
                            />}
        </div>
      </div>
    );
  }