import * as React from "react";
import {memo} from "react";

import useHomePageStyle from "./home-page.style";
import logo from "../../../../../images/Orpyx-SI-Logo-Knockout.svg";
import {HomePageNavbar} from "../home-page-navbar/home-page-navbar/home-page-navbar";

export const HomePage = memo(() => {
    const classes = useHomePageStyle();

    return (
        <div className = {classes.root}>
            <div className = {classes.homePage}>
                <HomePageNavbar />
            </div>
            <img src = {logo} className = {classes.logo} alt = "" />
        </div>
    );
});
